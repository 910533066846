<template>
    <div class="PerfilUsuario1perfil-MAIN">

        <div class="p8-profile-pic">
            <div>
                <img src="../assets/images/icons/profilepic.svg" id="perfil-img-preview" />
                <input type="file" id="perfil-img-upload" @change="uploadProfilePic">
            </div>
            <p><img src="../assets/images/icons/edit.svg" />Editar</p>
            <p style="margin-top: 0;" @click="deleteProfilePic()"><img src="../assets/images/icons/trash.svg"/> Eliminar</p>
        </div>

        <div class="p8perfil-grid">
            <div><img src="../assets/images/icons/user.svg" /><input type="text" placeholder="Nombre" id="perfil-nombre" />
            </div>
            <div><img src="" /><input type="text" placeholder="Apellidos" id="perfil-apellidos" /></div>
            <div><img src="../assets/images/icons/mail.svg" /><input type="email" placeholder="Email" id="perfil-email" />
            </div>
            <div><img src="../assets/images/icons/mobile-notch.svg" /><input type="number" placeholder="Teléfono celular"
                    id="perfil-cel" /></div>
        </div>

        <br><br>
        <p><img src="../assets/images/icons/passport.svg" />Ingresa una foto de tu INE o Pasaporte visto por el frente y
            legible</p>
        <p class="p8-min-txt">*Tus datos de identidad se validarán de acuerdo a tu documentación oficial (INE o Pasaporte).
            Tus datos de contacto permanecerán editables dependiendo de los canales de comunicación en los que quieras ser
            encontrado. <br><strong>La Foto de Perfil es obligatoria para completar tus solicitudes para subir Locación.
                Esta debe ser acorde a lo proporcionado en tu documentación oficial.</strong></p>
        <br>
        <div id="p8-inputImg"
            :style="`border: 1px dashed ${docImagesUploaded == [] || docImagesUploaded == '[]' ? 'red' : 'gray'}; background-color: rgb(${docImagesUploaded == [] || docImagesUploaded == '[]' ? '255' : '243'}, 243, 243);`">
            <p><img src="../assets/images/icons/photo.svg" />Fotografías</p>
            <img src="../assets/images/icons/clip.svg" />
            <p>Arrastra y suelta tus archivos aquí</p>
            <p><a>O selecciona tu archivo</a></p>
            <p>Formato PNG, JPEG, JPG. Máximo 20 fotografías</p>

            <input type="file" id="perfil-doc-img" multiple ref="docImgsInput" @change="handleDocImgsChange">
        </div>
        
        <p @click="deleteDocuments()"><img src="../assets/images/icons/trash.svg"/> Eliminar</p>

        <div style="border:3px solid transparent;" class="preview-files">
            <img v-for="(image, index) in docImagesUploaded" :key="index" :src="image">
        </div>

        <p class="p8-min-txt">TU IDENTIFICACIÓN NO SERÁ MOSTRADA A LOS USUARIOS. Es necesaria para la verificación de la
            autenticidad de tu cuenta. <a @click="goTo('terminos')">Términos y condiciones</a>. Tu cuenta será considerada
            como "pendiente", en caso de no realizar el registro completo con fotos (perfil e identificación). Sin embargo,
            la información que hayas colocado en tu publicación será guardada para cuando finalices tu registro.
        </p>

        <br>
        <p>Ingresa tus redes sociales activas (opcional):</p>
        <br>
        <div class="p8-networks">
            <div><img src="../assets/images/icons/linkedin.svg" /><input type="text" placeholder="Linkedin"
                    id="perfil-linkedin" /></div>
            <div><img src="../assets/images/icons/instagram-color.svg" /><input type="text" placeholder="Instagram"
                    id="perfil-instagram" /></div>
            <div><img src="../assets/images/icons/facebook-color.svg" /><input type="text" placeholder="Facebook"
                    id="perfil-facebook" /></div>
            <div><img src="../assets/images/icons/website.svg" /><input type="text" placeholder="Sitio web"
                    id="perfil-website" /></div>
        </div>
        <p class="p8-min-txt">Los datos de tus redes sociales son necesarios para segmentar mejor los perfiles que ven tus
            locaciones. NINGUNA DE TUS REDES SOCIALES SERÁN VISIBLES PARA NUESTROS USUARIOS.</p>

        <label class="profile-aceptar-terminos">
            <input type="checkbox" v-model="aceptarTerminos"> Acepto términos y condiciones
        </label>

        <button @click="editProfile(aceptarTerminos)" :class="{ 'not-button': !aceptarTerminos }">Actualizar</button>

        <div class="updatedProfile" v-if="updatedProfile">
            <div>
                <h3>{{ popUpTitle }}</h3>
                <p style="margin: 0;" v-html="popUpSubtitle"></p>
                <button @click="updateProfileReload()">Aceptar</button>
            </div>
        </div>

    </div>
</template>

<script>
/* eslint-disable no-unreachable */
import Compress from 'compress.js';
import { element } from 'alexa-reactor-core';
export default {
    name: 'PerfilUsuario1perfil',
    data() {
        return {
            profilePicURL: JSON.parse(localStorage.getItem('userData'))["user"]["S_URLFotoPerfil"],
            docImagesUploaded: [], //set on mounted() with JSON.parse(localStorage.getItem('userData')).user.S_URLIdentificacion
            docImagesOriginalNames: [],
            aceptarTerminos: false,
            updatedProfile: false,
            popUpTitle: "Tu información ha sido actualizada.",
            popUpSubtitle: ""
        }
    },
    watch: {
        docImagesUploaded: function(newVal) {
            this.parsedDocImages = this.parseJson(newVal);
        },
    },
    methods: {
        parseJson(data_){
            try {
                return JSON.parse(data_);
            } catch (error) {
                return []
            }
        },

        uploadProfilePic() {
            let profilePic = document.getElementById("perfil-img-upload");
            let imgPreview = document.getElementById("perfil-img-preview");

            if (profilePic.files && profilePic.files.length > 0) {
                // Obtener el primer archivo seleccionado
                let file = profilePic.files[0];

                // Crear una URL local para la imagen seleccionada
                const imgUrl = URL.createObjectURL(file);

                // Actualizar el atributo src de la imagen con la URL local
                imgPreview.src = imgUrl;
            } else {
                // Si no se seleccionó ningún archivo, borrar la imagen previa
                imgPreview.src = "";
            }

            // Verificar si se seleccionó algún archivo
            if (profilePic.files.length > 0) {
                // Obtener el primer archivo seleccionado
                let file = profilePic.files[0];

                // Comprimir la imagen antes de enviarla
                const compress = new Compress();
                compress
                    .compress([file], {
                        size: 4, // Define el tamaño objetivo en MB
                        quality: 0.75, // Define la calidad de compresión
                    })
                    .then((data) => {
                        const compressedImage = data[0]; // Obtén la imagen comprimida en el nuevo formato
                        // Convertir el resultado de la compresión a un objeto Blob
                        const blob = Compress.convertBase64ToFile(compressedImage.data, compressedImage.ext);
                        // Crear un objeto FormData para enviar la imagen comprimida en el cuerpo de la solicitud
                        let formData = new FormData();
                        formData.append("lvl1", "usuarios"); // Agrega el campo lvl1 con el valor "usuarios"
                        formData.append("lvl2", "perfil"); // Agrega el campo lvl2 con el valor "perfil"
                        formData.append("archivo", blob, file.name); // Adjunta la imagen comprimida en el campo "archivo"

                        fetch("https://locacionbackoffice.ideeo.mx/api/sistema_de_carga", {
                            method: "POST",
                            body: formData,
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                this.profilePicURL = data.cargada_en.replace("/public/", "https://locacionbackoffice.ideeo.mx/");
                            })
                            .catch((error) => {
                                console.error("Error:", error);
                            });
                    });
            } else {
                console.log("No se seleccionó ningún archivo.");
            }
        },

        handleDocImgsChange() {
            // Limpiar los arrays docImagesUploaded y docImagesOriginalNames
            this.docImagesUploaded = [];
            this.docImagesOriginalNames = [];

            // Verificar si se seleccionó algún archivo
            if (this.$refs.docImgsInput && this.$refs.docImgsInput.files.length > 0) {
                // Obtener el input de imágenes
                const docImgs = this.$refs.docImgsInput.files;

                // Verificar si se excede el límite de 20 imágenes
                if (docImgs.length > 20) {
                    console.log("Se excede el límite de 20 imágenes. Selecciona un máximo de 20 imágenes.");
                    document.getElementById("doc-img-uploaded").innerHTML = '<span style="color:red;">¡El límite es de 20 imágenes!</span>';
                    return; // Detener el proceso de carga de imágenes.
                }

                // Iterar sobre los archivos seleccionados y comprimirlos y subirlos
                for (const file of docImgs) {
                    this.docImagesOriginalNames.push(file.name); // Agregar el nombre original al array
                    this.compressAndUploadImage(file);
                }
            } else {
                console.log("No se seleccionó ningún archivo.");
            }
            console.log(this.docImagesUploaded);
            console.log(this.docImagesOriginalNames);
        },

        compressAndUploadImage(file) {
            // Comprimir la imagen antes de enviarla
            const compress = new Compress();
            compress.compress([file], {
                size: 4, // Define el tamaño objetivo en MB
                quality: 0.75, // Define la calidad de compresión
            })
                .then((data) => {
                    const compressedImage = data[0]; // Obtén la imagen comprimida en el nuevo formato
                    // Convertir el resultado de la compresión a un objeto Blob
                    const blob = Compress.convertBase64ToFile(compressedImage.data, compressedImage.ext);
                    // Crear un objeto FormData para enviar la imagen comprimida en el cuerpo de la solicitud
                    const formData = new FormData();
                    formData.append("lvl1", "usuarios"); // Agrega el campo lvl1 con el valor "usuarios"
                    formData.append("lvl2", "perfil"); // Agrega el campo lvl2 con el valor "perfil"
                    formData.append("archivo", blob, file.name); // Adjunta la imagen comprimida en el campo "archivo"

                    fetch("https://locacionbackoffice.ideeo.mx/api/sistema_de_carga", {
                        method: "POST",
                        body: formData,
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            this.docImagesUploaded.push(data.cargada_en.replace("/public/", "https://locacionbackoffice.ideeo.mx/")); // Agregar el nombre de la imagen subida al array
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                })
                .catch((error) => {
                    console.error("Error al comprimir la imagen:", error);
                });
        },

        deleteProfilePic(){
            this.profilePicURL = "https://cdn.iconscout.com/icon/free/png-256/user-placeholder-866235.png";
            element("#perfil-img-preview").src = this.profilePicURL;
        },

        deleteDocuments(){
            this.docImagesUploaded = [];
            this.docImagesOriginalNames = [];
        },

        editProfile(terminosAceptados_) {
            if (!terminosAceptados_) {
                console.log("Terminos no acpetados");
                return;
            }
            let nombre = document.getElementById("perfil-nombre").value;
            let apellidos = document.getElementById("perfil-apellidos").value;
            let email = document.getElementById("perfil-email").value;
            let cel = document.getElementById("perfil-cel").value;
            //let docImages = document.getElementById("perfil-doc-img");
            let linkedin = document.getElementById("perfil-linkedin").value;
            let instagram = document.getElementById("perfil-instagram").value;
            let facebook = document.getElementById("perfil-facebook").value;
            let website = document.getElementById("perfil-website").value;

            const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"]; // Reemplaza con tu token de acceso

            fetch('https://locacionbackoffice.ideeo.mx/api/usuarios/' + JSON.parse(localStorage.getItem('userData')).user.id, {
                method: 'PUT',
                body: JSON.stringify({
                    "S_Nombre": nombre,
                    "S_Apellidos": apellidos,
                    "tw_roles_id": 4,
                    "email": email,
                    "password": "1234abcd",
                    "N_Activo": 1,
                    "S_URLFotoPerfil": this.profilePicURL,
                    "N_TelWhatsapp": cel,
                    "N_TelCasa": "5512345678",
                    "S_TipoLocatario": "propietario",
                    "S_URLIdentificacion": this.docImagesUploaded,
                    "S_URLlinked": linkedin,
                    "S_URLfacebook": facebook,
                    "S_URLinstagram": instagram,
                    "S_URLweb": website,
                    "N_AceptaPublicidad": 1
                }),
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${accessToken}` // Incluir el token Bearer en el encabezado
                }
            })
                .then(response => response.json())
                .then(json => {
                    window.scrollTo({ top: 0, behavior: 'auto' });
                    //Actualizar datos:
                    let newUserData = JSON.parse(localStorage.getItem('userData'));
                    newUserData.user.S_URLFotoPerfil = json.dato.S_URLFotoPerfil;
                    newUserData.user.S_Nombre = json.dato.S_Nombre;
                    newUserData.user.S_Apellidos = json.dato.S_Apellidos;
                    newUserData.user.email = json.dato.email;
                    newUserData.user.N_TelWhatsapp = json.dato.N_TelWhatsapp;
                    newUserData.user.S_URLIdentificacion = json.dato.S_URLIdentificacion;
                    newUserData.user.S_URLfacebook = json.dato.S_URLfacebook;
                    newUserData.user.S_URLinstagram = json.dato.S_URLinstagram;
                    newUserData.user.S_URLlinked = json.dato.S_URLlinked;
                    newUserData.user.S_URLweb = json.dato.S_URLweb;
                    localStorage.setItem("userData", JSON.stringify(newUserData));


                    document.querySelectorAll('.nav-userpic').forEach(userpic => userpic.src = json.dato.S_URLFotoPerfil);
                    document.querySelectorAll('.nav-username').forEach(username => username.textContent = json.dato.S_Nombre);
                
                    // Si no hay foto de perfil o documentos, agregar leyenda.
                    console.log(json.dato.S_URLIdentificacion);

                    if(!json.dato.S_URLIdentificacion || this.docImagesUploaded.length == 0){
                        this.popUpTitle = "Tu información ha sido actualizada.";
                        this.popUpSubtitle = "Falta por subir tu identificación.<br><b>No podrás publicar tus locaciones.</b>";
                    }else
                    if(json.dato.S_URLFotoPerfil.includes("https://cdn.iconscout.com/icon/free/")){
                        this.popUpTitle = "Tu información ha sido actualizada.";
                        this.popUpSubtitle = "Falta por subir tu foto de perfil.<br><b>No podrás publicar tus locaciones.</b>";
                    }else{
                        this.popUpTitle = "Tu información ha sido actualizada.";
                        //const uploadUrl = window.location.href.split("#")[0]+"#/subir-locacion";
                        this.popUpSubtitle = ""; //`Puedes proceder a <a href="${uploadUrl}">subir tu locación</a>.`;
                    }
                    this.updatedProfile = true;
                })
                .catch(error => {
                    console.error("Error:", error);
                });
        },

        goTo(section_) {
            const newURL = window.location.origin + window.location.pathname;
            history.replaceState({}, document.title, newURL);
            window.location.hash = "/" + section_;
            if (localStorage.getItem('onSession') === 'true') {
                let userData = JSON.parse(localStorage.getItem('userData'));
                //console.log(userData)
                setTimeout(() => {
                    document.querySelectorAll('.navProfile').forEach(element => element.style.display = "flex");
                    document.querySelectorAll('.navAccount').forEach(element => element.style.display = "none");
                    document.querySelectorAll('.nav-username').forEach(username => username.textContent = userData.user.S_Nombre);
                    document.querySelectorAll('.nav-userpic').forEach(userpic => userpic.src = userData.user.S_URLFotoPerfil);
                    console.log("cambiado")
                }, 10);
            }
            setTimeout(() => { window.scrollTo(0, 0); }, 10);
        },

        updateProfileReload(){
            this.updatedProfile = false;
            window.location.reload();
        }

    },

    mounted() {
        window.scrollTo({ top: 0, behavior: 'auto' });
        let user = JSON.parse(localStorage.getItem('userData')).user;
        document.getElementById("perfil-nombre").value = user.S_Nombre;
        document.getElementById("perfil-apellidos").value = user.S_Apellidos;
        document.getElementById("perfil-email").value = user.email;
        document.getElementById("perfil-cel").value = user.N_TelWhatsapp;
        document.getElementById("perfil-linkedin").value = user.S_URLlinked;
        document.getElementById("perfil-instagram").value = user.S_URLinstagram;
        document.getElementById("perfil-facebook").value = user.S_URLfacebook;
        document.getElementById("perfil-website").value = user.S_URLweb;

        document.getElementById("perfil-img-preview").src = user.S_URLFotoPerfil;

        this.docImagesUploaded = JSON.parse(localStorage.getItem('userData'))["user"]["S_URLIdentificacion"];
        this.docImagesUploaded = JSON.parse(this.docImagesUploaded);
        this.docImagesOriginalNames = eval(JSON.parse(localStorage.getItem('userData'))["user"]["S_URLIdentificacion"]);
    },

    computed: {
        displayDocImages() {
            // Generar la cadena HTML con los nombres de las imágenes subidas
            let result = "";
            console.log("docImageNames:")
            try {
                for (const imgName of this.docImagesOriginalNames) {
                    if (imgName.startsWith("https://"))
                        result += `• <a href="${imgName}">${imgName}</a><br>`
                    else
                        result += `• ${imgName}<br>`;
                }
            } catch (error) {
                console.log("(Ignore error)", error);
            }
            return result;
        },
    },
}
</script>

<style scoped>
.PerfilUsuario1perfil-MAIN {
    display: flex;
    flex-direction: column;
}

.p8-profile-pic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-top: 4ch;
}

.p8-profile-pic>div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20ch;
    height: 20ch;
    position: relative;
    border-radius: 100vw;
    overflow: hidden;
}

.p8-profile-pic>div>img {
    width: 100%;
}

#perfil-img-preview {
    border-radius: 100vw;
}

.p8-profile-pic>div>input {
    position: absolute;
    border: 1px solid red;
    width: 100%;
    height: calc(100% + 4ch);
    border-radius: 10ch;
    opacity: 0;
    cursor: pointer;
}

.p8-profile-pic>p {
    display: flex;
    align-items: center;
    text-decoration: underline;
}

.p8-profile-pic>p img {
    max-width: 2ch;
    margin-right: 0.5ch;
}

.p8perfil-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 1.5ch;
    margin-top: 4ch;
}

.p8perfil-grid>div {
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
}

.p8perfil-grid>div input {
    width: calc(100% - 6ch);
}

.p8perfil-grid>div img {
    max-width: 3ch;
    margin-right: 1ch;
}

#p8-inputImg {
    border: 1px dashed gray;
    border-radius: 1ch;
    background-color: rgb(243, 243, 243);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2ch 0;
    position: relative;
}

#p8-inputImg>p {
    color: gray;
    width: 80%;
    text-align: center;
    font-size: 1.4ch;
    margin: 0.5ch;
}

#p8-inputImg>p a {
    color: blue;
    text-decoration: underline;
}

#p8-inputImg>input {
    position: absolute;
    border: 1px solid red;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.p8-networks>div {
    display: flex;
    align-items: center;
    margin-bottom: 2ch;
}

.p8-networks>div input {
    width: calc(100% - 9ch);
}

.p8-networks>div img {
    width: 3ch;
    margin-right: 3ch;
}

.p8-min-txt {
    display: inline;
}

.p8-min-txt a {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

#doc-img-uploaded {
    margin-top: 1ch;
    font-weight: 300;
    word-break: break-all;
}

#p8-inputImg>p:nth-child(1) {
    display: none;
}

.profile-aceptar-terminos {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1ch 0;
}

.not-button {
    background-color: rgb(231, 231, 231) !important;
    background: unset;
    color: gray;
    cursor: unset;
}

.preview-files{
    display: flex;
    margin-top: 2ch;
    overflow-x: auto;
    overflow-y: hidden;
}

.preview-files > img{
    height: 10ch;
    border-radius: 1ch;
    margin-right: 1ch;
}

.updatedProfile{
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.updatedProfile > div{
    display: inherit;
    flex-direction: inherit;
    align-items: inherit;
    background-color: white;
    border-radius: 2ch;
    padding: 2ch;
    text-align: center;
}

.updatedProfile > div button{
    scale: 0.8;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px) {

    .p8perfil-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .p8perfil-grid>div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .p8perfil-grid>div input {
        width: 95%;
    }

    .p8perfil-grid>div img {
        margin-bottom: 1ch;
        align-self: flex-start;
    }


    #p8-inputImg {
        border: none;
        background-color: transparent;
        padding: 0;
        font-size: 4vw;
        text-align: left !important;
    }

    #p8-inputImg>p:not(:nth-child(5)),
    #p8-inputImg img {
        display: none;
    }

    #p8-inputImg>p:nth-child(1) {
        display: flex;
        align-items: center;
        color: black;
    }

    #p8-inputImg>p img {
        display: flex;
        margin-right: 1ch;
    }

    #p8-inputImg>p:nth-child(1)::before {
        content: "+";
        position: absolute;
        left: 32vw;
        font-size: 8vw;
        color: orangered;
    }

    #p8-inputImg p {
        text-align: left;
        width: 100%;
    }

    #p8-inputImg>input {
        display: flex;
        margin-top: -10vw;
        padding-top: 10vw;
    }

    .updatedProfile > div{
        max-width: calc(90vw - 4ch);
    }


}</style>