<template>
    <div class="SubirLocacion2locacion-MAIN">
        <p><img src="../assets/images/icons/route.svg"/>Dirección de tu locación</p>
        <div class="pantalla5caracteristicas-inputs">
            <input type="text" placeholder="Código postal*" required style="grid-area:codigoPostal;" id="p5-codigoPostal" v-model="inputValue" @click="invalidate()" @input="codigoPostal()" maxlength="5" pattern="\d\d\d\d\d" @blur="preserveUnsavedData()"/>
            <input type="text" placeholder="Estado" name="p5-estado" id="p5-estado" style="grid-area:estado;" @blur="preserveUnsavedData()"/>
            <input type="text" placeholder="Municipio" name="p5-municipio" id="p5-municipio" style="grid-area:municipio;" @blur="preserveUnsavedData()"/>
            <input type="text" placeholder="Calle y número exterior*" required style="grid-area:calle;" id="p5-calle" @blur="preserveUnsavedData()"/>
            <input type="text" placeholder="Número interior*" required style="grid-area:numInterior;" id="p5-numInterior" @blur="preserveUnsavedData()"/>
        </div>

        <!--<p class="p5-min-txt">* Estos datos son confidenciales y solo serán vistos una vez que el usuario interesado haya agendado una visita.</p>-->
        <p class="p5-min-txt">
            *El usuario solo podrá ver la información de alcaldía y la ciudad. Es importante que no haya falsedad en estos datos, ya que debe ser comprobada la existencia de esta locación, por la seguridad de tus interesados.
            <a :href="getTerminosUrl()">Términos y condiciones.</a>
        </p>

        <!--<p><img src="../assets/images/icons/marker.svg"/>Ubica tu Locación en el mapa para asegurar la dirección:</p>

        <div class="pantalla5-mapa">
            <iframe  id="pantalla5-mapa" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d10233563.78520139!2d-101.08002536072227!3d24.43677062984202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2smx!4v1689285767024!5m2!1ses!2smx" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>-->

        <button v-on:click="nextStep(), preserveUnsavedData()">Siguiente</button>
    </div>
</template>

<script>
export default {
    name: 'SubirLocacion2locacion',
    data() {
        return {
            inputValue: "",
            codeSaved: ""
        };
    },
    methods:{
        nextStep(){
            this.$parent.nextStep();
        },

        invalidate(){
            document.getElementById("p5-codigoPostal").className = "invalidate";    
        },

        setMap(){
            const longitud = -101.08002536072227;
            const latitud = 24.43677062984202;
            
            const url = `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d10233563.78520139!2d${longitud}!3d${latitud}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2smx!4v1689285767024!5m2!1ses!2smx`;

            document.getElementById("pantalla5-mapa").src = url;
        },

        codigoPostal() {
            // Verificar si el valor consiste solo en números
            if (/^\d*$/.test(this.inputValue)) {
                if (this.inputValue.length >= 5) {
                    let codigo = this.inputValue;

                    if (this.codeSaved !== this.inputValue) {
                        this.codeSaved = this.inputValue;
                        fetch('https://locacionbackoffice.ideeo.mx/api/obtenerCP/' + codigo, {method: 'GET'})
                        .then(response => response.json())
                        .then(json => {
                            let result = json[0].response;
                            console.log(json);
                            document.getElementById("p5-municipio"). value = result.municipio;
                            document.getElementById("p5-estado"). value = result.estado;
                            this.preserveUnsavedData();
                            //this.setMap();
                        });
                    }
                }
            }
        },

        preserveUnsavedData(){
            this.$parent.preserveUnsavedData();
        },

        getTerminosUrl(){
            const terminosUrl = window.location.href.split("#")[0]+"#/terminos";
            return terminosUrl;
        }
    }
}
</script>

<style scoped>
.SubirLocacion2locacion-MAIN{
    display: flex;
    flex-direction: column;
}

.pantalla5caracteristicas-inputs{
    display: grid;
    grid-template-areas:"codigoPostal municipio"
                        "calle calle"
                        "numInterior estado";
    grid-column-gap: 6ch;
    grid-row-gap: 3ch;
}

.pantalla5-mapa{
    height: 30ch;
    overflow: hidden;
    border-radius: 1ch;
    margin-top: 1ch;
    margin-bottom: 3ch;
}

.invalidate:invalid{
    border: 1px solid red;
    outline: 1px solid red;
}

.check-invalid:invalid, .check-false{
    border: 1px solid red !important;
    outline: 1px solid red !important;
    background-color: rgb(255, 241, 241);
}

.p5-min-txt{
    display: inline;
}
/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    .pantalla5caracteristicas-inputs{
        grid-template-areas:"estado"
                            "municipio"
                            "calle"
                            "numInterior"
                            "codigoPostal";
    }

}
</style>