<template>
    <div id="PreguntasFrecuentes-MAIN">
        <NavLocacion :currentSection="'Preguntas Frecuentes'"/>

        <main>
            <br><h1>Preguntas Frecuentes</h1>

            <!--repeat-->
            <div class="pregunta" v-for="(question, index) in preguntas" :key="index">
                <img src="../assets/images/icons/arrow-small.svg" @click="showQuestion(question.id)" :id="`desplegar-pregunta-${question.id}`"/>
                <h3>{{ question.pregunta }}</h3>
                <hr>
                <div :id="`respuesta-${question.id}`" v-html="linkPhoneNum(linkTerminosYCondiciones(links(question?.respuesta)))"></div>
            </div>
            
        </main>

        <FooterLocacion/>
    </div>
</template>

<script>
import FooterLocacion from './FooterLocacion.vue'
import NavLocacion from './NavLocacion.vue'
export default {
  components: { NavLocacion, FooterLocacion },
    name: 'PreguntasFrecuentes',
    data(){
        return{
            preguntas: [],
            showing: undefined
        }
    },
    methods:{
        getPreguntas(){
            //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://locacionbackoffice.ideeo.mx/api/preguntas', {method: 'get',
                headers: {
                    "Content-type": "application/json"
                }})
            .then(response => response.json())
            .then(json => {
                for(let i of json)
                    if(i.activo == 1 && i.pregunta != null && i.respuesta != null){
                        this.preguntas.push(i);
                    }
                console.log("this.preguntas", this.preguntas);
            })
        },

        showQuestion(id_){
            document.getElementById(`respuesta-${id_}`).style.display = "block";
            document.getElementById(`desplegar-pregunta-${id_}`).style.transform = "rotate(180deg)";
            if(typeof this.showing != "undefined"){
                document.getElementById(`respuesta-${this.showing}`).style.display = "none";
                document.getElementById(`desplegar-pregunta-${this.showing}`).style.transform = "rotate(0deg)";
            }
                
            if(id_ == this.showing)
                this.showing = undefined;
            else
                this.showing = id_;
        },

        links(str_){
            // Expresión regular para encontrar los enlaces
            var regex = /https:\/\/\S+/g;
            // Reemplazar los enlaces con etiquetas <a>
            var resultado = str_.replace(regex, function (match) {
                return '<a href="' + match + '">' + match + '</a>';
            });
            return resultado;
        },

        linkTerminosYCondiciones(str_){
            var regex = /Términos\s+y\s+Condiciones/gi;
            var resultado = str_.replaceAll(regex, '<a href="https://dev.ideeo.mx/locaciones/#/terminos" target="_blank">Términos y Condiciones</a>');
            return resultado;
        },

        linkPhoneNum(str_) {
            // Expresión regular para encontrar números de teléfono
            const phoneRegex = /(\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s*\d{3}-\d{4}|\d{3}\s+\d{3}\s+\d{4})/g;
            // Reemplazar los números de teléfono con enlaces
            const linkedText = str_.replace(phoneRegex, '<a href="tel:$1">$1</a>');
            return linkedText;
        }
    },
    mounted(){
        this.getPreguntas();
    }
}
</script>

<style scoped>
    #PreguntasFrecuentes-MAIN main{
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #F4F4F4;
        padding-bottom: 10ch;
    }

    #PreguntasFrecuentes-MAIN h1{
        text-align: center;
        max-width: 90vw;
    }

    .pregunta{
        border-radius: 1ch;
        background-color: white;
        width: 80vw;
        margin: 1ch;
        padding: 2ch;
        position: relative;
        height: fit-content;
        transition: all 0.2s;
    }

    .pregunta > img{
        position: absolute;
        top: 1ch;
        right: 1ch;
        width: 1.5ch;
        cursor: pointer;
        transition: transform 0.2s;
        padding: 1ch;
    }

    .pregunta > hr{
        width: 10vw;
        justify-self: flex-start !important;
        margin: 0;
        margin-bottom: 1.5ch;
        border: 2px solid #FF5107;
    }

    .pregunta > div{
        margin-bottom: 1ch;
        display: none;
    }
</style>