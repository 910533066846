<template>
    <div id="TerminosCondiciones-MAIN">
        <NavLocacion/>

        <main>
            <br><h1>Términos y condiciones</h1>
            <p class="en-el-caso">En el caso de que no esté de acuerdo con los términos y condiciones de uso y<br>privacidad debe abstenerse de acceder o utilizar el portal.</p>

            <div class="terminos" v-html="terminosHTML">
            </div>

            <br>
        </main>

        <FooterLocacion/>
    </div>
</template>

<script>
import FooterLocacion from './FooterLocacion.vue'
import NavLocacion from './NavLocacion.vue'
export default {
  components: { NavLocacion, FooterLocacion },
    name: 'TerminosCondiciones',
    data(){
        return{
            terminosHTML: ""
        }
    },
    methods:{
        getTerminos(){
            try {
                //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
                fetch('https://locacionbackoffice.ideeo.mx/api/terminos', {method: 'get',
                    headers: {
                            "Content-type": "application/json"
                        }})
                    .then(response => response.json())
                    .then(json => {
                        //console.log(json[0].contenido)
                        this.terminosHTML = json[0].contenido;
                    }) 
            } catch (error) {
                console.log(error);
            }
        }
    },
    mounted(){
        this.getTerminos();
    }
}
</script>

<style scoped>
    #TerminosCondiciones-MAIN main{
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #F4F4F4;
    }

    #TerminosCondiciones-MAIN h1, .en-el-caso{
        text-align: center;
        max-width: 90vw;
    }

    .en-el-caso{
        text-align: center;
        margin-bottom: 4ch;
    }

    .terminos{
        border-radius: 1ch;
        background-color: white;
        width: 80vw;
        margin: 1ch;
        padding: 2ch;
        position: relative;
        height: fit-content;
        transition: all 0.2s;
        margin-bottom: 6ch;
    }

</style>