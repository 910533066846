<template>
    <div class="SubirLocacion3operativo-MAIN">
        <p><img src="../assets/images/icons/id-badge.svg"/>Detalles de tu Operación</p>
        <p class="p5-min-txt" style="margin-top: 0;">*Esta información no podrá ser vista en tu anuncio.</p>
        <div class="p5-sl3-radios" id="p5-sl3-radios">
            <label @click="validate('p5-sl3-radios')">*<input type="radio" name="upload-detalles-de-operacion" value="propietario" @blur="preserveUnsavedData()">Soy propietario</label>
            <label @click="validate('p5-sl3-radios')">*<input type="radio" name="upload-detalles-de-operacion" value="administrador" @blur="preserveUnsavedData()">Soy el administrador</label>
            <label @click="validate('p5-sl3-radios')">*<input type="radio" name="upload-detalles-de-operacion" value="empresa" @blur="preserveUnsavedData()">Soy empresa</label>
        </div>
        <div class="p5-sl3-input-detalles">
            <!--<div><img src="../assets/images/icons/users.svg"/><input type="text" placeholder="Capacidad de personas" id="upload-capacidad-personas" required></div>-->
            <div><img src="../assets/images/icons/money-check-edit.svg"/><span class="span-dollar">$</span><input type="number" class="input-num-no-arrows" placeholder="Costo mínimo que acepto por proyecto" id="upload-costo-minimo-proyecto" required @blur="preserveUnsavedData()" maxlength="7"></div>
        </div>
        
        <p class="p5-ls3-opcional-txt">Opcional:</p>
        
        <div class="p5-sl3-input-detalles p5-ls3-opcional"><div><img src="../assets/images/icons/money-check-edit.svg"/><span class="span-dollar">$</span><input type="number" class="input-num-no-arrows" placeholder="Costo por hora" id="p5-ls3-opcional-costoHora" required @blur="preserveUnsavedData()" maxlength="7"></div></div>
        <div class="p5-sl3-input-detalles p5-ls3-opcional"><div><img src="../assets/images/icons/money-check-edit.svg"/><span class="span-dollar">$</span><input type="number" class="input-num-no-arrows" placeholder="Costo por día" id="p5-ls3-opcional-costoDia" required @blur="preserveUnsavedData()" maxlength="7"></div></div>
        
        <p style="margin-bottom:0ch; margin-top:2ch;">Elige un costo por visita a tu propiedad o Locación</p>
        <div>
            <p class="p5-min-txt">*El costo que coloques es fundamental para lograr visitas recurrentes<br><br><strong>Costo promedio actual por visitas: De $520 a $600</strong></p>
        </div>

        <p><img src="../assets/images/icons/usd-circle.svg"/>Costo por tu visita</p>
        <div style="display: flex; align-items: center;"><span class="span-dollar">$</span><input type="number" placeholder="0" id="p5-costoPorVisita" required step="50" min="498" max="4999" maxlength="7" v-model="costoPorVisita" @input="calcularGananciaPorVisita" @blur="checkOutRange(), preserveUnsavedData()" @click="fixLimit()"/> &nbsp;Costo de $498 a $4999</div>
        <div>
            <p class="p5-min-txt">Este monto será publicado en tu anuncio.</p>
        </div>

        <p class="build-green-p"><img src="../assets/images/icons/building-green.svg" class="build-green"/>Tu ganancia por visita</p>
        <p class="p5-min-txt">Esta será tu ganancia.</p>
        <input type="text" placeholder="$0.00" id="p5-gananciaPorVisita" disabled v-model="gananciaPorVisita" style="color:rgb(38, 189, 18);"/>
        <p class="p5-min-txt">Aplicada retención por manejo de plataforma del 20% e impuestos.</p>

        <p class="OfrezcoQueEnCasoConcrete"><img src="../assets/images/icons/file-invoice-dollar.svg"/> Ofrezco que en caso de que se concrete el proyecto en mi locación, tomo a cuenta el monto de costo de visita publicado en mi anuncio.</p>
        <p class="p5-min-txt" style="margin-top: 0;">Esto te ayudará a tener visitas potenciales.</p>
        <div class="p5-sl3-SiNo" id="p5-sl3-SiNo">
            <label @click="validate('p5-sl3-SiNo')"><input type="radio" name="OfrezcoEnCasoDeConcreteProyecto" value="1" @blur="preserveUnsavedData()">Sí</label>
            <label @click="validate('p5-sl3-SiNo')"><input type="radio" name="OfrezcoEnCasoDeConcreteProyecto" value="0" @blur="preserveUnsavedData()">No</label>
        </div>
        <p class="p5-min-txt">La evaluación y selección de tu locación, depende en gran manera de que sea exclusiva y cerrada al público; ya que la plataforma Locación, lo que ofrece es un gran escaparate de espacios únicos y creativos para potenciales líderes de proyectos, o gente que busca innovar en sus reuniones o eventos.</p>

        <label class="p5-aceptar-terminos">
            <input type="checkbox" id="p5-aceptar-terminos" v-model="aceptarTerminos"> Acepto términos y condiciones
        </label>
        <div class="p5-sl3-buttons">
            <button v-on:click="previewLocation()">Cómo se verá mi publicación</button>
            <button v-on:click="nextStep(true)" :class="{ 'not-button': !aceptarTerminos }" id="finalPostBtn">Publicar</button>
        </div>

        <div class="previewLocationPopUp" v-if="preview">
            <div class="close-btn-preview" @click="preview = false">+</div>
            <DetalleLocacion :showBar="false" class="detalleLocacion-COMPONENT"/>
        </div>
    </div>
</template>

<script>
import DetalleLocacion from './DetalleLocacion.vue';

export default {
    name: 'SubirLocacion3operativo',
    data() {
        return {
            costoPorVisita: "",
            gananciaPorVisita: "$ " + 0,
            aceptarTerminos: false,
            preview: false
        };
    },
    methods: {
        nextStep(post_) {
            document.getElementById("finalPostBtn").style.pointerEvents = "none";
            document.getElementById("finalPostBtn").style.opacity = 0.5;
            this.$parent.nextStep(post_);
        },
        validate(id_) {
            document.getElementById(id_).classList.remove("check-false");
        },
        calcularGananciaPorVisita() {
            // Calcular la ganancia restando el 10% del costoPorVisita
            //this.gananciaPorVisita = this.costoPorVisita - (this.costoPorVisita * 0.20);
            //this.gananciaPorVisita = "$ " + (this.gananciaPorVisita - (this.costoPorVisita * 0.16));
            let cVisita = this.costoPorVisita;
            let comision = cVisita * 0.20;
            let ivaComision = comision * 0.16;
            this.gananciaPorVisita = "$ " + (cVisita - comision - ivaComision).toFixed(2);
        },
        checkOutRange() {
            if (this.costoPorVisita < 498)
                this.costoPorVisita = 498;
            if (this.costoPorVisita > 4999)
                this.costoPorVisita = 4999;
            this.calcularGananciaPorVisita();
        },
        fixLimit(){
            if (this.costoPorVisita == 4998)
                this.costoPorVisita = 4999;
        },
        preserveUnsavedData() {
            this.$parent.preserveUnsavedData();
        },
        previewLocation() {
            this.preserveUnsavedData();
            console.log("PARA VISTA PREVIA");
            //let result = window.location.href.replace("#/subir-locacion", "#/detalle");
            //window.open(result, '_blank');
            this.preview = true;
        }
    },
    components: { DetalleLocacion },

    mounted(){
        setTimeout(() => {
            let costo = document.getElementById("p5-costoPorVisita").value;
            if(costo.length > 0){
                this.costoPorVisita = costo;
                this.calcularGananciaPorVisita();
            }
        }, 3000);
        //this.calcularGananciaPorVisita();
    }
}
</script>

<style scoped>
.SubirLocacion3operativo-MAIN{
    display: flex;
    flex-direction: column;
}

.p5-sl3-radios{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1ch;
    padding: 0.5ch 0.5ch;
}

.p5-sl3-input-detalles{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3.5ch;
}

.p5-ls3-opcional-txt{
    margin-left: 3ch;
    margin-top: 1.5ch;
    margin-bottom: 1ch;
}

.p5-ls3-opcional{
    margin: 0;
}

.p5-ls3-opcional img{
    opacity: 0;
}

.p5-ls3-opcional > div:nth-child(1){
    margin-bottom: 1.5ch;
}

.p5-sl3-input-detalles > div{
    width: 47%;
    display: flex;
}

.p5-sl3-input-detalles > div input{
    display: flex;
    align-items: center;
    width: 100%;
}

.p5-sl3-input-detalles > div input::placeholder{
    font-size: 1.3ch;
}

.p5-sl3-input-detalles > div img{
    max-width: 3ch;
    margin-right: 1ch;
}

#p5-costoPorVisita, #p5-gananciaPorVisita{
    width: 20ch;
}

.OfrezcoQueEnCasoConcrete{
    align-items: flex-start;
}

.p5-sl3-SiNo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20%;
    min-width: fit-content;
    padding: 0.5ch 0.5ch;
}

.check-invalid:invalid, .check-false{
    border: 1px solid red !important;
    outline: 1px solid red !important;
    background-color: rgb(255, 241, 241);
}

input:not(#p5-gananciaPorVisita){
    padding-left: 3ch !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

.build-green-p{
    margin-bottom: 0 !important;
    color: rgb(38, 189, 18);
}

.p5-aceptar-terminos, .p5-sl3-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.p5-aceptar-terminos{
    margin: 2ch 0;
}

.p5-sl3-buttons{
    justify-content: space-evenly;
    margin-bottom: 2ch;
}

.not-button{
    background-color: rgb(231, 231, 231) !important;
    background: unset;
    color: gray;
    cursor: unset;
}

.previewLocationPopUp{
    position: fixed;
    top: 6.5ch;
    left: 0;
    z-index: 2;
    width: 100% !important;
    max-width: unset !important;
    height: calc(100vh - 6.5ch);
    overflow: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start !important;
    background-color: rgba(0, 0, 0, 0.5);
}

.detalleLocacion-COMPONENT{
    height: 100%;
    width: 80% !important;
    background-color: transparent;
    height: 100%;
    margin-bottom: 2ch !important;
}

.close-btn-preview{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 2ch;
    height: 2ch;
    font-size: 5ch;
    transform: rotate(45deg);
    color: white;
    position: fixed;
    top: 2ch;
    right: 1ch;
    cursor: pointer;
}

.span-dollar{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -2ch;
    margin-left: 1ch;
    z-index: 2;
}


/* Chrome, Safari, Edge, Opera */
.input-num-no-arrows::-webkit-outer-spin-button,
.input-num-no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-num-no-arrows{
  -moz-appearance: textfield;
}
/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    .p5-sl3-radios{
        flex-direction: column;
        width: fit-content;
        align-self: center;
    }

    .p5-sl3-radios > label{
        width: 100%;
        margin-bottom: 3vw;
    }

    #upload-costo-minimo-proyecto{
        width: 50vw !important;
    }

    #pantalla5-step3{
        
        max-width: 80vw !important;
    }

    #pantalla5-step3 > *{
        max-width: 80vw !important;
        display: flex;
        padding: 0;
    }

    .p5-sl3-buttons{
        flex-direction: column;
    }

    .previewLocationPopUp{
        width: 100vw !important;
        max-width: 100vw !important;
        min-width: 100vw !important;
        height: calc(100vh - 14ch);
        z-index: 3;
    }

    .close-btn-preview{
        width: 8vw;
        height: 8vw;
        font-size: 8vw;
        top: 4.5ch;
        right: 3vw;
        z-index: 2;
    }

    .input-num-no-arrows{
        max-width: unset !important;
        width: 55vw;
        min-width: 55vw;
    }

}
</style>