<template> <!-- 5 Pantalla Proceso Sube tu Locación. Usuario con sesión iniciada -->
    <div class="SubirLocacionWithSession-MAIN">

        <NavLocacion :currentSection="'Sube tu locacion'"/>
        
        <div class="SubirLocacion-back-cel"><img src="../assets/images/icons/arrow-long.svg" @click="returnOnMobile()"></div>

        <p class="ElNuevoMundoDeLocaciones">El nuevo mundo de las locaciones te espera</p>

        <div id="p5-progress-bar">
            <img src="../assets/images/icons/building.svg"/>
            <div>
                <div id="p5-progress"></div>
            </div>
            <span id="p5-progress-step">{{currentStep}}/3</span>
        </div>

        <div class="p5-video-tutorial">
            <div>
                <p><span @click="switchVideoTutorial(true)">Haz click aquí para ver nuestro video tutorial.</span></p>
                <VideoTutorial v-if="videoTutorialActive"/>
            </div>
        </div>

        <div class="p5-aside-cel">
            <p @click="switchSection(1)">Características<span><img src="../assets/images/icons/arrow.svg"/></span></p>
            <hr>
            <p @click="switchSection(2)">Locación<span><img src="../assets/images/icons/arrow.svg"/></span></p>
            <hr>
            <p @click="switchSection(3)">Operativo<span><img src="../assets/images/icons/arrow.svg"/></span></p>
        </div>

        <section>
            <aside>
                <button id="pantalla5-aside-menu1" class="general-btn" v-on:click="switchSection(1)">Características</button>
                <div id="pantalla5-submenu1">
                    <p @click="focusOn(1, 'upload-caracteristicasNombre')">Nombre</p>
                    <p @click="focusOn(1, 'p5-sl1-radios')">¿Para qué vas a usar tu locación?</p>
                    <p @click="focusOn(1, 'upload-caracteristicasDescripcion')">Describe tu locación</p>
                    <p @click="focusOn(1, 'p5-inputImg-fotos')">Fotos</p>
                </div>
                <button id="pantalla5-aside-menu2" class="general-btn" v-on:click="switchSection(2)">Locación</button>
                <div id="pantalla5-submenu2">
                    <p @click="focusOn(2, 'p5-codigoPostal')">Dirección de tu Locación</p>
                    <!--<p @click="focusOn(2, 'p5-codigoPostal')">Ubica tu Locación en el mapa para asegurar la dirección</p>-->
                </div>
                <button id="pantalla5-aside-menu3" class="general-btn" v-on:click="switchSection(3)">Operativo</button>
                <div id="pantalla5-submenu3">
                    <p @click="focusOn(3, 'p5-sl3-radios')">Detalles de tu Operación</p>
                    <p @click="focusOn(3, 'p5-costoPorVisita')">Costo por tu visita</p>
                    <!--<p @click="focusOn(3, '')">Foto de tu INE o Pasaporte</p>
                    <p @click="focusOn(3, '')">Redes sociales</p>-->
                </div>
                <div class="upload-preview" v-on:click="verifyData(true)">
                    <div class="upload-preview-type-div"><img src="" id="upload-preview-type"></div>
                    <div id="upload-preview-images">
                    </div>
                </div>
                <a href="tel:55-35-55-20-39" target="_blank"><img src="../assets/images/banner.png" class="banner"/></a>
            </aside>

            <div id="pantalla5-components">
                <SubirLocacion1caracteristicas id="pantalla5-step1" @images-uploaded="handleImagesUploaded" />
                <SubirLocacion2locacion id="pantalla5-step2"/>
                <SubirLocacion3operativo id="pantalla5-step3"/>
            </div>
        </section>

        <div id="subirLocacionPopUp-ok">
            <div>
                <div class="subirLocacion-close-button" @click="showPopUp(false, false)">+</div>
                <h2 id="slPopUpOk-title">¡Importante!</h2>
                <p id="slPopUpOk-text"><strong>Recuerda tener completo tu perfil para que podemos validar tu anuncio.</strong><br> Verifica que tengas tu INE y foto de perfil activa. <br>Muy pronto te estará llegando una notificación, vía correo electrónico, donde te avisaremos si tu anuncio fue aprobado o no. Recuerda revisar tu bandeja de spam.</p>
                <p v-html="infoProfile()"></p>
                <button @click="showPopUp(false, true)" id="slPopUpOk-btn-continuar">Continuar</button>
                <button @click="showPopUp(false, false)" id="slPopUpOk-btn-cerrar">Cerrar</button>
            </div>
        </div>

        <div v-if="incompleteDataAlert" class="incompleteDataPopUp">
            <div>
                <h2>Te falta llenar los siguientes datos datos</h2>
                <p>{{ incompleteInput }}</p>
                <button @click="incompleteDataAlert = false, restoreButton()">Aceptar</button>
            </div>
        </div>

        <FooterLocacion/>
    </div>
</template>

<script>
import { element } from 'alexa-reactor-core'
import FooterLocacion from './FooterLocacion.vue'
/* eslint-disable vue/no-unused-components */
import NavLocacion from './NavLocacion.vue'
import SubirLocacion1caracteristicas from './SubirLocacion1caracteristicas.vue'
import SubirLocacion2locacion from './SubirLocacion2locacion.vue'
import SubirLocacion3operativo from './SubirLocacion3operativo.vue'
import VideoTutorial from './VideoTutorial.vue'
export default {
    components: { NavLocacion, SubirLocacion1caracteristicas, SubirLocacion2locacion, SubirLocacion3operativo, FooterLocacion, VideoTutorial },
    name: 'SubirLocacionWithSession',
    data(){
        return{
            currentStep: 1,
            selectedImagesUploaded: [],
            mode: "new",
            locationByURL: undefined,
            incompleteDataAlert: false,
            incompleteInput: "",
            videoTutorialActive: false,
        }
    },
    methods:{

        handleImagesUploaded(images) {
            this.selectedImagesUploaded = images; // Actualizar los datos del componente hijo en el componente padre
        },

        getQueRentas(){
            let inputs = document.getElementsByName("p5-queRentas-inputs");
            let arr = [];
            for(let i of inputs)
                if(i.checked)
                    arr.push(i.value);
            return arr;
        },

        getQueSePuedeHacer(){
            let inputs = document.getElementsByName("p5-queSePuedeHacer-inputs");
            let arr = [];
            for(let i of inputs)
                if(i.checked)
                    arr.push(i.value);
            return arr;
        },

        getBeneficios(){
            let inputs = document.getElementsByName("p5-beneficios-inputs");
            let arr = [];
            for(let i of inputs)
                if(i.checked)
                    arr.push(i.value);
            return arr;
        },

        switchSection(step_){
            ['1', '2', '3'].forEach(step => {
                document.getElementById(`pantalla5-step${step}`).style.display = 'none';
                document.getElementById(`pantalla5-aside-menu${step}`).style.fontWeight = '400';
                document.getElementById(`pantalla5-aside-menu${step}`).style.borderBottom = '2px solid transparent';
                document.getElementById(`pantalla5-submenu${step}`).style.maxHeight =  '0px';
                document.getElementById(`pantalla5-aside-menu${step}`).classList.add("general-btn-inactive");
                console.log(step)
            });

            document.getElementById(`pantalla5-step${step_}`).style.display = "flex";
            document.getElementById(`pantalla5-aside-menu${step_}`).style.fontWeight = "bolder";
            document.getElementById(`pantalla5-aside-menu${step_}`).style.borderBottom = "2px solid #FF282A";
            document.getElementById(`pantalla5-submenu${step_}`).style.maxHeight = "500px";
            document.getElementById("p5-progress").style.width = `${step_*3}${step_*3}%`;
            document.querySelector(".p5-aside-cel").style.display = "none";
            document.getElementById("pantalla5-components").style.display = "flex";
            document.getElementById(`pantalla5-aside-menu${step_}`).classList.remove("general-btn-inactive");
            this.currentStep = step_;
        },

        returnOnMobile(){
            ['1', '2', '3'].forEach(step => {
                document.getElementById(`pantalla5-step${step}`).style.display = 'none';
                document.getElementById(`pantalla5-aside-menu${step}`).style.fontWeight = '400';
                document.getElementById(`pantalla5-aside-menu${step}`).style.borderBottom = '2px solid transparent';
                document.getElementById(`pantalla5-submenu${step}`).style.maxHeight =  '0px';
            });
            document.querySelector(".p5-aside-cel").style.display = "flex";
            document.getElementById("pantalla5-components").style.display = "none";
        },

        nextStep(post_){
            if(this.verifyData() == false)
                return;
            if(this.currentStep <=2){
                this.currentStep += 1;
                this.switchSection(this.currentStep);
            } 
            if(this.currentStep == 3 && post_ == true){
                this.verifyData("publicar");
            }
            setTimeout(() => {
                window.scrollTo(0, 0);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 800);
        },

        verifyData(preview_=false){
            //Revisar si todos los campos son correctos;
            let CaracteristicasNombre = document.getElementById("upload-caracteristicasNombre");
            let paraQueUsarLocacion = `${document.getElementById('upload-para-que-usar-locacion-s').checked == true ? 'S' : ''}${document.getElementById('upload-para-que-usar-locacion-f').checked == true ? 'F' : ''}${document.getElementById('upload-para-que-usar-locacion-r').checked == true ? 'R' : ''}${document.getElementById('upload-para-que-usar-locacion-e').checked == true ? 'E' : ''}`;

            //console.log(paraQueUsarLocacion);
            let tipoDeLocacion = this.getQueRentas();
            let queSePuedeHacer = this.getQueSePuedeHacer();
            let beneficios = this.getBeneficios();
            let restricciones = {
                limiteAsistentes: document.getElementById("p5r-limiteAsistentes").value,
                horaLLegada: document.getElementById("p5r-horaLlegada").value,
                horaSalida: document.getElementById("p5r-horaSalida").value,
                fumadores: document.getElementById("p5r-fumadores").checked,
                mascotas: document.getElementById("p5r-mascotas").checked,
                fiestas: document.getElementById("p5r-fiestas").checked,
                menores: document.getElementById("p5r-menores18").checked,
                catering: document.getElementById("p5r-catering").checked,
                montajes: document.getElementById("p5r-montajes").checked,
                valet_parking: document.getElementById("p5r-valetparking").checked,
                poliza: document.getElementById("p5r-poliza").checked,
                permisos: document.getElementById("p5r-permisos").checked,
                adicionales: document.getElementById("p5r-adicionales").value
            };
            let descripcion = document.getElementById("upload-caracteristicasDescripcion");
            let uploadImages = this.selectedImagesUploaded;


            let estado = document.getElementById("p5-estado");
            let municipio = document.getElementById("p5-municipio");
            let calle = document.getElementById("p5-calle");
            let numInterior = document.getElementById("p5-numInterior");
            let codigoPostal = document.getElementById("p5-codigoPostal");
            
            const radiosDetallesDeOperacion = document.getElementsByName("upload-detalles-de-operacion");
            let detallesDeOperacion;
            radiosDetallesDeOperacion.forEach(radio => {
                if (radio.checked)
                    detallesDeOperacion = radio.value;
            });
            let capacidadPersonas = 0; //document.getElementById("upload-capacidad-personas");
            let costoMinimoProyecto = document.getElementById("upload-costo-minimo-proyecto");
            let costoPorVisita = document.getElementById("p5-costoPorVisita");
            let gananciaPorVisita = document.getElementById("p5-gananciaPorVisita");
            const radiosTomoACuentaPago = document.getElementsByName("OfrezcoEnCasoDeConcreteProyecto");
            let tomoACuentaPago;
            radiosTomoACuentaPago.forEach(radio => {
                if (radio.checked)
                    tomoACuentaPago = radio.value;
            });

            if(preview_ == true){
                this.previewLocation(CaracteristicasNombre.value, paraQueUsarLocacion, tipoDeLocacion, queSePuedeHacer, restricciones.value, descripcion.value, uploadImages, estado.value, municipio.value, calle.value, numInterior.value, codigoPostal.value, detallesDeOperacion, capacidadPersonas.value, costoMinimoProyecto.value, costoPorVisita.value, gananciaPorVisita.value, tomoACuentaPago);
                return;
            }

            /*
            console.log(CaracteristicasNombre.value);
            console.log(paraQueUsarLocacion);

            console.log(restricciones.value);
            console.log(descripcion.value);
            console.log(uploadImages);
            */
            console.log(tipoDeLocacion);
            console.log(queSePuedeHacer);
            if(CaracteristicasNombre.value == "" ||
                paraQueUsarLocacion === "" ||
                tipoDeLocacion.length === 0 ||
                queSePuedeHacer.length === 0 ||
                beneficios.length === 0 ||
                restricciones.limiteAsistentes == "" ||
                restricciones.horaLLegada == "" ||
                restricciones.horaSalida == "" ||
                descripcion.value == "" ||
                uploadImages.length == 0
            ){
                
                this.currentStep = 1;
                this.switchSection(this.currentStep);
                setTimeout(() => {
                    window.scrollBy({top: -100, behavior: "smooth"});
                }, 80);

                if(CaracteristicasNombre.value == "") {
                    setTimeout(() => {
                        CaracteristicasNombre.focus();
                    }, 500);
                    this.popUpIncompleteData("Nombre");
                    CaracteristicasNombre.classList.add("check-invalid"); return false;
                }
                if(paraQueUsarLocacion == "[]" || paraQueUsarLocacion.length == 0){
                    setTimeout(() => {
                        document.getElementById("p5-sl1-radios").focus();
                    }, 500);
                    this.popUpIncompleteData("¿Para qué vas a usar tu locación?");
                    document.getElementById("p5-sl1-radios").classList.add("check-false");
                    return false;
                }else {
                    document.getElementById("p5-sl1-radios").classList.remove("check-false");
                }

                if(restricciones.limiteAsistentes == "" ||
                restricciones.horaLLegada == "" ||
                restricciones.horaSalida == ""){
                    setTimeout(() => {
                        document.getElementById("p5-restricciones").focus();
                    }, 500);
                    document.getElementById("p5-restricciones").classList.add("check-false");
                    this.popUpIncompleteData("Restricciones");
                    return false;
                }

                if (tipoDeLocacion.length === 0) {
                    console.log("tipoDeLocacion está vacío");
                    setTimeout(() => {
                        element("#p5-queRentas").focus();
                    }, 500);
                    element("#p5-queRentas").classList.add("check-false");
                    this.popUpIncompleteData("¿Qué es lo que rentas?");
                    return false;
                } else {
                    element("#p5-queRentas").classList.remove("check-false");
                }
                if (queSePuedeHacer.length === 0) {
                    console.log("queSePuedeHacer está vacío");
                    setTimeout(() => {
                        element("#p5-QueSePuedeHacer").focus();
                    }, 500);
                    element("#p5-QueSePuedeHacer").classList.add("check-false");
                    this.popUpIncompleteData("¿Qué se puede hacer?");
                    return false;
                } else {
                    element("#p5-QueSePuedeHacer").classList.remove("check-false");
                }
                if (beneficios.length === 0) {
                    console.log("beneficios está vacío");
                    element("#p5-beneficios").classList.add("check-false");
                    setTimeout(() => {
                        element("#p5-beneficios").focus();
                    }, 500);
                    this.popUpIncompleteData("Beneficios");
                    return false;
                } else {
                    element("#p5-beneficios").classList.remove("check-false");
                }

                if(descripcion.value == "") {
                    setTimeout(() => {
                        descripcion.focus();
                    }, 500);
                    this.popUpIncompleteData("Descripción");
                    descripcion.classList.add("check-invalid"); return false;
                }
                if(uploadImages.length == 0){
                    setTimeout(() => {
                        document.getElementById("p5-inputImg").focus();
                    }, 500);
                    document.getElementById("p5-inputImg").classList.add("check-false");
                    this.popUpIncompleteData("Fotografías");
                    return false;
                }else{
                    document.getElementById("p5-inputImg").classList.remove("check-false");
                }

                return false;
            }
            
            /*console.log(estado.value);
            console.log(municipio.value);
            console.log(calle.value);
            console.log(numInterior.value);
            console.log(codigoPostal.value);
            */
            if(//estado.value == "Estado" ||
                //municipio.value == "Municipio" ||
                calle.value == "" ||
                codigoPostal.value == ""
            ){
                this.currentStep = 2;
                this.switchSection(this.currentStep);
                setTimeout(() => {
                    window.scrollBy({top: -100, behavior: "smooth"});
                }, 80);
                if(codigoPostal.value == "") {
                    setTimeout(() => {
                        codigoPostal.focus(); 
                    }, 500);
                    this.popUpIncompleteData("Código postal");
                    codigoPostal.classList.add("check-invalid"); return false;}
                if(calle.value == "") {
                    setTimeout(() => {
                        calle.focus();
                    }, 500);
                    this.popUpIncompleteData("Calle");
                    calle.classList.add("check-invalid"); return false;}
                if(numInterior.value == ""){
                    this.popUpIncompleteData("Número interior");
                    return false;
                }
                //if(municipio.value == "Municipio") municipio.focus();
                //if(estado.value == "Estado") estado.focus();
                return false;
            }

            /*
            console.log(detallesDeOperacion);
            console.log(capacidadPersonas.value);
            console.log(costoMinimoProyecto.value);
            console.log(costoPorVisita.value);
            console.log(gananciaPorVisita.value);
            console.log(tomoACuentaPago);
            */
            if(this.currentStep == 3 && (
                detallesDeOperacion === undefined ||
                capacidadPersonas.value == "" ||
                costoMinimoProyecto.value == "" ||
                costoPorVisita.value == "" ||
                gananciaPorVisita.value == "" ||
                tomoACuentaPago === undefined)
            ){
                setTimeout(() => {
                    window.scrollBy({top: -100, behavior: "smooth"});
                }, 80);
                //if(tomoACuentaPago === undefined) tomoACuentaPago.focus(); 
                if(detallesDeOperacion === undefined){
                    setTimeout(() => {
                        document.getElementById("p5-sl3-radios").focus();
                    }, 500);
                    document.getElementById("p5-sl3-radios").classList.add("check-false");
                    this.popUpIncompleteData("Detalles de tu operación");
                    return false;
                }else {
                    document.getElementById("p5-sl3-radios").classList.remove("check-false");
                }
                if(capacidadPersonas.value == "") {
                    setTimeout(() => {
                        capacidadPersonas.focus();
                    }, 500);
                    capacidadPersonas.classList.add("check-invalid"); return false}
                if(costoMinimoProyecto.value == "") {
                    setTimeout(() => {
                        costoMinimoProyecto.focus();
                    }, 500);
                    this.popUpIncompleteData("Costo mínimo que acepto por proyecto");
                    costoMinimoProyecto.classList.add("check-invalid"); return false}
                if(costoPorVisita.value == "") {
                    setTimeout(() => {
                        costoPorVisita.focus();    
                    }, 500);
                    this.popUpIncompleteData("Costo por visita");
                    costoPorVisita.classList.add("check-invalid"); return false}
                if(gananciaPorVisita.value == "") {
                    setTimeout(() => {
                        gananciaPorVisita.focus(); 
                    }, 500);
                    gananciaPorVisita.classList.add("check-invalid"); return false}
                if(tomoACuentaPago === undefined){
                    setTimeout(() => {
                        document.getElementById("p5-sl3-SiNo").focus();
                    }, 500);
                    document.getElementById("p5-sl3-SiNo").classList.add("check-false");
                    return false;
                }else {
                    document.getElementById("p5-sl3-SiNo").classList.remove("check-false");
                }
                return false;
            }

            if(preview_ == "publicar")
                this.publicar(CaracteristicasNombre.value, paraQueUsarLocacion, tipoDeLocacion, queSePuedeHacer, restricciones, descripcion.value, uploadImages, estado.value, municipio.value, calle.value, numInterior.value, codigoPostal.value, detallesDeOperacion, capacidadPersonas.value, costoMinimoProyecto.value, costoPorVisita.value, gananciaPorVisita.value, tomoACuentaPago);
            return true;
        },

        popUpIncompleteData(data_){
            this.incompleteDataAlert = true;
            this.incompleteInput = data_;
        },

        previewLocation(CaracteristicasNombre_, paraQueUsarLocacion_, tipoDeLocacion_, queSePuedeHacer_, restricciones_, descripcion_, uploadImages_, estado_, municipio_, calle_, numInterior_, codigoPostal_, detallesDeOperacion_, capacidadPersonas_, costoMinimoProyecto_, costoPorVisita_, gananciaPorVisita_, tomoACuentaPago_){
            localStorage.setItem("previewLocation", JSON.stringify({
                caracteristicasNombre: CaracteristicasNombre_,
                paraQueUsarLocacion_: paraQueUsarLocacion_,
                tipoDeLocacion: JSON.stringify(tipoDeLocacion_),
                queSePuedeHacer: JSON.stringify(queSePuedeHacer_),
                
                restricciones: restricciones_,
                descripcion: descripcion_,
                uploadImages: uploadImages_,
                estado: estado_,
                municipio: municipio_,
                calle: calle_,
                numInterior: numInterior_,
                codigoPostal: codigoPostal_,
                detallesDeOperacion: detallesDeOperacion_,
                capacidadPersonas: capacidadPersonas_,
                costoMinimoProyecto: costoMinimoProyecto_,
                costoPorVisita: costoPorVisita_,
                gananciaPorVisita: gananciaPorVisita_,
                tomoACuentaPago: tomoACuentaPago_,
            }));           
            let result = window.location.href.replace("#/subir-locacion", "#/detalle");
            window.open(result, '_blank');
        },

        publicar(CaracteristicasNombre_, paraQueUsarLocacion_, tipoDeLocacion_, queSePuedeHacer_, restricciones_, descripcion_, uploadImages_, estado_, municipio_, calle_, numInterior_, codigoPostal_, detallesDeOperacion_, capacidadPersonas_, costoMinimoProyecto_, costoPorVisita_, gananciaPorVisita_, tomoACuentaPago_){
            console.log("publicar");
            console.log("tipo de locacion", tipoDeLocacion_);
            //console.log(queSePuedeHacer_, restricciones_, uploadImages_, detallesDeOperacion_, capacidadPersonas_, gananciaPorVisita_)
            
            let fotos = this.selectedImagesUploaded.map((url, index) => ({
                id: index+1,
                S_URL: url,
                N_Status: 1,
                S_Observaciones: null,
                N_Principal: index === 0 ? 1 : 0,
                areas: [1],
                N_Orden: index,
                mantener: 1
            }));


            console.log("Fotos nuevas:", fotos)
            let loadedImages = JSON.parse(localStorage.getItem("loadedImages"));
            if(loadedImages != null){
                let counter = 0;
                for (const key in loadedImages) {
                    if (Object.hasOwnProperty.call(loadedImages, key)) {
                        loadedImages[key].mantener = 0;
                        loadedImages[key].N_Orden = fotos.length + counter;
                        loadedImages[key].areas = [1]
                    }
                    counter += 1;
                }
                fotos = [...fotos, ...loadedImages];
            }else{
                fotos = [...fotos];
            }

            console.log("Fotos anteriores", loadedImages)

            console.log("Todas las fotos:", fotos)
            
            console.log("Tipo de locacion", tipoDeLocacion_)
            let data = {
                "S_Tipo": paraQueUsarLocacion_/*.split("")*/,
                "S_Titulo": CaracteristicasNombre_,
                "S_Descripcion": descripcion_,
                "FK_TipoLocacion": 1,
                "N_CostoVisita": costoPorVisita_,
                "N_CostoMinimo": costoMinimoProyecto_,
                "N_TomarACuenta": tomoACuentaPago_,
                "N_Status": 1,
                "FK_Propietario": JSON.parse(localStorage.getItem('userData')).user.id,
                "FK_Aprobo": null,
                "N_AceptaPublicidad": 1,
                "direccion": {
                    "S_Calle": calle_,
                    "S_Numero": numInterior_ == undefined || numInterior_ == "" ? "0" : numInterior_,
                    "S_Colonia": "exact",
                    "S_CodigoPostal": codigoPostal_,
                    "S_Municipio": municipio_,
                    "S_Estado": estado_
                },
                "fotos": fotos,
                "amenidades": [
                    {
                        "tc_amenidades_id": 1,
                        "N_Cantidad": null
                    },
                    {
                        "tc_amenidades_id": 3,
                        "N_Cantidad": 3
                    },
                    {
                        "tc_amenidades_id": 14,
                        "N_Cantidad": null
                    }
                ],
                "restricciones": {
                    "limiteAsistentes": restricciones_.limiteAsistentes,
                    "horaLlegada": restricciones_.horaLLegada,
                    "horaSalida": restricciones_.horaSalida,
                    "fumadores": restricciones_.fumadores,
                    "mascotas": restricciones_.mascotas,
                    "fiestas": restricciones_.fiestas,
                    "menores": restricciones_.menores,
                    "catering": restricciones_.catering,
                    "montajes": restricciones_.montajes,
                    "valet_parking": restricciones_.valet_parking,
                    "poliza": restricciones_.poliza,
                    "permisos": restricciones_.permisos,
                    "adicionales": restricciones_.adicionales
                },
                "que_se_puede_hacer": JSON.stringify(queSePuedeHacer_),
                "tipo_locacion_items":  JSON.stringify(tipoDeLocacion_),
                "beneficios": JSON.stringify(this.getBeneficios()),
                "detalles_de_operacion": detallesDeOperacion_,
                "costo_por_hora": parseFloat(element("#p5-ls3-opcional-costoHora").value) || 0,
                "costo_por_dia": parseFloat(element("#p5-ls3-opcional-costoDia").value) || 0,
                "portada_rotaciones": document.getElementById("p5-cover-rotations").textContent,
                "token": localStorage.getItem("prospectToken")
            }

            if(!document.getElementById("p5-aceptar-terminos").checked){
                console.log("NO SE ACEPTARON TERMINOS Y CONDICIONES")
                return;
            }

            //EL SIGUIENTE CÓDIGO SOLO SE EJECUTARÁ SI TIENE IMAGEN DE PERFIL Y DOCUMENTOS:
            //JSON.parse(localStorage.getItem('userData'))["user"]["S_URLFotoPerfil"]
            //JSON.parse(localStorage.getItem('userData'))["user"]["S_URLIdentificacion"]
            /*let checkProfilePic = JSON.parse(localStorage.getItem('userData'))["user"]["S_URLFotoPerfil"];
            let checkIdentificacion = eval(JSON.parse(localStorage.getItem('userData'))["user"]["S_URLIdentificacion"]);

            if(checkIdentificacion == [] || checkIdentificacion == "[]" || checkIdentificacion === null || checkProfilePic.includes("https://cdn.iconscout.com/icon/free/")){
                console.log("NO SE PUEDE SUBIR LOCACION");
                console.log(checkIdentificacion);
                this.showPopUp(true, false);
                document.getElementById("slPopUpOk-title").innerHTML = "¡Recuerda!";
                const perfilUrl = window.location.href.split("#")[0]+"#/perfil";
                document.getElementById("slPopUpOk-text").innerHTML = "Tus Locaciones no serán validadas hasta que completes tu Perfil.<br><strong>Presiona en <a href=\""+perfilUrl+"\">\"Mi Cuenta\"</a> y termina el proceso.</strong>";
                document.getElementById("slPopUpOk-btn-continuar").style.display = "none";
                document.getElementById("slPopUpOk-btn-cerrar").style.display = "block";
                this.preserveUnsavedData();
                //return; //Uncomment for block location upload without identification.
                return;
            } */

            let endpoint = {"url":"https://locacionbackoffice.ideeo.mx/api/CrearLocacionFilmacion", "method":"POST"};
            if(this.mode == "edit"){
                endpoint.url = `https://locacionbackoffice.ideeo.mx/api/EditaLocacionFilmacion/${parseInt(this.urlGET("edit"))}`; 
                endpoint.method = "PUT";
                //console.log("Datos a editar - no se subiran aun", endpoint);
                //return;
            }

            const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"]; // Reemplaza con tu token de acceso
            fetch(endpoint.url, {
                    method: endpoint.method,
                    body: JSON.stringify(data),
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${accessToken}` // Incluir el token Bearer en el encabezado
                    }})
                .then(response => response.json())
                .then(json => {
                    console.log(json.estado)
                    if(json.estado){
                        //if(checkIdentificacion == [] || checkIdentificacion == "[]" || checkIdentificacion === null){
                        //    console.log("no")
                        //}else{
                            this.showPopUp(true, true);
                            document.getElementById("slPopUpOk-title").innerHTML = "¡Importante!";
                            document.getElementById("slPopUpOk-text").innerHTML = "<strong>Recuerda tener completo tu perfil para que podemos validar tu anuncio.</strong><br> Verifica que tengas tu INE y foto de perfil activa. <br>Muy pronto te estará llegando una notificación, vía correo electrónico, donde te avisaremos si tu anuncio fue aprobado o no. Recuerda revisar tu bandeja de spam.";
                            // Cambiar textos a actualizado:
                            document.getElementById("slPopUpOk-btn-continuar").style.display = "block";
                            document.getElementById("slPopUpOk-btn-cerrar").style.display = "none";
                            localStorage.removeItem('preserveUnsavedData');
                            localStorage.removeItem("prospectToken");
                        //}

                        try {
                            element("#upload-costo-minimo-proyecto").value = "";
                            element("#p5r-limiteAsistentes").value = "";
                            element("#p5r-horaLlegada").value = "";
                            element("#p5r-horaSalida").value = "";
                            element("#p5r-fumadores").checked = false;
                            element("#p5r-mascotas").checked = false;
                            element("#p5r-fiestas").checked = false;
                            element("#p5r-menores18").checked = false;
                            element("#p5r-catering").checked = false;
                            element("#p5r-montajes").checked = false;
                            element("#p5r-valetparking").checked = false;
                            element("#p5r-poliza").checked = false;
                            element("#p5r-permisos").checked = false;
                            element("#p5r-adicionales").value = "";
                            element("#p5-costoPorVisita").value = "";
                            element("#reset-images").click();
                            setTimeout(() => {
                                localStorage.removeItem('preserveUnsavedData');
                                localStorage.removeItem("editImagesArray");
                            }, 800);
                            console.log("Removed preserved")
                            

                            this.selectedImagesUploaded = [];
                            element("#reset-images").click();
                        } catch (error) {
                            console.log("Data not deleted", error)
                        }
                    }
                    document.getElementById("finalPostBtn").style.pointerEvents = "all";
                    document.getElementById("finalPostBtn").style.opacity = 1;
            })
        },

        showPopUp(mode_, reset_){
            try {
                document.getElementById("subirLocacionPopUp-ok").style.display = mode_ == true ? "flex" : "none";
            } catch (error) {
                console.log(error);
            }
            
            if(mode_ == false){
                if(reset_ == false){
                    this.goTo("perfil");
                    setTimeout(() => {
                        try {
                            document.getElementById("perfil-perfil-btn").click();
                        } catch (error) {
                            console.log("element not in desktop")
                        }
                    }, 100);
                    return;
                }
                window.location.hash = "/";
                if (localStorage.getItem('onSession') === 'true') {
                    let userData = JSON.parse(localStorage.getItem('userData'));
                    //console.log(userData)
                    setTimeout(() => {
                        document.querySelectorAll('.navProfile').forEach(element => element.style.display = "flex");
                        document.querySelectorAll('.navAccount').forEach(element => element.style.display = "none");
                        document.querySelectorAll('.nav-username').forEach(username => username.textContent = userData.user.S_Nombre);
                        document.querySelectorAll('.nav-userpic').forEach(userpic => userpic.src =userData.user.S_URLFotoPerfil);
                        console.log("cambiado")
                    }, 10);
                }
                // Limpiar los campos:
                console.log("Limpieza de campos")
                element("#upload-costo-minimo-proyecto").value = "";
                element("#p5r-limiteAsistentes").value = "";
                element("#p5r-horaLlegada").value = "";
                element("#p5r-horaSalida").value = "";
                element("#p5r-fumadores").checked = false;
                element("#p5r-mascotas").checked = false;
                element("#p5r-fiestas").checked = false;
                element("#p5r-menores18").checked = false;
                element("#p5r-catering").checked = false;
                element("#p5r-montajes").checked = false;
                element("#p5r-valetparking").checked = false;
                element("#p5r-poliza").checked = false;
                element("#p5r-permisos").checked = false;
                element("#p5r-adicionales").value = "";
                element("#p5-costoPorVisita").value = "";
                localStorage.removeItem('preserveUnsavedData');
                element("#reset-images").click();
            }
        },

        focusOn(section_, input_){
            this.switchSection(section_);
            setTimeout(() => {
                document.getElementById(input_).focus();
            }, 80);    
        },

        urlGET(param_){
            const queryString = window.location.search;
            let result = new URLSearchParams(queryString);
            return result.get(param_);
        },

        preserveUnsavedData() {
            //const locationID = parseInt(this.urlGET("edit"));
            //if(!isNaN(locationID)){
            //    console.log("Returning...")
            //    return;
            //}

            try {
                // Obtener todas las variables que quieres guardar
                let CaracteristicasNombre = document.getElementById("upload-caracteristicasNombre");
                let paraQueUsarLocacion = `${document.getElementById('upload-para-que-usar-locacion-s').checked == true ? 'S' : ''}${document.getElementById('upload-para-que-usar-locacion-f').checked == true ? 'F' : ''}${document.getElementById('upload-para-que-usar-locacion-r').checked == true ? 'R' : ''}${document.getElementById('upload-para-que-usar-locacion-e').checked == true ? 'E' : ''}`;
                let tipoDeLocacion = JSON.stringify(this.getQueRentas());
                let queSePuedeHacer = JSON.stringify(this.getQueSePuedeHacer());
                let beneficios = JSON.stringify(this.getBeneficios());
                let descripcion = document.getElementById("upload-caracteristicasDescripcion");
                console.log("DESCRIPTION", descripcion.value)
                let uploadImages = this.selectedImagesUploaded;
                let estado = document.getElementById("p5-estado");
                let municipio = document.getElementById("p5-municipio");
                let calle = document.getElementById("p5-calle");
                let numInterior = document.getElementById("p5-numInterior");
                let codigoPostal = document.getElementById("p5-codigoPostal");
                const radiosDetallesDeOperacion = document.getElementsByName("upload-detalles-de-operacion");
                let detallesDeOperacion;
                radiosDetallesDeOperacion.forEach(radio => {
                    if (radio.checked)
                        detallesDeOperacion = radio.value;
                });
                let costoMinimoProyecto = document.getElementById("upload-costo-minimo-proyecto");
                let costoPorVisita = document.getElementById("p5-costoPorVisita");
                let gananciaPorVisita = document.getElementById("p5-gananciaPorVisita");
                const radiosTomoACuentaPago = document.getElementsByName("OfrezcoEnCasoDeConcreteProyecto");
                let tomoACuentaPago;
                radiosTomoACuentaPago.forEach(radio => {
                    if (radio.checked)
                        tomoACuentaPago = radio.value;
                });

                let Fotos_ = uploadImages.map(url => ({ S_URL: url }));
                console.log("preserve 1");
                const datos = {
                    S_Titulo: CaracteristicasNombre.value, //ok
                    S_Tipo: paraQueUsarLocacion, //ok
                    FK_TipoLocacion: 1, //ok
                    que_se_puede_hacer: queSePuedeHacer, //ok
                    tipo_locacion_items: tipoDeLocacion,
                    beneficios: beneficios,
                    S_Descripcion: descripcion.value, //ok
                    Fotos: Fotos_, //+o-
                    direccion:{ //ok
                        S_Estado: estado.value,
                        S_Municipio: municipio.value,
                        S_Calle: calle.value,
                        S_Numero: numInterior.value,
                        S_CodigoPostal: codigoPostal.value,
                    },
                    detalles_de_operacion: detallesDeOperacion, //ok
                    N_CostoMinimo: costoMinimoProyecto.value, //ok
                    N_CostoVisita: costoPorVisita.value, //ok
                    gananciaPorVisita: gananciaPorVisita.value, //ok
                    N_TomarACuenta: tomoACuentaPago, //ok
                    limite_asistentes: document.getElementById("p5r-limiteAsistentes").value,
                    hora_llegada: document.getElementById("p5r-horaLlegada").value,
                    hora_salida: document.getElementById("p5r-horaSalida").value,
                    fumadores: document.getElementById("p5r-fumadores").checked,
                    mascotas: document.getElementById("p5r-mascotas").checked,
                    fiestas: document.getElementById("p5r-fiestas").checked,
                    menores: document.getElementById("p5r-menores18").checked,
                    catering: document.getElementById("p5r-catering").checked,
                    montajes: document.getElementById("p5r-montajes").checked,
                    valet_parking: document.getElementById("p5r-valetparking").checked,
                    poliza: document.getElementById("p5r-poliza").checked,
                    permisos: document.getElementById("p5r-permisos").checked,
                    adicionales: document.getElementById("p5r-adicionales").value,
                    costo_por_hora: parseFloat(element("#p5-ls3-opcional-costoHora").value) || 0,
                    costo_por_dia: parseFloat(element("#p5-ls3-opcional-costoDia").value) || 0,
                    portada_rotaciones: document.getElementById("p5-cover-rotations").textContent
                };

                localStorage.setItem('preserveUnsavedData', JSON.stringify(datos));
                console.log("Preserving:", JSON.parse(localStorage.getItem('preserveUnsavedData')) );
            
                if(!localStorage.getItem("prospectToken")){
                    this.getTokenProspect();
                }

            } catch (error) {
                localStorage.removeItem('preserveUnsavedData');    
            }
            //test:
        },

        setDataByURL(edit_=false){
            const data = this.locationByURL;

            console.log("Datos para colocar por url:", data)
            
            element("#upload-caracteristicasNombre").value = data.S_Titulo;
            if(data.S_Tipo.includes("S"))
                element("#upload-para-que-usar-locacion-s").checked = true;
            if(data.S_Tipo.includes("F"))
                element("#upload-para-que-usar-locacion-f").checked = true;
            if(data.S_Tipo.includes("R"))
                element("#upload-para-que-usar-locacion-r").checked = true;
            if(data.S_Tipo.includes("E"))
                element("#upload-para-que-usar-locacion-e").checked = true;

            console.log(edit_)

            if(data.tipo_locacion_items){
                let queSePuedeHacer = JSON.parse(data.tipo_locacion_items);
                if(edit_){ queSePuedeHacer = JSON.parse(queSePuedeHacer); }
                queSePuedeHacer = [...queSePuedeHacer];
                for(let i of queSePuedeHacer){
                    for(let checkbox of document.getElementsByName("p5-queRentas-inputs"))
                        
                        if(checkbox.value == i){
                            checkbox.checked = true;
                            break;
                        }
                }
            }

            if(data.que_se_puede_hacer){
                try {
                    let queRentas = JSON.parse(data.que_se_puede_hacer);
                    if(edit_){ queRentas = JSON.parse(queRentas); }
                    queRentas = [...queRentas];
                    for(let i of queRentas){
                        console.log("111111111", i)
                        for(let checkbox of document.getElementsByName("p5-queSePuedeHacer-inputs"))
                            if(checkbox.value == i){
                                checkbox.checked = true;
                                break;
                            }
                    }
                } catch (error) {
                    console.error(error);       
                }
            }
                
            if(data.beneficios){
                let beneficios = JSON.parse(data.beneficios);
                if(edit_){ beneficios = JSON.parse(beneficios); }
                beneficios = [...beneficios];
                for(let i of beneficios)
                    for(let checkbox of document.getElementsByName("p5-beneficios-inputs"))
                        if(checkbox.value == i){
                            checkbox.checked = true;
                            break;
                        }
            }
            

            element("#p5r-limiteAsistentes").value = data.limite_asistentes;
            element("#p5r-horaLlegada").value = data.hora_llegada;
            element("#p5r-horaSalida").value = data.hora_salida;
            element("#p5r-fumadores").checked = data.fumadores == 1 ? true : false;
            element("#p5r-mascotas").checked = data.mascotas == 1 ? true : false;
            element("#p5r-fiestas").checked = data.fiestas == 1 ? true : false;
            element("#p5r-menores18").checked = data.menores == 1 ? true : false;
            element("#p5r-catering").checked = data.catering == 1 ? true : false;
            element("#p5r-montajes").checked = data.montajes == 1 ? true : false;
            element("#p5r-valetparking").checked = data.valet_parking == 1 ? true : false;
            element("#p5r-poliza").checked = data.poliza == 1 ? true : false;
            element("#p5r-permisos").checked = data.permisos == 1 ? true : false;
            element("#p5r-adicionales").value = data.adicionales;
            //console.log("Fotos: ", JSON.stringify(data.Fotos));
            console.log("FOTOS", data.Fotos)
            for(let i of data.Fotos){
                this.selectedImagesUploaded.push(i.S_URL);
            }
            localStorage.setItem("editImagesArray", JSON.stringify(this.selectedImagesUploaded));
                
            element("#upload-caracteristicasDescripcion").value = data.S_Descripcion;

            element("#p5-estado").value = data.direccion.S_Estado;
            element("#p5-municipio").value = data.direccion.S_Municipio;
            element("#p5-calle").value = data.direccion.S_Calle;
            element("#p5-numInterior").value = data.direccion.S_Numero;
            element("#p5-codigoPostal").value = data.direccion.S_CodigoPostal;

            // Detalles de operacion
            const radios = document.getElementsByName("upload-detalles-de-operacion");
            radios.forEach((radio) => {
                console.log(radio.value, data.detallesDeOperacion)
                if (radio.value === data.detalles_de_operacion) {
                    
                    radio.checked = true;
                }
            });

            element("#p5-ls3-opcional-costoHora").value = isNaN(data.costo_por_hora)? '' : data.costo_por_hora;
            element("#p5-ls3-opcional-costoDia").value = isNaN(data.costo_por_dia)? '' : data.costo_por_dia;

            element("#upload-costo-minimo-proyecto").value = data.N_CostoMinimo;
            element("#p5-costoPorVisita").value = data.N_CostoVisita;
            if (data.N_TomarACuenta === 1) {
                document.querySelector('input[value="1"]').checked = true;
            } else {
                document.querySelector('input[value="0"]').checked = true;
            }

            setTimeout(() => {
                element("#upload-caracteristicasDescripcion").value = data.S_Descripcion;
                setTimeout(() => {
                    element("#upload-caracteristicasDescripcion").value = data.S_Descripcion;
                }, 500);
            }, 1000);
        },

        goTo(section_){
            window.location.hash = "/"+section_;
            if (localStorage.getItem('onSession') === 'true') {
                let userData = JSON.parse(localStorage.getItem('userData'));
                //console.log(userData)
                setTimeout(() => {
                document.querySelectorAll('.navProfile').forEach(element => element.style.display = "flex");
                document.querySelectorAll('.navAccount').forEach(element => element.style.display = "none");
                document.querySelectorAll('.nav-username').forEach(username => username.textContent = userData.user.S_Nombre);
                document.querySelectorAll('.nav-userpic').forEach(userpic => userpic.src =userData.user.S_URLFotoPerfil);
                console.log("cambiado")
                }, 10);
            }
            setTimeout(() => { window.scrollTo(0, 0); }, 10);
        },

        getTokenProspect(){
            const accessCodeNum = localStorage.getItem("accessCodeNum");
            const userId = JSON.parse(localStorage.getItem("userData")).user.id;
            console.log("CREACION DE NUEVA LOCACION", accessCodeNum, userId);

            fetch(`https://locacionbackoffice.ideeo.mx/api/token?codigo=${accessCodeNum}&usuario_id=${userId}`, {method: 'post',
                body: JSON.stringify({
                    "codigo": accessCodeNum,
                    "tipo_sesion": "T",
                    "usuario": userId
                }),
                headers: {
                    "Content-type": "application/json"
                }})
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    if(json.error == 0){
                        localStorage.setItem("prospectToken", json.token);
                    }
                })
        },

        infoProfile(){
            let checkProfilePic = JSON.parse(localStorage.getItem('userData'))["user"]["S_URLFotoPerfil"];
            let checkIdentificacion = eval(JSON.parse(localStorage.getItem('userData'))["user"]["S_URLIdentificacion"]);
            if(checkIdentificacion == [] || checkIdentificacion == "[]" || checkIdentificacion === null || checkProfilePic.includes("https://cdn.iconscout.com/icon/free/")){
                const perfilUrl = window.location.href.split("#")[0]+"#/perfil";
                return "Recuerda que tus Locaciones no serán validadas hasta que completes tu Perfil.<br><strong>Presiona en <a href=\""+perfilUrl+"\">\"Mi Cuenta\"</a> y termina el proceso.</strong>";
            }else{
                return "";
            }
        },

        switchVideoTutorial(mode_){
            this.videoTutorialActive = mode_;
        },
        
        restoreButton(){
            document.getElementById("finalPostBtn").style.pointerEvents = "all";
            document.getElementById("finalPostBtn").style.opacity = 1;
        }

    },

    mounted(){
        document.getElementById(`pantalla5-step1`).style.display = "flex";
        document.getElementById(`pantalla5-aside-menu1`).style.fontWeight = "bolder";
        document.getElementById(`pantalla5-aside-menu1`).style.borderBottom = "2px solid #FF282A";
        document.getElementById(`pantalla5-aside-menu1`).classList.remove("general-btn-inactive");
        document.getElementById(`pantalla5-aside-menu2`).classList.add("general-btn-inactive");
        document.getElementById(`pantalla5-aside-menu3`).classList.add("general-btn-inactive");
        document.getElementById(`pantalla5-submenu1`).style.maxHeight = "500px";

        const locationID = parseInt(this.urlGET("edit"))
       
        if(isNaN(locationID)){ // NUEVA LOCACIÓN
            this.mode = "new";
            this.selectedImagesUploaded = [];
            let preservedData = JSON.parse(localStorage.getItem('preserveUnsavedData'));
            if(preservedData != null){
                console.log("Recuperar datos");
                this.locationByURL = preservedData;
                setTimeout(() => {
                    this.setDataByURL();

                    let counter = 0;
                    let previewImages = document.getElementById("upload-preview-images");
                    previewImages.innerHTML = "";
                    for (let src_ of this.selectedImagesUploaded) {
                        // Puedes hacer cualquier cosa con src_, por ejemplo, agregar las imágenes a un elemento en la página
                        let imgElement = document.createElement("img");
                        imgElement.src = src_;
                        previewImages.appendChild(imgElement);
                    }
                    setTimeout(() => {
                        this.presentationInterval = setInterval(() => {
                            previewImages.scrollLeft += previewImages.offsetWidth;
                            counter++;
                            if(counter == this.selectedImagesUploaded.length){
                                counter = 0;
                                previewImages.scrollLeft = 0;
                            }
                        }, 3000);
                    }, 100);
                }, 1500);
                
            }
        }else{ // EDITAR LOCACIÓN
            this.mode = "edit";
            const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://locacionbackoffice.ideeo.mx/api/locaciones', {method: 'GET',
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }})
                .then(response => response.json())
                .then(json => {
                    json = json.dato;

                    for(let location in json){
                        if(json[location].id == locationID){
                            this.locationByURL = json[location];
                            break;
                        }
                    }
                    this.setDataByURL(true);

                    localStorage.setItem("loadedImages", JSON.stringify(this.locationByURL.Fotos));

                    // Lo siguiente solamente es para el silder:
                    let counter = 0;
                    let previewImages = document.getElementById("upload-preview-images");
                    previewImages.innerHTML = "";
                    for (let src_ of this.selectedImagesUploaded) {
                        // Puedes hacer cualquier cosa con src_, por ejemplo, agregar las imágenes a un elemento en la página
                        let imgElement = document.createElement("img");
                        imgElement.src = src_;
                        previewImages.appendChild(imgElement);
                    }
                    setTimeout(() => {
                        this.presentationInterval = setInterval(() => {
                            previewImages.scrollLeft += previewImages.offsetWidth;
                            counter++;
                            if(counter == this.selectedImagesUploaded.length){
                                counter = 0;
                                previewImages.scrollLeft = 0;
                            }
                        }, 3000);
                    }, 100);

                })
        }


    }
}
</script>

<style>
.SubirLocacionWithSession-MAIN{
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.SubirLocacionWithSession-MAIN section{
    display: flex;
    justify-content: space-evenly;
    border-radius: 2ch;
    background-color: white;
    width: 85%;
    margin-bottom: 5ch;
}

.ElNuevoMundoDeLocaciones{
    margin-top: 2ch;
    font-size: 2.5ch;
    font-weight: bolder;
}

#p5-progress-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3ch;
}

#p5-progress-bar > div{
    background-color: white;
    height: 1ch;
    width: 10vw;
    border-radius: 100vw;
    margin:  0 2ch;
    display: flex;
    align-items: center;
}

#p5-progress{
    border-radius: 100vw;
    background-color: #FF282A;
    width: 33%;
    height: 100%;
    transition: all 0.2s;
}

/* left side bar */
.SubirLocacionWithSession-MAIN section > aside{
    width: calc(25% - 5ch);
}
.SubirLocacionWithSession-MAIN section > div{
    width: calc(70% - 5ch);
}

.SubirLocacionWithSession-MAIN section > aside > p{
    border-bottom: 2px solid transparent;
}

.SubirLocacionWithSession-MAIN section > aside > p:hover{
    cursor: pointer;
    border-bottom: 2px solid lightgray !important;
}

.SubirLocacionWithSession-MAIN section > aside > div p:hover{
    color: black;
    cursor: pointer;
    text-shadow: 0px 0px 1px black;
}

.SubirLocacionWithSession-MAIN section > aside > p{
    font-size: 2ch;
    width: fit-content;
    padding-bottom: 0.5ch;
    margin-top: 2ch;
    margin-bottom: 0ch;
}

.SubirLocacionWithSession-MAIN section > aside > div > p{
    font-size: 1.2ch;
    color: rgb(98, 98, 98);
}

#pantalla5-step2, #pantalla5-step3{
    display: none;
}

#pantalla5-submenu1, #pantalla5-submenu2, #pantalla5-submenu3{
    max-height: 0px;
    transition: all 0.2s;
    overflow: hidden;
}

.banner{
    width: 15vw;
}

/* Component styles */
.SubirLocacion1caracteristicas-MAIN > p,
.SubirLocacion2locacion-MAIN > p,
.SubirLocacion3operativo-MAIN > p{
    display: flex;
    align-items: center;
    font-size: 1.7ch;
    margin-top: 2.5ch;
}

.SubirLocacion1caracteristicas-MAIN > p img,
.SubirLocacion2locacion-MAIN > p img,
.SubirLocacion3operativo-MAIN > p img{
    margin-right: 1ch;
    max-height: 2ch;
    filter: grayscale(1);
}

.build-green{
    filter: none !important;
}

.SubirLocacion1caracteristicas-MAIN select,
.SubirLocacion2locacion-MAIN select,
.SubirLocacion3operativo-MAIN select{
    width: 100%;
    padding: 1ch;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: white;
}

.SubirLocacion1caracteristicas-MAIN input:not(input[type=file]), .SubirLocacion1caracteristicas-MAIN textarea,
.SubirLocacion2locacion-MAIN input:not(input[type=file]), .SubirLocacion2locacion-MAIN textarea,
.SubirLocacion3operativo-MAIN input:not(input[type=file]), .SubirLocacion3operativo-MAIN textarea{
    padding: 1ch;
    border: 1px solid gray;
    border-radius: 5px;
}

.p5-min-txt{
    font-size: 1.2ch !important;
    text-align: justify;
}

.SubirLocacion1caracteristicas-MAIN button,
.SubirLocacion2locacion-MAIN button,
.SubirLocacion3operativo-MAIN button,
#subirLocacionPopUp-ok button,
.incompleteDataPopUp button,
.general-btn{
    background: linear-gradient(#FF4C0B, #FF0A46);
    color: white;
    padding: 2ch 4ch;
    font-size: 12px;
    border: none;
    border-radius: 100vw;
    text-transform: uppercase;
    min-width: 210px;
    font-weight: bold;
    margin-bottom: 2ch;
    width: fit-content;
    align-self: center;
    margin-top: 3ch;
}

.general-btn{
    padding: 2ch 0 !important;
    margin-bottom: 0;
    width: 100% !important;
    max-width: 100% !important;
    min-width: unset !important;
}

.general-btn-inactive{
    background: unset;
    background-color: white !important;
    color: gray !important;
    border: 2px solid #FF4C0B !important;
}

button:hover{
    cursor: pointer;
}

.p5-aside-cel{
    display: none;
}

.upload-preview{
    margin-top: 2vw;
    background-color: #F4F4F4;
    display: flex;
    position: relative;
    width: 15vw;
    height: 15vw;
    border-radius: 1vw;
    overflow: hidden;
    position: sticky !important;
    z-index: 1;
    margin-bottom: 2ch;
}

.upload-preview:hover{
    cursor: pointer;
}

.upload-preview-type-div{
    position: absolute;
    top: 1vw;
    right: 1vw;
    width: 2ch;
    height: 2ch;
    padding: 0.7ch;
    background-color: white;
    border-radius: 100vw;
}

.upload-preview-type-div > img{
    max-width: 2ch;
    max-height: 2ch;
}

#upload-preview-images{
    width: inherit;
    height: inherit;
    overflow: hidden;
    display: flex;
    align-items: center;
    scroll-behavior: smooth;
}

#upload-preview-images > img{
    width: 100%;
    min-width: 15vw;
    height: 100%;
    object-fit: cover; /* Ajusta la imagen para cubrir el contenedor */
    border: 1px solid white;
    margin-left: -1px;
}

#subirLocacionPopUp-ok,
.incompleteDataPopUp{
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);
    z-index: 3;
    display: none;
}

.incompleteDataPopUp{
    display: flex;
}

.subirLocacion-close-button{
    position: absolute;
    top: 0.3ch;
    right: 0.3ch;
    color: gray;
    transform: rotate(45deg);
    font-size: 3.5ch;
    width: 1.5ch;
    height: 1.5ch;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    text-align: center;
    cursor: pointer;
}

#subirLocacionPopUp-ok > div,
.incompleteDataPopUp > div{
    position: relative;
    background-color: white;
    width: 40vw;
    height: fit-content;
    min-height: fit-content;
    display: inherit;
    flex-direction: inherit;
    align-items: inherit;
    justify-content: inherit;
    border-radius: 1ch;
    padding: 4ch;
    text-align: center;
}

#subirLocacionPopUp-ok p, .incompleteDataPopUp p{
    color: rgb(65, 65, 65);
}

.SubirLocacion-back-cel{
    display: none;
}

.p5-video-tutorial{
    max-width: 90%;
    text-align: center;
    margin-top: -1ch;
    margin-bottom: 1ch;
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.p5-video-tutorial > div{
    border-radius: 100vw;
    background: linear-gradient(#FF4C0B, #FF0A46);
    padding: 0.5ch 1ch;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p5-video-tutorial > div > p{
    cursor: pointer;
    margin: 0;
    color: white;
}
/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){
    
    .ElNuevoMundoDeLocaciones{
        text-align: center;
        max-width: 95%;
        font-size: 7vw;
    }

    #p5-progress-bar > div{
        width: 50vw;
    }

    .SubirLocacionWithSession-MAIN section{
        width: 90%;
    }

    .SubirLocacionWithSession-MAIN section > aside{
        display: none;
    }

    .SubirLocacionWithSession-MAIN section > div{
        width: 90%;
    }

    /* Component styles */
    .SubirLocacion1caracteristicas-MAIN > p,
    .SubirLocacion2locacion-MAIN > p,
    .SubirLocacion3operativo-MAIN > p{
        font-weight: bolder;
        font-size: 4vw;
    }

    .SubirLocacion1caracteristicas-MAIN > p img,
    .SubirLocacion2locacion-MAIN > p img,
    .SubirLocacion3operativo-MAIN > p img{
        margin-right: 1ch;
        max-height: 1.75ch;
        filter: grayscale(1);
    }

    .build-green{
        filter: none !important;
    }

    .SubirLocacion1caracteristicas-MAIN textarea,
    .SubirLocacion2locacion-MAIN textarea,
    .SubirLocacion3operativo-MAIN textarea{
        font-size: 4vw;
    }

    .p5-min-txt{
        font-size: 3vw !important;
        font-weight: 500 !important;
    }

    .SubirLocacion1caracteristicas-MAIN button,
    .SubirLocacion2locacion-MAIN button,
    .SubirLocacion3operativo-MAIN button{
        font-size: 3.3vw;
    }

    .p5-aside-cel{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border-radius: 4vw;
        width: 85vw;
        padding: 2ch 0;
    }

    .p5-aside-cel > p{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .p5-aside-cel > p img{
        height: 1.5ch;
        transform: rotate(180deg);
    }

    .p5-aside-cel > hr{
        width: 90%;
        margin: 0;
        border: 1px solid transparent;
        border-top: 1px solid lightgray;
    }

    .upload-preview{
        display: none;
    }

    #pantalla5-components{
        display: none;
    }

    #subirLocacionPopUp-ok > div,
    .incompleteDataPopUp > div{
        width: 75vw;
        padding: 5vw;
    }

    .SubirLocacion-back-cel{
        display: flex !important;
        width: 85%;
        margin-top: 1.5ch;
        margin-bottom: -1ch;
    }

    .SubirLocacion-back-cel > img{
        transform: rotate(180deg);
    }

    .p5-video-tutorial{
        justify-content: center;
    }
}
</style>