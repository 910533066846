<template>
    <div class="NotFound-MAIN">
        <NavLocacion/>
        <div>
            <p><span v-html="strNotFound"></span> Not found</p>
        </div>
    </div>
</template>

<script>
import NavLocacion from './NavLocacion.vue'
export default {
  components: { NavLocacion },
    name: 'NotFound',
    data(){
        return{
            strNotFound: ""
        }
    },

    mounted(){
        this.strNotFound = window.location.href.split("#")[1]
    }
}
</script>

<style scoped>
.NotFound-MAIN > div{
    width: 100%;
    height: calc(100vh - 16vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.NotFound-MAIN > div span{
    color: gray;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){
    
    .NotFound-MAIN > div{
        height: calc(100vh - 24vh);
    }

}
</style>