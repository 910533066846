<template>
    <main class="VideoTutorial-MAIN">

        <div class="close-btn-video" @click="closeVideoTutorial()">+</div>

        <video width="320" height="240" controls>
            <source src="../assets/videos/tutorial.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>

    </main>
</template>

<script>
export default {
    name: 'VideoTutorial',
    methods:{
        closeVideoTutorial(){
            this.$parent.switchVideoTutorial(false);
        }
    }
}
</script>

<style scoped>
.VideoTutorial-MAIN{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.close-btn-video{
    width: 1ch;
    height: 1ch;
    aspect-ratio: 1/1 !important;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    rotate: 45deg;
    font-size: 4ch;
    top: 1ch;
    right: 1ch;
    position: absolute;
    cursor: pointer;
    text-shadow: 0 0 1ch black;
    z-index: 10;
}

video{
    height: 90%;
    border-radius: 1ch !important;
    height: fit-content;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    video{
        height: unset;
        width: 100%;
    }

}
</style>