<template>
  <LocacionApp/>
</template>

<script>
import LocacionApp from './components/LocacionApp.vue'

export default {
  name: 'App',
  components: {
    LocacionApp
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&family=Zilla+Slab&display=swap');

  *{
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
    /*font-family: 'Zilla Slab', serif;*/
  }
</style>
