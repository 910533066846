<template> <!-- 4 Pantalla Detalle de Locación. -->
    <div class="DetalleLocacion-MAIN">

        <NavLocacion v-if="showBar"/>

        <section>

            <div class="detalleCover">
                <div class="detalleCover-info">
                    
                    <div><img v-if="locationData.tipo_locacion_items" :src="viewImg(locationData.tipo_locacion_items)" id="detalle-type"/></div>
                    <h1 id="detalle-title">{{ locationData.S_Titulo }}</h1>
                    <h2 id="detalle-clave">{{ locationData.S_SKU }} <button @click="copyToClipboard(locationData.S_SKU)" id="copy-btn">🗐</button></h2>
                    <p id="detalle-direccion">{{ locationData.direccion?.["S_Municipio"] + ", " + locationData.direccion?.["S_Estado"] }}</p>
                </div>

                <img src="../assets/images/icons/arrow.svg" class="arrow-left"/>
                <div class="detalle-images-container">
                    <slider id="slider-detalle" interval="4000">
                        <div v-for="(foto, index) in locationData.Fotos" :key="index">
                        <img :src="foto.S_URL" alt="Imagen" class="detalle-img" style="width: 100%; min-width: 100%; height: unset; object-fit: cover;" />
                        </div>
                    </slider>
                </div>
                <img src="../assets/images/icons/arrow.svg" class="arrow-right"/>
            </div>

            <div class="detalle-contents">
                <p class="cita-costo"><img src="../assets/images/icons/usd-circle.svg"/>Esta cita tiene un costo de&nbsp;<span id="cita-costo-span">${{ locationData.N_CostoVisita }}</span></p>
            
                <p v-if="locationData.N_TomarACuenta == '1' || locationData.N_TomarACuenta == 1">Tomo a cuenta el monto de costo de visita publicado en mi anuncio.</p>

                <p class="titulo-locacion">{{ locationData.S_Titulo }}</p>
            
                <p class="descripcion-locacion" id="descripcion-locacion">
                    {{ locationData.S_Descripcion }}
                </p>

                <div class="cita-costo"><img src="../assets/images/icons/file-invoice-dollar.svg"/><p>Rento mi locación para proyectos a partir de un monto mínimo de:&nbsp;<span id="cita-costo-span">${{ locationData.N_CostoMinimo }}</span></p></div>
            
                <!-- Listas desplegables -->

                <p class="detalle-submenu" @click="deployInfo('que-ofrece')"><img src="../assets/images/icons/bullseye-arrow.svg">¿Qué ofrece esta locación?<img src="../assets/images/icons/arrow-small.svg" id="detalle-arrow-que-ofrece"></p>
                <div id="detalle-que-ofrece" class="detalle-desplegable" v-if="locationData.tipo_locacion_items">
                    <div v-for="(item, key) in (isSubirLocacion ? JSON.parse(locationData.tipo_locacion_items) : JSON.parse(JSON.parse(locationData.tipo_locacion_items)))" :key="key">

                        <!--{{ key }}:-->{{ item }}
                    </div>
                </div>

                <p class="detalle-submenu" @click="deployInfo('que-hacer')"><img src="../assets/images/icons/flag-alt.svg">Qué mas puedes hacer en mi locación<img src="../assets/images/icons/arrow-small.svg" id="detalle-arrow-que-hacer"></p>
                <div id="detalle-que-hacer" class="detalle-desplegable" v-if="locationData.que_se_puede_hacer">
                    <div v-for="(item, key) in (isSubirLocacion ? JSON.parse(locationData.que_se_puede_hacer) : JSON.parse(JSON.parse(locationData.que_se_puede_hacer)))" :key="key">
                        <!--{{ key }}:--><div v-if="!locationData?.que_se_puede_hacer?.includes('Ninguna')">{{ item }}</div>
                    </div>
                </div>

                <p class="detalle-submenu" @click="deployInfo('reglas-restricciones')"><img src="../assets/images/icons/planning.svg">Reglas y restricciones<img src="../assets/images/icons/arrow-small.svg" id="detalle-arrow-reglas-restricciones"></p>
                <div id="detalle-reglas-restricciones" class="detalle-desplegable">
                    <p>Límite de asistentes: {{ locationData.limite_asistentes }}</p>
                    <p>Hora de llegada: {{ locationData.hora_llegada }}</p>
                    <p>Hora de salida: {{ locationData.hora_salida }}</p>
                    <p v-if="locationData.fumadores == 1">Fumadores: {{ locationData.fumadores == 1 ? "No": "Sí" }}</p>
                    <p v-if="locationData.mascotas == 1">Mascotas: {{ locationData.mascotas == 1 ? "No": "Sí" }}</p>
                    <p v-if="locationData.fiestas == 1">Fiestas: {{ locationData.fiestas == 1 ? "No": "Sí" }}</p>
                    <p v-if="locationData.menores == 1">Menores de 18: {{ locationData.menores == 1 ? "No": "Sí" }}</p>
                    <p v-if="locationData.catering == 1">Catering: {{ locationData.catering == 1 ? "No": "Sí" }}</p>
                    <p v-if="locationData.comerciales == 1">Montajes nocturnos: {{ locationData.comerciales == 1 ? "No": "Sí" }}</p>
                    <p>Requiero póliza de seguro: {{ locationData.poliza == 1 ? "Si": "No" }}</p>
                    <p>Requiero permisos especiales: {{ locationData.permisos == 1 ? "Si": "No" }}</p>
                    <p>Reglas adicionales: <span id="detalle-adicionales"></span></p>
                    {{ locationData.adicionales }}
                </div>
            </div>

        </section>

        <div class="detalle-float-btn"><img src=""/></div>

        <FooterLocacion v-if="showBar"/>

    </div>
</template>

<script>
import { htjs } from 'alexa-reactor-core'
import FooterLocacion from './FooterLocacion.vue'
import NavLocacion from "./NavLocacion.vue"

export default{
    components: { NavLocacion, FooterLocacion },
    name: 'DetalleLocacion',
    props:{
        showBar: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return{
            locationByURL: undefined,
            currentArrow: "",
            locationData: {}
        }
    },

    methods:{
        urlGET(param_){
            const queryString = window.location.search;
            let result = new URLSearchParams(queryString);
            return result.get(param_);
        },

        setDataByURL(){
            console.log(this.locationData);
            setTimeout(() => {
                htjs();
            }, 80);
        },

        deployInfo(section_){
            document.getElementById('detalle-que-ofrece').style.height = "0px";
            document.getElementById('detalle-que-hacer').style.height = "0px";
            document.getElementById('detalle-reglas-restricciones').style.height = "0px";
            document.getElementById('detalle-arrow-que-ofrece').style.transform = "rotate(0deg)";
            document.getElementById('detalle-arrow-que-hacer').style.transform = "rotate(0deg)";
            document.getElementById('detalle-arrow-reglas-restricciones').style.transform = "rotate(0deg)";
            if(section_ == this.currentArrow){
                this.currentArrow = "";
                return;
            }
            document.getElementById(`detalle-${section_}`).style.height = "fit-content";
            document.getElementById(`detalle-arrow-${section_}`).style.transform = "rotate(180deg)";
            this.currentArrow = section_;
        },

        viewImg(locationType_){
            // image replacer-
            console.log("Imagen en preview")

            locationType_ = JSON.parse(locationType_);
            console.log(locationType_)

            let result;
            try {
                result = locationType_[0].replaceAll(" ", "").toLowerCase();
            } catch (error) {
                result = "casas";
            }
            if(result == "jardin") result = "jardines";
            if(result == "terraza") result = "terrazas";
            if(result == "casa") result = "casas";
            if(result == "bodega") result = "bodegas";
            if(result == "hospital") result = "hospitales";
            if(result == "hacienda") result = "haciendas";
            if(result == "helipuerto") result = "helipuertos";
            if(result == "local") result = "locales";
            if(result == "alberca") result = "albercas";
            if(result == "oficina") result = "oficinas";
            if(result == "cabaña" || result == "cabañas") result = "house-tree";

            if(result == "") result = "casas";

            console.log(result);
            try {
                return require(`../assets/images/icons/${result}.svg`);
            } catch (error) {
                console.log("No existe img");
                return require(`../assets/images/icons/casas.svg`);
            }
        },

        copyToClipboard(textToCopy) {
            // Crea un elemento de textarea temporal para copiar el texto al portapapeles
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = textToCopy;
            
            // Agrega el elemento textarea temporal al DOM
            document.body.appendChild(tempTextArea);
            
            // Selecciona y copia el texto al portapapeles
            tempTextArea.select();
            document.execCommand('copy');
            
            // Elimina el elemento textarea temporal del DOM
            document.body.removeChild(tempTextArea);
            
            // Puedes agregar un mensaje o una notificación para indicar que el texto se copió al portapapeles
            document.getElementById("copy-btn").innerHTML = "✓";
            setTimeout(() => {
                document.getElementById("copy-btn").innerHTML = "🗐";
            }, 3000);
        },

    },

    computed: {
        isSubirLocacion() {
            return window.location.href.includes('/#/subir-locacion');
        }
    },


    mounted(){
        const locationID = parseInt(this.urlGET("id"));

        if(isNaN(locationID)){
            this.locationData = JSON.parse(localStorage.getItem('preserveUnsavedData'));
            this.setDataByURL();
            console.log(">>>>>>>>>>>>> preview")
        }else{
            const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://locacionbackoffice.ideeo.mx/api/locaciones', {method: 'GET',
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }})
                .then(response => response.json())
                .then(json => {
                    json = json.dato;

                    for(let location in json){
                        if(json[location].id == locationID){
                            this.locationData = json[location];
                            break;
                        }
                    }
                    this.setDataByURL();
                })
        }


    }
}
</script>

<style scoped>
.DetalleLocacion-MAIN{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #F4F4F4;
}

.DetalleLocacion-MAIN > section{
    background-color: white;
    width: 90%;
    display: flex;
    flex-direction: column;
    padding-bottom: 4ch;
    margin-bottom: 5ch;
    border-radius: 0 0 1ch 1ch;
}

.detalleCover{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 50vh;
    background-color: rgb(41, 41, 41);
}

.detalleCover-info{
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    position: relative;
    text-shadow: 0ch 0ch 0.5ch rgba(0, 0, 0, 0.3);
}

.detalleCover-info > div{
    background-color: white;
    border-radius: 100vw;
    width: 3.6ch;
    height: 3.6ch;
    position: absolute;
    left: -3.4ch;
    top: 3ch;
    display: flex;
    align-items: center;
    justify-content: center;
}

.detalleCover-info > div img{
    max-width: 60%;
    max-height: 60%;
}

#detalle-type{
    width: 100%;
    height: 100%;
}

.detalleCover-info h1{
    margin: 0;
    margin-top: 2.2ch;
    padding: 0;
    margin-left: 2vw;
}

.detalleCover-info h2{
    position: absolute;
    top: 4ch;
    font-size: 2ch;
    font-weight: 400;
    margin-left: 2vw;
}

.detalleCover-info p{
    text-align: right;
}

.detalleCover .arrow-left, .detalleCover .arrow-right, #detalle-cover-imgs{
    position: absolute;
}

.detalleCover .arrow-left, .detalleCover .arrow-right{
    border-radius: 100vw;
    background-color: white;
    width: 1.8ch;
    height: 1.8ch;
    padding: 0.5ch;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    display: none;
}

.detalleCover .arrow-left{
    left: 4vw;
    border-radius: 100vw;
}

.detalleCover .arrow-right{
    right: 4vw;
    transform: rotate(180deg);
    border-radius: 100vw;
}

.detalleCover > *:not(.detalle-images-container){
    z-index: 2;
}

.detalle-images-container{
    width: inherit;
    height: inherit;
    display: flex;
    align-items: flex-start;
    position: absolute;
    overflow: hidden;
    z-index: 0;
}

#detalle-cover-imgs{
    width: 90vw;
    height: unset !important;
    display: flex;
    overflow: auto;
    scroll-behavior: smooth;
    opacity: 1;
}

#slider-detalle{
    width: 100%;
    height: 100%;
}

.detalle-contents{
    width: 90%;
    align-self: center !important;
    justify-self: center !important;
}

.detalle-contents > *{
    max-width: 80%;
}

.cita-costo, .detalle-submenu{
    display: flex;
    align-items: center;
    margin-top: 4ch;
}

.cita-costo span{
    font-weight: bolder;
}

.cita-costo > img, .detalle-submenu > img:nth-child(1){
    width: 2.2ch;
    margin-right: 1ch;
    margin-left: -1ch;
}

.titulo-locacion{
    font-size: 2ch;
    font-weight: bolder;
    margin-top: 4ch;
}

.descripcion-locacion{
    color: #4E4E4E;
    font-size: 1.4ch;
    margin-bottom: 6ch;
}

.detalle-submenu{
    font-weight: bolder;
}

.detalle-submenu:hover{
    cursor: pointer;
}

.detalle-submenu > img:nth-child(2){
    margin-left: 2ch;
    width: 1.4ch;
    transition: transform 0.2s;
}

.detalle-float-btn{
    display: none;
}

.detalle-desplegable{
    overflow: hidden;
    height: 0px;
}

#copy-btn{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5ch !important;
    padding-bottom: 0.5ch !important;
    width: fit-content !important;
    min-width: 2.5ch !important;
    transform: translateY(-0.25ch);
    border: none;
    border-radius: 0.5ch;
    background: unset !important;
    background-color: white !important;
    font-weight: bolder;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    .DetalleLocacion-MAIN > section{
        width: 100%;
    }

    .detalleCover-info{
        width: 70%;
        justify-content: flex-start;
    }

    .detalleCover-info > *:not(div){
        margin: 0;
        margin-left: 1ch;
        top: 0;
        bottom: 0;
        position: relative;
    }

    #detalle-clave{
        order: 1;
        margin-top: 1ch;
        margin-bottom: 0;
    }

    #detalle-title{
        order: 2;
        margin-bottom: 0.3ch;
    }

    #detalle-direccion{
        order: 3;
        text-align: left;
    }

    #detalle-cover-imgs{
        width: 100%;
    }

    .detalle-contents > *{
        max-width: unset;
    }

    .cita-costo{
        display: inline-block;
    }

    .cita-costo, .detalle-submenu{
        padding-left: 3ch;
    }

    .detalle-submenu{
        position: relative;
        padding-right: 3ch;
    }

    .detalle-submenu > img:nth-child(2){
        position: absolute;
        right: 0;
    }

    .detalle-float-btn{
        display: flex;
        position: fixed;
        right: 1ch;
        bottom: 1ch;
    }

}
</style>