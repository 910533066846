<template>
    <div class="PerfilUsuario6datosBancarios-MAIN">

        <section id="p8-NoHayMetodoPago">
            <img src="../assets/images/icons/credit-card.svg"/>
            <p>No hay método de pago archivados</p>
            <p>Los métodos de pago que agregue aparecerán aquí</p>
            <button @click="switchPayMethod(true)">agregar método de pago</button>
        </section>

        <section id="p8-addPayMethod">
            <div><img src="../assets/images/icons/edit.svg"/>Editar</div>
            <p><img src="../assets/images/icons/user.svg"/>Nombre que figura en la tarjeta*</p>
            <div><img src=""/><input type="text" placeholder="Nombre(s) Apellido paterno Apellido materno" disabled/></div>
            <br>
            <p><img src="../assets/images/icons/user.svg"/>Número de la tarjeta*</p>
            <div><img src=""/><input type="number" placeholder="0000  1234  4567  8900" disabled/></div>
            <br>
            <div>
                <div>
                    <p><img src=""/>Fecha de vencimiento*</p>
                    <input type="text" placeholder="MM/AA" disabled/>
                </div>
                <div>
                    <p><img src=""/>CVC*</p>
                    <input type="text" placeholder="123" disabled/>
                </div>
            </div>
            <button style="justify-self: center !important;" @click="switchPayMethod(false)">agregar</button>
        </section>

    </div>
</template>

<script>
import { stylize } from 'alexa-reactor-core'
export default {
    name: 'PerfilUsuario6datosBancarios.vue',

    methods:{
        switchPayMethod(addPayMethod_){
            if(addPayMethod_){
                stylize("#p8-addPayMethod").display = "flex";
                stylize("#p8-NoHayMetodoPago").display = "none";
            }else{
                stylize("#p8-NoHayMetodoPago").display = "flex";
                stylize("#p8-addPayMethod").display = "none";
            }
        }
    }
}
</script>

<style scoped>
.PerfilUsuario6datosBancarios-MAIN{
    display: flex;
    flex-direction: column;
    width: 100%;
}

#p8-NoHayMetodoPago, #p8-addPayMethod{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 5ch;
}

#p8-NoHayMetodoPago > p:nth-child(2){
    font-weight: bold;
    font-size: 2ch;
    margin-bottom: 0;
    text-align: center;
}

#p8-NoHayMetodoPago > p:nth-child(3){
    font-weight: 300;
}

#p8-addPayMethod{
    display: none;
}

#p8-addPayMethod > *{
    display: flex;
    align-items: center;
    width: 100%;
}

#p8-addPayMethod > div:nth-child(1){
    color: gray;
    text-decoration: underline;
}

#p8-addPayMethod > div:nth-child(1) img{
    width: 2ch;
    margin-right: 0.5ch;
}

#p8-addPayMethod > p > img{
    margin-right: 1ch;
}

#p8-addPayMethod > div{
    justify-content: flex-end;
}

#p8-addPayMethod > div > input{
    width: 95%;
}

#p8-addPayMethod > div:nth-child(8){
    width: 97.5%;
    align-self: flex-end !important;
    justify-content: space-between;
}

#p8-addPayMethod > div:nth-child(8) > div{
    width: 45%;
}

#p8-addPayMethod > div:nth-child(8) > div > input{
    width: 94%;
}

#p8-addPayMethod button{
    justify-self: center !important;
    width: fit-content;
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    #p8-NoHayMetodoPago{
        margin-bottom: 0;
    }

}

</style>