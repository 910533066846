<template> <!-- 2 Pantalla Buscador Locación. Usuario sin cuenta creada -->
    <div id="BuscadorNoSession-MAIN">
        <NavLocacion :currentSection="'Buscar Locacion'"/>

        <div class="proximo-lanzamiento" ref="proximoLanzamiento" id="p2-proximoLanzamiento" v-if="showProximoLanzamiento">
            <div>
                <img class="close-proximo-lanzamiento" src="../assets/images/close.png" @click="showProximoLanzamiento = false">
                <img src="../assets/images/coming-soon.svg">
                <h2>Próximo lanzamiento para búsquedas</h2>
                <button @click="proximamenteSubirLocacion()"><img src="../assets/images/btn-prox.png"></button>
            </div>
        </div>
        
        <section class="pantalla2-banner" @click="popUpProximamente()"> <!--delet this-->
            <h1>Eventos, Reuniones, Filmaciones y Shootings</h1>
            <div class="searchbar">
                <label>
                    <p><img src="../assets/images/icons/home.svg"/>¿Qué espacio buscas?</p>
                    <input type="text" placeholder="Escribe tipo de locación o ingresa clave" ref="searchTipoEspacio"/>
                </label>
                <label>
                    <p><img src="../assets/images/icons/marker.svg"/>¿Dónde?</p>
                    <select name="p2-tipoEspacio" id="p2-tipoEspacio" ref="searchDonde">
                        <option value="Selecciona">Selecciona</option>
                    </select>
                </label>
                <label for="p2-tipoEspacio"><img src="../assets/images/icons/arrow-small.svg"/></label>
                <div>
                    <img src="../assets/images/icons/filter.svg"/>
                    <button @click="startSearch()">
                        <img src="../assets/images/icons/search.svg"/> BUSCAR
                    </button>
                </div>
            </div>
            
            <div class="radio-search-div">
                <label><input type="checkbox" id="check-search-e" value="E" class="checkbox-round" ref="checkSearchE">Eventos</label>
                <label><input type="checkbox" id="check-search-r" value="R" class="checkbox-round" ref="checkSearchR">Reuniones</label>
                <label><input type="checkbox" id="check-search-f" value="F" class="checkbox-round" ref="checkSearchF">Filmaciones</label>
                <label><input type="checkbox" id="check-search-s" value="S" class="checkbox-round" ref="checkSearchS">Shootings</label>
            </div>

            <slider id="slider1-pantalla2" interval="3000">
                <img :src="require(`../assets/images/carrusel-2/1${isMobile('cel','')}.png`)"/>
                <img :src="require(`../assets/images/carrusel-2/2${isMobile('cel','')}.png`)"/>
                <img :src="require(`../assets/images/carrusel-2/3${isMobile('cel','')}.png`)"/>
                <img :src="require(`../assets/images/carrusel-2/4${isMobile('cel','')}.png`)"/>
                <img :src="require(`../assets/images/carrusel-2/5${isMobile('cel','')}.png`)"/>
                <img :src="require(`../assets/images/carrusel-2/6${isMobile('cel','')}.png`)"/>
            </slider>

            <div class="pantalla2-mobile-searchbar">
                <div>
                    <img src="../assets/images/icons/home.svg"/>
                    <div>
                        <p>¿Qué espacio buscas?</p>
                        <span>Escribe tipo de locación o ingresa clave</span>
                    </div>
                </div>
                <div>
                    <img src="../assets/images/icons/marker.svg"/>
                    <div>
                        <p>¿Dónde?</p>
                        <select name="p2-tipoEspacio-mobile" id="p2-tipoEspacio-mobile">
                            <option value="Selecciona">Selecciona</option>
                        </select>
                    </div>
                </div>
                <button>
                    <img src="../assets/images/icons/search.svg"/> BUSCAR
                </button>
            </div>
        </section>
  
        <section class="cinta-categorias cinta-icons">
            <img src="../assets/images/icons/arrow.svg" class="arrow-left" @click="moveIcons(-1)"/>
            <div class="cinta-container" ref="cintaContainer">
                <div @click="popUpProximamente()">
                    <div><img src="../assets/images/icons/bodegas.svg"><p>Bodegas</p></div>
                    <div><img src="../assets/images/icons/salones.svg"><p>Salones</p></div>
                    <div><img src="../assets/images/icons/locales.svg"><p>Locales</p></div>
                    <div><img src="../assets/images/icons/estadios.svg"><p>Estadios</p></div>
                    <div><img src="../assets/images/icons/helipuertos.svg"><p>Helipuertos</p></div>
                    <div><img src="../assets/images/icons/palcos.svg"><p>Palcos</p></div>
                    <div><img src="../assets/images/icons/casas.svg"/><p>Casas</p></div>
                    <div><img src="../assets/images/icons/terrazas.svg"/><p>Terrazas</p></div>
                    <div><img src="../assets/images/icons/casaantigua.svg"/><p>Casa antigua</p></div>
                    <div><img src="../assets/images/icons/albercas.svg"/><p>Albercas</p></div>
                    <div><img src="../assets/images/icons/jardines.svg"/><p>Jardines</p></div>
                    <div><img src="../assets/images/icons/sotanos.svg"/><p>Sótanos</p></div>
                    <div><img src="../assets/images/icons/haciendas.svg"/><p>Haciendas</p></div>
                    <div><img src="../assets/images/icons/house-tree.svg"/><p>Cabañas</p></div>
                    <div><img src="../assets/images/icons/vecindades.svg"/><p>Vecindades</p></div>
                    <div><img src="../assets/images/icons/iglesia.svg"/><p>Iglesia</p></div>
                    <div><img src="../assets/images/icons/cafeteria.svg"/><p>Cafeteria</p></div>
                    <div><img src="../assets/images/icons/foro.svg"/><p>Foro</p></div>
                    <div><img src="../assets/images/icons/oficinas.svg"/><p>Oficinas</p></div>
                    <div><img src="../assets/images/icons/deportivo.svg"/><p>Deportivo</p></div>
                    <div><img src="../assets/images/icons/galeria.svg"/><p>Galería</p></div>
                    <div><img src="../assets/images/icons/edificio.svg"/><p>Edificio</p></div>
                    <div><img src="../assets/images/icons/departamento.svg"/><p>Departamento</p></div>
                    <div><img src="../assets/images/icons/hospitales.svg"/><p>Hospitales</p></div>
                </div>
            </div>
            <img src="../assets/images/icons/arrow.svg" class="arrow-right" @click="moveIcons(1)"/>
        </section>

        <BuscoEsto v-if="experimental.buscoEstoActive"/>

        <section class="locaciones-destacadas">
            <div class="cinta-container destacadas-tiles">
                <div>
                    <!-- Solo uno, repetir 12 veces -->        
                    <div v-for="(locacion, index) in paginatedLocaciones" :key="index" class="tile-destacado"  @click="verLocacion(locacion.id, locacion.disponible)">
                        <div>
                            <!--<img src="../assets/images/icons/arrow.svg" class="arrow-left" @click="moveDestacado(-1, locacion.id)"/>-->
                            <img  class="tile-destacado-type-icon" :src="require(`../assets/images/icons/${viewImg(locacion?.TipoFilmacion?.S_Tipo)}.svg`)"/>
                            <!--<img src="../assets/images/icons/arrow.svg" class="arrow-right" @click="moveDestacado(+1, locacion.id)"/>-->
                            <div class="tile-destacado-slider" :id="`tile-destacado-slider-${locacion.id}`">
                                <div>
                                    <div class="tile-destacado-img" :style="`background-image:url('${showImg(locacion?.Fotos[0]?.S_URL)}');`">
                                        <div class="tile-direccion">{{ locacion?.direccion?.S_Estado }}<br>{{ locacion?.direccion?.S_Municipio }}</div>
                                        <div class="tile-sfre">{{getTypesSFRE(locacion?.S_Tipo)}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="tile-title">{{ locacion.S_Titulo }}</p>
                        <p class="tile-clave">Clave: 
                            <strong>
                                {{
                                    locacion.S_SKU
                                    ? 
                                    locacion.S_SKU[0] + locacion.S_SKU[1] + locacion.S_SKU[2] + locacion.S_SKU[3] + locacion.S_SKU[4]
                                    : 
                                    (locacion.S_Titulo?.replace(" ", "x")[0].toUpperCase() + locacion?.S_Titulo.replace(" ", "x")[1].toUpperCase() + locacion?.S_Titulo.replace(" ", "x")[2].toUpperCase() + locacion?.S_Titulo.replace(" ", "x").length + locacion.id)
                                }}
                            </strong>
                            <!--&nbsp; Costo por visita <strong>${{ locacion.N_CostoVisita }}</strong>-->
                        </p>
                    </div>
                    <!-- end tiles-->
                </div>
            </div>

            <div class="ver-mas" @click="paginationNextBack('next', true)">Ver más</div>

            <div class="num-pages">
                <img src="" class="arrow-left"/>
                <div v-for="(index) of numOfPages" :key="index">
                    <div class="number-page" :id="`num-page-${index}`" @click="paginationNextBack(index)">{{index}}</div>
                </div>
                <div class="num-page-next" @click="paginationNextBack('next')">
                    Siguiente
                    <img src="../assets/images/icons/arrow-long.svg"/>
                </div>
            </div>
        </section>

        <div class="gold">
            <img src="../assets/images/gold.png" @click="insertPassGold = true">
            <input type="text" placeholder="Abc123" @keyup.enter="handleEnterGold" @blur="handleBlurGold" ref="goldInput">
            <div class="gold-wrong-pw" v-if="showWrongGold">
                <div>
                    <h2>Contraseña incorrecta</h2>
                    <button @click="showWrongGold = false">Aceptar</button>
                </div>
            </div>
            <div class="gold-wrong-pw" v-if="insertPassGold">
                <div>
                    <h2>Introduzca la contraseña</h2>
                    <button @click="insertPassGold = false, this.$refs.goldInput.focus()">Aceptar</button>
                </div>
            </div>
        </div>

        <FooterLocacion/>  
    </div>
</template>
  
<script>
import FooterLocacion from './FooterLocacion.vue';
import NavLocacion from "./NavLocacion.vue";
/*eslint-disable no-unused-vars*/
import { element, htjs, rand } from "alexa-reactor-core";
import BuscoEsto from './BuscoEsto.vue';

export default{
    components: { NavLocacion, FooterLocacion, BuscoEsto },
    name: 'BuscadorNoSession',
    data() {
        return {
            locacionesArray: [              
    /*{
        "id": 1,
        "TipoFilmacion": {
            "S_Tipo": "jardin"
        },
        "S_Titulo": "Jardín con alberca para tu boda perfecta",
        "Fotos": [{"S_URL":
            "Foto_2.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 2,
        "TipoFilmacion": {
            "S_Tipo": "terraza"
        },
        "S_Titulo": "Rento el mejor espacio para tus eventos",
        "Fotos": [{"S_URL":
            "Foto_3.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 520,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Cuauhtémoc"
        },
        "S_Tipo": "RE",
    },
    {
        "id": 3,
        "TipoFilmacion": {
            "S_Tipo": "casa antigua"
        },
        "S_Titulo": "Rento casa eventos pequeños, la mejor ubicación",
        "Fotos": [{"S_URL":
            "Foto_4.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 600,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "FRE",
    },
    /*{
        "id": 4,
        "TipoFilmacion": {
            "S_Tipo": ""
        },
        "S_Titulo": "Suite para eventos de marca",
        "Fotos": [{"S_URL":
            "Foto_5.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 510,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": ""
        },
        "S_Tipo": "",
    },*/
    {
        "id": 5,
        "TipoFilmacion": {
            "S_Tipo": "departamento"
        },
        "S_Titulo": "Rento mi depa para filmaciones o shoots",
        "Fotos": [{"S_URL":
            "Foto_6.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "La Roma"
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 8,
        "TipoFilmacion": {
            "S_Tipo": "departamento"
        },
        "S_Titulo": "Espacios retro para tus proyectos",
        "Fotos": [{"S_URL":
            "Foto_9.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 500,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Cuauhtémoc"
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 9,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Hermosa casa para grabar o tomas de fotos",
        "Fotos": [{"S_URL":
            "Foto_10.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 680,
        "direccion":{
            "S_Estado":  "",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "SF",
    },
    {
        "id": 10,
        "TipoFilmacion": {
            "S_Tipo": "jardin"
        },
        "S_Titulo": "Rento mi jardín para retiros, clases o reuniones",
        "Fotos": [{"S_URL":
            "Foto_11.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 545,
        "direccion":{
            "S_Estado":  "Morelos",
            "S_Municipio": ""
        },
        "S_Tipo": "FR",
    },
    {
        "id": 11,
        "TipoFilmacion": {
            "S_Tipo": "locales"
        },
        "S_Titulo": "Piso libre para hacer cualquier evento",
        "Fotos": [{"S_URL":
            "Foto_12.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 680,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Coyoacán"
        },
        "S_Tipo": "RE",
    },
    {
        "id": 12,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "El mejor jardín para eventos",
        "Fotos": [{"S_URL":
            "Foto_13.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "SFRE",
    },
    {
        "id": 13,
        "TipoFilmacion": {
            "S_Tipo": "bodega"
        },
        "S_Titulo": "Bodega estilo industrial multiples usos",
        "Fotos": [{"S_URL":
            "Foto_14.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 600,
        "direccion":{
            "S_Estado":  "Edo. De México",
            "S_Municipio": ""
        },
        "S_Tipo": "FE",
    },
    {
        "id": 14,
        "TipoFilmacion": {
            "S_Tipo": "departamento"
        },
        "S_Titulo": "Ph para renta de un día para proyectos fílmicos",
        "Fotos": [{"S_URL":
            "Foto_15.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 576,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Polanco"
        },
        "S_Tipo": "SFR",
    },
    /*{
        "id": 15,
        "TipoFilmacion": {
            "S_Tipo": "casa antigua"
        },
        "S_Titulo": "Casona para capacitaciones, cursos, conferencias",
        "Fotos": [{"S_URL":
            "Foto_16.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 590,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Col. Juárez"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 16,
        "TipoFilmacion": {
            "S_Tipo": "departamento"
        },
        "S_Titulo": "Linda estancia para tus capacitaciones o showroom",
        "Fotos": [{"S_URL":
            "Foto_17.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Polanco"
        },
        "S_Tipo": "R",
    },
    {
        "id": 17,
        "TipoFilmacion": {
            "S_Tipo": "bodega"
        },
        "S_Titulo": "Bodega para cualquier proyecto",
        "Fotos": [{"S_URL":
            "Foto_18.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 710,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Azcapotzalco"
        },
        "S_Tipo": "FRE",
    },
    {
        "id": 18,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Rento casa para filmaciones",
        "Fotos": [{"S_URL":
            "Foto_19.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 590,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "SF",
    },
    {
        "id": 19,
        "TipoFilmacion": {
            "S_Tipo": "hospital"
        },
        "S_Titulo": "Hospital para filmaciones",
        "Fotos": [{"S_URL":
            "Foto_20.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Centro"
        },
        "S_Tipo": "F",
    },
    {
        "id": 20,
        "TipoFilmacion": {
            "S_Tipo": "bodega"
        },
        "S_Titulo": "Bodegón para eventos de marca o sociales",
        "Fotos": [{"S_URL":
            "Foto_21.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 645,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Col. Juárez"
        },
        "S_Tipo": "FRE",
    },
    {
        "id": 21,
        "TipoFilmacion": {
            "S_Tipo": "departamento"
        },
        "S_Titulo": "Rento departamento vacío para reuniones chicas",
        "Fotos": [{"S_URL":
            "Foto_22.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 510,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Narvarte"
        },
        "S_Tipo": "R",
    },
    {
        "id": 22,
        "TipoFilmacion": {
            "S_Tipo": "galeria"
        },
        "S_Titulo": "Rento espacio tipo galería",
        "Fotos": [{"S_URL":
            "Foto_23.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 680,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Col. Juárez"
        },
        "S_Tipo": "RE",
    },
    {
        "id": 23,
        "TipoFilmacion": {
            "S_Tipo": "casa antigua"
        },
        "S_Titulo": "Casa solo pare eventos de marca y filmaciones",
        "Fotos": [{"S_URL":
            "Foto_24.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 980,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "SFRE",
    },
    {
        "id": 24,
        "TipoFilmacion": {
            "S_Tipo": "deportivo"
        },
        "S_Titulo": "Club para filmaciones",
        "Fotos": [{"S_URL":
            "Foto_25.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Xochimilco"
        },
        "S_Tipo": "F",
    },
    /*{
        "id": 25,
        "TipoFilmacion": {
            "S_Tipo": "galeria"
        },
        "S_Titulo": "Galería para showroom y eventos exclusivos",
        "Fotos": [{"S_URL":
            "Foto_26.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 699,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Col. Juárez"
        },
        "S_Tipo": "",
    },
    {
        "id": 26,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Casona para cursos y capacitaciones",
        "Fotos": [{"S_URL":
            "Foto_27.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 85,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Polanco"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 27,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Rento hermosa casa con cancha de tenis",
        "Fotos": [{"S_URL":
            "Foto_28.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Coyoacán"
        },
        "S_Tipo": "SF",
    },
    /*{
        "id": 28,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Hermoso jardín exclusivo",
        "Fotos": [{"S_URL":
            "Foto_29.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 730,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Álvaro Obregón"
        },
        "S_Tipo": "",
    },
    {
        "id": 29,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Casa completa para tomas de fotos y grabaciones",
        "Fotos": [{"S_URL":
            "Foto_30.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Tlalpan"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 30,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Casa para proyectos filmicos",
        "Fotos": [{"S_URL":
            "Foto_31.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 515,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Pedregal"
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 31,
        "TipoFilmacion": {
            "S_Tipo": "casa antigua"
        },
        "S_Titulo": "Casa de época en renta",
        "Fotos": [{"S_URL":
            "Foto_32.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 590,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Centro"
        },
        "S_Tipo": "SF",
    },
    {
        "id": 33,
        "TipoFilmacion": {
            "S_Tipo": "casa antigua"
        },
        "S_Titulo": "Casona porfiriana para proyectos",
        "Fotos": [{"S_URL":
            "Foto_34.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 725,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Centro"
        },
        "S_Tipo": "SF",
    },
    {
        "id": 35,
        "TipoFilmacion": {
            "S_Tipo": "helipuerto"
        },
        "S_Titulo": "Helipuerto para renta según proyecto",
        "Fotos": [{"S_URL":
            "Foto_36.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 698,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "SFRE",
    },
    /*{
        "id": 36,
        "TipoFilmacion": {
            "S_Tipo": "iglesia"
        },
        "S_Titulo": "Iglesia",
        "Fotos": [{"S_URL":
            "Foto_37.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Centro"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 37,
        "TipoFilmacion": {
            "S_Tipo": "terraza"
        },
        "S_Titulo": "Terraza rascacielos",
        "Fotos": [{"S_URL":
            "Foto_38.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 646,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Álvaro Obregón"
        },
        "S_Tipo": "SE",
    },
    {
        "id": 38,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Casa de foto proyectos seleccionados",
        "Fotos": [{"S_URL":
            "Foto_39.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 732,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Coyoacán"
        },
        "S_Tipo": "SF",
    },
    /*{
        "id": 39,
        "TipoFilmacion": {
            "S_Tipo": "departamento"
        },
        "S_Titulo": "Roof Balcón",
        "Fotos": [{"S_URL":
            "Foto_40.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Condesa"
        },
        "S_Tipo": "",
    },
    {
        "id": 40,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Jardín para eventos infantiles o sociales",
        "Fotos": [{"S_URL":
            "Foto_41.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 690,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 41,
        "TipoFilmacion": {
            "S_Tipo": "terraza"
        },
        "S_Titulo": "Renta super terraza vista toda la ciudad",
        "Fotos": [{"S_URL":
            "Foto_42.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 676,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 42,
        "TipoFilmacion": {
            "S_Tipo": "cabaña"
        },
        "S_Titulo": "Casa para filmaciones o eventos",
        "Fotos": [{"S_URL":
            "Foto_43.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Tlalpan"
        },
        "S_Tipo": "SFRE",
    },
    {
        "id": 43,
        "TipoFilmacion": {
            "S_Tipo": "cabaña"
        },
        "S_Titulo": "Espacio estilo cabaña",
        "Fotos": [{"S_URL":
            "Foto_44.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Tlalpan"
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 44,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "jardín para eventos sociales.",
        "Fotos": [{"S_URL":
            "Foto_45.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 535,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "R",
    },
    /*{
        "id": 45,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Espacios sofisticados para eventos políticos",
        "Fotos": [{"S_URL":
            "Foto_46.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 967,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "",
    },
    {
        "id": 46,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Casona para múltiples proyectos",
        "Fotos": [{"S_URL":
            "Foto_47.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 560,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 47,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Casa estilo barroco. Espacios impactantes",
        "Fotos": [{"S_URL":
            "Foto_48.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 876,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Coyoacán"
        },
        "S_Tipo": "SF",
    },
    {
        "id": 48,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Casa mansión vacía para lanzamientos de marca",
        "Fotos": [{"S_URL":
            "Foto_49.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 767,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "FRE",
    },
    /*{
        "id": 49,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Casa minimalista con gran jardín",
        "Fotos": [{"S_URL":
            "Foto_50.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 576,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "",
    },
    {
        "id": 50,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Casa lista para grabaciones y fotos",
        "Fotos": [{"S_URL":
            "Foto_51.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Álvaro Obregón"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 51,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Casa amueblada con muchos espacios",
        "Fotos": [{"S_URL":
            "Foto_52.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "Edo. De México",
            "S_Municipio": ""
        },
        "S_Tipo": "SFR",
    },
    /*{
        "id": 52,
        "TipoFilmacion": {
            "S_Tipo": "galeria"
        },
        "S_Titulo": "Galería única y exclusiva",
        "Fotos": [{"S_URL":
            "Foto_53.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 790,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Col. Juárez"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 53,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Casa única y de época",
        "Fotos": [{"S_URL":
            "Foto_54.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 570,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Miguel Hidalgo"
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 54,
        "TipoFilmacion": {
            "S_Tipo": ""
        },
        "S_Titulo": "Edificio estilo mexicano varios cuartos conceptuales",
        "Fotos": [{"S_URL":
            "Foto_55.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 565,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Col. Centro"
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 55,
        "TipoFilmacion": {
            "S_Tipo": ""
        },
        "S_Titulo": "Hacienda ideal para tus retiros y cursos",
        "Fotos": [{"S_URL":
            "Foto_56.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 680,
        "direccion":{
            "S_Estado":  "Edo. De México",
            "S_Municipio": ""
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 56,
        "TipoFilmacion": {
            "S_Tipo": "iglesia"
        },
        "S_Titulo": "Iglesia histórica anglicana",
        "Fotos": [{"S_URL":
            "Foto_57.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 670,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Col. Centro"
        },
        "S_Tipo": "FRE",
    },
    /*{
        "id": 57,
        "TipoFilmacion": {
            "S_Tipo": "galeria"
        },
        "S_Titulo": "Galería estilo industrial",
        "Fotos": [{"S_URL":
            "Foto_58.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Col. Juárez"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 58,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Casa única en su género eventos seleccionados",
        "Fotos": [{"S_URL":
            "Foto_59.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 698,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Tlalpan"
        },
        "S_Tipo": "SF",
    },
    /*{
        "id": 59,
        "TipoFilmacion": {
            "S_Tipo": "jardin"
        },
        "S_Titulo": "Casa rocosa muy aprovechable para grabaciones",
        "Fotos": [{"S_URL":
            "Foto_60.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 590,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Coyoacán"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 60,
        "TipoFilmacion": {
            "S_Tipo": "galeria"
        },
        "S_Titulo": "Hotel Boutique para cualquier proyecto",
        "Fotos": [{"S_URL":
            "Foto_61.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "Edo. De México",
            "S_Municipio": ""
        },
        "S_Tipo": "SFRE",
    },
    /*{
        "id": 61,
        "TipoFilmacion": {
            "S_Tipo": "galeria"
        },
        "S_Titulo": "Maravillosa galería rentas elegidas",
        "Fotos": [{"S_URL":
            "Foto_62.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 790,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Cuauhtémoc"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 62,
        "TipoFilmacion": {
            "S_Tipo": "hacienda"
        },
        "S_Titulo": "Rancho hacienda para tus videos o retiros",
        "Fotos": [{"S_URL":
            "Foto_63.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Tlalpan"
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 63,
        "TipoFilmacion": {
            "S_Tipo": "local"
        },
        "S_Titulo": "Local en la mejor zona de CDMX",
        "Fotos": [{"S_URL":
            "Foto_64.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 530,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Polanco"
        },
        "S_Tipo": "RE",
    },
    {
        "id": 64,
        "TipoFilmacion": {
            "S_Tipo": "galeria"
        },
        "S_Titulo": "Espacio para cursos, clases y capacitaciones",
        "Fotos": [{"S_URL":
            "Foto_65.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 570,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Cuauhtémoc"
        },
        "S_Tipo": "RE",
    },
    {
        "id": 65,
        "TipoFilmacion": {
            "S_Tipo": "foro"
        },
        "S_Titulo": "Escenarios chicos con camerinos",
        "Fotos": [{"S_URL":
            "Foto_66.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Cuauhtémoc"
        },
        "S_Tipo": "SF",
    },
    {
        "id": 66,
        "TipoFilmacion": {
            "S_Tipo": "alberca"
        },
        "S_Titulo": "Casa única lista para lo que necesites",
        "Fotos": [{"S_URL":
            "Foto_67.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 735,
        "direccion":{
            "S_Estado":  "Edo. De México",
            "S_Municipio": ""
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 67,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Renta casa áreas o completa",
        "Fotos": [{"S_URL":
            "Foto_68.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "Edo. De México",
            "S_Municipio": ""
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 68,
        "TipoFilmacion": {
            "S_Tipo": "hacienda"
        },
        "S_Titulo": "Rancho excelente para retiros Edo. Mex",
        "Fotos": [{"S_URL":
            "Foto_69.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "Edo. De México",
            "S_Municipio": ""
        },
        "S_Tipo": "SFRE",
    },
    {
        "id": 69,
        "TipoFilmacion": {
            "S_Tipo": ""
        },
        "S_Titulo": "Locación para bodas íntimas o reuniones",
        "Fotos": [{"S_URL":
            "Foto_70.png"
        }],
        "disponible": false,
        "N_CostoVisita": 670,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": ""
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 70,
        "TipoFilmacion": {
            "S_Tipo": "cafeteria"
        },
        "S_Titulo": "Hermosa cafetería con jardín en condo",
        "Fotos": [{"S_URL":
            "Foto_71.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Narvarte"
        },
        "S_Tipo": "SF",
    },
    /*{
        "id": 71,
        "TipoFilmacion": {
            "S_Tipo": ""
        },
        "S_Titulo": "Casona perfecta para filmaciones, cursos y más",
        "Fotos": [{"S_URL":
            "Foto_72.png"
        }],
        "disponible": false,
        "N_CostoVisita": 540,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": ""
        },
        "S_Tipo": "",
    },
    {
        "id": 72,
        "TipoFilmacion": {
            "S_Tipo": "casa antigua"
        },
        "S_Titulo": "Atención agencias para sus eventos",
        "Fotos": [{"S_URL":
            "Foto_73.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 510,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Cuauhtémoc"
        },
        "S_Tipo": "",
    },
    {
        "id": 73,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Rento casa para minis grabaciones",
        "Fotos": [{"S_URL":
            "Foto_74.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 590,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Coyoacán"
        },
        "S_Tipo": "",
    },
    {
        "id": 74,
        "TipoFilmacion": {
            "S_Tipo": "casa"
        },
        "S_Titulo": "Hermosa casa estilo inglés",
        "Fotos": [{"S_URL":
            "Foto_75.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Xochimilco"
        },
        "S_Tipo": "",
    },*/
    {
        "id": 75,
        "TipoFilmacion": {
            "S_Tipo": "edificio"
        },
        "S_Titulo": "Edificio vacío años 70`s",
        "Fotos": [{"S_URL":
            "Foto_76.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 620,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Centro"
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 76,
        "TipoFilmacion": {
            "S_Tipo": "oficina"
        },
        "S_Titulo": "Rento todo un piso para sociales, marcas, fotos, etc",
        "Fotos": [{"S_URL":
            "Foto_77.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 670,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Centro"
        },
        "S_Tipo": "FRE",
    },
    {
        "id": 77,
        "TipoFilmacion": {
            "S_Tipo": "edificio"
        },
        "S_Titulo": "Plaza abandonada en Edo. de México",
        "Fotos": [{"S_URL":
            "Foto_78.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 780,
        "direccion":{
            "S_Estado":  "Edo. De México",
            "S_Municipio": ""
        },
        "S_Tipo": "SFRE",
    },
    {
        "id": 78,
        "TipoFilmacion": {
            "S_Tipo": "casa antigua"
        },
        "S_Titulo": "Casona de época",
        "Fotos": [{"S_URL":
            "Foto_79.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 545,
        "direccion":{
            "S_Estado":  "Edo. De México",
            "S_Municipio": ""
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 79,
        "TipoFilmacion": {
            "S_Tipo": "terraza"
        },
        "S_Titulo": "Roof con vistas a la ciudad para shoots",
        "Fotos": [{"S_URL":
            "Foto_80.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Narvarte"
        },
        "S_Tipo": "SF",
    },
    {
        "id": 80,
        "TipoFilmacion": {
            "S_Tipo": "deportivo"
        },
        "S_Titulo": "Renta club por secciones",
        "Fotos": [{"S_URL":
            "Foto_81.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Xochimilco"
        },
        "S_Tipo": "SF",
    },
    {
        "id": 81,
        "TipoFilmacion": {
            "S_Tipo": "galeria"
        },
        "S_Titulo": "Espacios de alto diseño",
        "Fotos": [{"S_URL":
            "Foto_82.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 680,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Juárez"
        },
        "S_Tipo": "SFR",
    },
    {
        "id": 82,
        "TipoFilmacion": {
            "S_Tipo": "jardin"
        },
        "S_Titulo": "Roof eventos infantiles",
        "Fotos": [{"S_URL":
            "Foto_83.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 498,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Coyoacán"
        },
        "S_Tipo": "RE",
    },
    {
        "id": 83,
        "TipoFilmacion": {
            "S_Tipo": "alberca"
        },
        "S_Titulo": "Shoots en increíble alberca en condominio",
        "Fotos": [{"S_URL":
            "Foto_84.jpg"
        }],
        "disponible": false,
        "N_CostoVisita": 590,
        "direccion":{
            "S_Estado":  "CDMX",
            "S_Municipio": "Narvarte"
        },
        "S_Tipo": "SF",
    }
            ],
            tempStorageForAllLocations: [],
            numOfPages: 1,
            currentPage: 1,
            paginatedLocaciones: [],
            allLocations: [],
            approvedLocations: [],
            showWrongGold: false,
            showProximoLanzamiento: false,
            insertPassGold: false,
            experimental:{
                buscoEstoActive: false,
            }
        }
    },

    methods:{
        loadData() {
            this.paginator();
            setTimeout(() => {
                document.getElementById("num-page-1").click();  
            }, 10);
        },

        paginator(){
            this.numOfPages = Math.ceil(this.locacionesArray.length / 12);
        },

        paginateArray(isMobile_=false) {
            const startIndex = (this.currentPage - 1) * 12;
            const endIndex = startIndex + 12;
            // Cortar el array de locaciones en bloques de 12 elementos
            if(isMobile_)
                this.paginatedLocaciones = this.locacionesArray.slice(0, endIndex);
            else
                this.paginatedLocaciones = this.locacionesArray.slice(startIndex, endIndex);
        },

        paginationNextBack(num_, isMobile_=false) {  
            if (num_ === 'next') {
                if (this.currentPage + 1 <= this.numOfPages) {
                    this.currentPage++;
                    this.paginateArray(isMobile_); // Llamar a la función para actualizar el array paginado
                }
                else{
                    return this.popUpProximamente();
                }
            } else {
                this.currentPage = num_;
                this.paginateArray(); // Llamar a la función para actualizar el array paginado
            }

            if(!isMobile_)
                window.scrollTo(0,0);
            //console.log(replaceLastPage);

            for(let i = 1; i <= this.numOfPages; i++)
                document.getElementById(`num-page-${i}`).classList.remove("num-page-active");
            document.getElementById(`num-page-${this.currentPage}`).classList.add("num-page-active");
        },

        popUpProximamente(){
            this.showProximoLanzamiento = true;
            console.log("Proximo lanzamiento")
        },

        proximamenteSubirLocacion(){
            this.showProximoLanzamiento = false;
            try {
                document.getElementById("subeTuLocacionPc").click();
            } catch (error) {
                document.getElementById("subeTuLocacionCel").click();
            }
        },

        verLocacion(id_, disponible_){
            console.log(id_)
            if(disponible_ == false){
                this.popUpProximamente()
            }

            //console.log(id_);
            //window.location.hash = "/detalle";
            //let newDirection = window.location.href.split("#")[0].split("?")[0] + `?id=${id_}/#/detalle`;
            //window.location.href = newDirection;
            //window.scrollTo(0, 0);
            //let result = window.location.href.replace("#/subir-locacion", "#/detalle");
            //window.open(result, '_blank');
        },

        moveDestacado(direction_, element_){
            event.stopPropagation();
            let slide = document.getElementById(`tile-destacado-slider-${element_}`);
            slide.scrollLeft += slide.clientWidth * direction_;
        },

        moveIcons(direction_){
            let slide = this.$refs.cintaContainer;
            slide.scrollLeft += slide.clientWidth * direction_;
        },

        showImg(url_){
            if(url_ == undefined){
                return "";
            }

            if(url_.includes("locacionbackoffice")){
                return url_;
            }else if(url_.includes("/public/storage/")){
                return url_.replace("/public/", "https://locacionbackoffice.ideeo.mx/")
            }else{
                //:style="`background-image:url('test/${image}');`">
                return "test/" + url_;
            }
        },

        isMobile(yes_, no_){
            if(window.matchMedia('(max-width: 780px)').matches)
                return yes_;
            else
                return no_;
        },

        viewImg(locationType_){
            // image replacer-
            if(locationType_ == undefined || typeof locationType_ == "undefined"){
                return "casas";
            }
            locationType_ = locationType_.toLowerCase()

            let result = locationType_.replaceAll(" ", "");
            if(result == "jardin") result = "jardines";
            if(result == "terraza") result = "terrazas";
            if(result == "casa") result = "casas";
            if(result == "bodega") result = "bodegas";
            if(result == "hospital") result = "hospitales";
            if(result == "hacienda") result = "haciendas";
            if(result == "helipuerto") result = "helipuertos";
            if(result == "local") result = "locales";
            if(result == "alberca") result = "albercas";
            if(result == "oficina") result = "oficinas";
            if(result == "cabaña" || result == "cabañas") result = "house-tree";

            const exceptions = ["teatro", "cultivo", "hotel", "restaurante"];
            if(exceptions.includes(result))
                result = "casas";

            if(result == "") result = "casas";
            return result;
        },

        randomPlace(){
            const places = ["Roma", "Juárez", "Pedregal", "Condesa", "Polanco", "Del valle", "Narvarte"]
            return rand(places);
        },

        getLocations(){
            //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://locacionbackoffice.ideeo.mx/api/locaciones', {method: 'GET',
                headers: {
                    "Content-type": "application/json",
                    //"Authorization": `Bearer ${accessToken}`
                }})
                .then(response => response.json())
                .then(json => {
                    console.log("TODAS LAS LOCACIONES:");
                    this.allLocations = json.dato;
                    console.log(this.allLocations);

                    for(let location of this.allLocations){
                        if(location.aprobadas == 1){
                            this.approvedLocations.push(location);
                        }
                    }
                    console.log("Locaciones aprobadas", this.approvedLocations);

                    this.locacionesArray = [...this.locacionesArray, ...this.approvedLocations];
                    this.tempStorageForAllLocations = this.locacionesArray;

                    this.loadData();
                })
        },

        getTypesSFRE(arg_){
            arg_ = `«${arg_}»`;
            arg_ = arg_.replace("S", "Shootings, ");
            arg_ = arg_.replace("F", "Filmaciones, ");
            arg_ = arg_.replace("R", "Reuniones, ");
            arg_ = arg_.replace("E", "Eventos, ");
            arg_ = arg_.replace("«", "");
            arg_ = arg_.replace(", »", "");
            return arg_;
        },

        handleEnterGold() {
            console.log("Enter key pressed");
            this.showWrongGold = true;
        },

        handleBlurGold() {
            console.log("Input field lost focus");
            this.showWrongGold = true;
        },

        startSearch(){
            console.log("search...");
            const where = this.$refs.searchDonde;
            const typeSpace = this.$refs.searchTipoEspacio;
            let letters = [];
            if(this.$refs.checkSearchE.checked) letters.push("E");
            if(this.$refs.checkSearchR.checked) letters.push("R");
            if(this.$refs.checkSearchF.checked) letters.push("F");
            if(this.$refs.checkSearchS.checked) letters.push("S");
            const locationTypeLetters = letters;

            console.log(where.value, typeSpace.value, locationTypeLetters);

            let provisionalFilter0 = this.tempStorageForAllLocations;
            let provisionalFilter1 = [];
            let provisionalFilter2 = [];
            let provisionalFilter3 = [];

            
            if(typeSpace.value.trim() != ""){
                for(let i of provisionalFilter0){
                    if(typeof i.S_SKU == "undefined"){
                        continue;
                    }else{
                        if(typeSpace.value.includes(i.S_SKU) || typeSpace.value.includes(i.id) || i.S_SKU.includes(typeSpace.value) || i.id.toString().includes(typeSpace.value)){
                            provisionalFilter1.push(i);
                        }
                    }
                } 
            }else{
                provisionalFilter1 = provisionalFilter0;
            }

            if(where.value != "Selecciona"){
                for(let i of provisionalFilter1){
                    if(where.value.includes(i.direccion.S_Estado) || i.direccion.S_Estado.includes(where.value)){
                        provisionalFilter2.push(i);
                    }
                }
            }else{
                provisionalFilter2 = provisionalFilter1;
            }

            console.log("locationtypeletters", locationTypeLetters)
            if(locationTypeLetters.length > 0){
                for (let i of provisionalFilter2) {
                    //console.log("iii", i.S_Tipo);
                    // Convertir i.S_Tipo a un array de caracteres
                    let tipoArray = i.S_Tipo.split("");
                    
                    // Comprobar si todos los elementos de locationTypeLetters están en tipoArray
                    if (locationTypeLetters.every(letter => tipoArray.includes(letter))) {
                        provisionalFilter3.push(i);
                    }
                }
            }else{
                provisionalFilter3 = provisionalFilter2;
            }

            /*for(let i of this.locacionesArray){
                console.log(i.S_SKU)
                console.log(i.id)
                console.log(i.direccion.S_Estado)
                console.log(i.S_Tipo)
            }*/

            //this.locacionesArray = [this.locacionesArray[0]]; //funciona, pero debe tener al menos una locación para funcionar
            this.locacionesArray = provisionalFilter3;
            console.log("locacionesArray", this.locacionesArray)
            console.log("provisional filter 3", provisionalFilter3)
            this.loadData();
        }

    },
    mounted(){
        //this.locacionesArray = [];
        this.getLocations();
        //this.loadData();
        htjs();
    },
}
</script>
  
<style scoped>
#BuscadorNoSession-MAIN{
    width: 100%;
    overflow-x: hidden;
}

.checkbox-round {
    width: 18px !important;
    height: 18px !important;
    background-color: white;
    border-radius: 100vw !important;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    transform: scale(0.6);
}

.checkbox-round:checked {
    background-color: #FF282A;
}

.pantalla2-banner{
    width: 100%;
    height: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    margin-bottom: 5ch;
}

.pantalla2-banner> *:not(#slider1-pantalla2){
    z-index: 20;
}

#slider1-pantalla2{
    position: absolute;
    top: 7ch;
    left: 0;
    width: 100%;
    height: 25vw;
    z-index: 0;
}

#slider1-pantalla2 img{
    height: 35vw !important;
    width: 100vw !important;
}

.pantalla2-banner > h1, .pantalla2-banner button{
    color: white;
}

.pantalla2-banner > h1{
    text-shadow: 0ch 0ch 0.5ch rgba(0, 0, 0, 0.25);
    font-size: 2vw;
    margin-bottom: 2ch;
}

.searchbar{
    background-color: rgba(255,255,255, 0.6);
    width: fit-content;
    margin-left: -6ch;
    min-height: 8vh;
    border-radius: 100vw;
    display: flex;
    justify-content: space-between;
}

@media all and (max-width:870px){
    .searchbar{
        width: 95%;
        margin-left: 0;
    }

    .searchbar > label:nth-child(1) input{
        width: 18ch;
    }

    .searchbar > label:nth-child(2) select{
        width: fit-content;
    }
}

.searchbar > label:not(:nth-child(3)){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left:6%;
}

.searchbar > label:not(:nth-child(3)) p{
    display: flex;
    align-items: center;
    margin: 1ch;
}

.searchbar > label:not(:nth-child(3)) p img{
    max-width: 2ch;
    max-height: 2ch;
    margin-right: 1ch;
    margin-left: -3ch;
}

.searchbar input, .searchbar select{
    border: 2px solid transparent;
    background-color: transparent;
    border-bottom: 2px solid #FF282A;
    padding: 0.5ch;
    margin-bottom: -1px;
    width: 30ch;
}

.searchbar input::placeholder{
    color:black;
}

.searchbar > label:nth-child(3){
    display: flex;
    align-items: flex-end;
    padding-bottom: 1ch;
    opacity: 0;
}

.searchbar > div{
    display: flex;
}

.searchbar > div > img{
    width: 4ch;
    opacity: 0.8;
    margin-right: 2ch;
}

.searchbar button{
    border-radius: 0 100vw 100vw 0;
    border: none;
    width: 18ch;
    display: flex;  
    align-items: center;
    justify-content: center;
    background: linear-gradient(#E0230C, #F2B105);
}

.searchbar button img{
    width: 2.8ch;
    margin-right: 1ch;
}

.radio-search-div{
    color: white;
    font-weight: bold;
    width: 70ch;
    margin-top: 1ch;
    margin-left: 2vw;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.radio-search-div label{
    margin-right: 4ch;
}

/* cinta */
.cinta-categorias{
    margin-bottom: 8ch;
    overflow: auto;
}

.cinta-categorias, .locaciones-destacadas{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cinta-categorias > .cinta-container{
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    width: 93%;
}

.cinta-categorias > .cinta-container > div{
    max-width: unset;
    width: fit-content;
}

.cinta-categorias > .cinta-container > div > div{
    text-align: center;
    width: 10ch !important;
}

.cinta-container > div, .cinta-container{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.cinta-container > div{
    width: 85%;
}

.cinta-icons > div{
    width: 90%;
}

.cinta-icons > div > div > div{
    margin: 0 1ch;
}

.cinta-icons > div > div img{
    max-width: 3ch;
    max-height: 3ch !important;
}

.cinta-container > div > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: calc(100% / 8);
}

.cinta-container > div > div img{
    height: 4.3ch;
}

.cinta-categorias .arrow-left, .cinta-categorias .arrow-right{
    position: absolute;
    margin-top: -1ch;
}

.cinta-categorias .arrow-left{
    left: 2ch;
    cursor: pointer;
}

.cinta-categorias .arrow-right{
    right: 2ch;
    cursor: pointer;
}

.cinta-categorias > div img{
    max-width: 4ch;
    max-height: 3.5ch;
}

.arrow-right{
    transform: rotate(180deg);
}

/* destacadas */
.locaciones-destacadas{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.destacadas-tiles > div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

/* tiles */
.tile-destacado{
    display: flex;
    width: 20vw !important;
    min-height: 20vw;
    margin-bottom: 3ch;
}

.tile-destacado > div{
    width: 20vw;
    height: 20vw;
    border-radius: 1ch;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5ch;
}

.tile-destacado > div > *:not(.tile-destacado-img){
    position: absolute;
}

.tile-destacado-type-icon{
    /*position: absolute;*/
    z-index: 2;
    top: 1ch;
    right: 1ch;
    width: 1.8vw;
    height: 1.8vw !important;
    background-color:    #F4F4F4;
    padding: 0.7ch;
    border-radius: 100vw;
}

.tile-destacado .arrow-left, .tile-destacado .arrow-right{
    /*position: absolute;*/
    z-index: 2;
    width: 1.5ch;
    height: 1.5ch;
    padding: 0.5ch;
    border-radius: 100vw;
    background-color: #F4F4F4;
    display: none;
}

.tile-destacado:hover .arrow-left, .tile-destacado:hover .arrow-right{
    display: flex;
}

.tile-destacado .arrow-left{
    left: 1ch;
    display: none !important;
}

.tile-destacado .arrow-right{
    right: 1ch;
    display: none !important;
}

.tile-destacado .arrow-left:hover, .tile-destacado .arrow-right.arrow-right:hover{
    transform: scale(1.2);
    transition: all 0.1s;
    cursor: pointer;
}

.tile-destacado p{
    margin-top: 0;
    font-size: 1ch;
    white-space: nowrap;
    width: 100%;
}

.tile-destacado .tile-title{
    white-space:break-spaces;
    overflow: hidden;
    font-size: 1.3ch;
    margin-bottom: 1ch;
}

.arrow-right.arrow-right:hover{
    transform: scale(1.2) rotate(180deg) !important;
}

/* images */
.tile-destacado-slider{
    width: 20vw;
    height: 20vw;
    position: relative;
    overflow: hidden;
    scroll-behavior: smooth;
}
.tile-destacado-slider > div{
    height: 100%;
    width: fit-content;
    display: flex;
}
.tile-destacado-img {
    width: 20vw;
    height: 20vw;
    background-size: cover;
    z-index: 1;
    border-radius: 0.6ch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
}

.tile-direccion{
    padding: 1ch;
    color: white;
    text-shadow: 0ch 0ch 1ch black;
    text-align: right;
}

.tile-sfre{
    padding: 1ch;
    text-align: right;
    font-size: 1ch;
    color: white;
    text-shadow: 0ch 0ch 1ch black;
}

/* numerador */
.num-pages{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5ch;
    margin-bottom: 5ch;
}

.number-page{
    border: 1px solid transparent;
    padding: 1ch 1.5ch;
    border-radius: 0.5ch;
    margin: 0 0.4ch;
}

.number-page:hover{
    border: 1px solid black;
}

.num-page-active{
    background-color: #4D4D4D;
    color: white;
}

.num-page-next{
    display: flex;
    align-items: center;
    margin-right: 8vw;
    padding: 1ch 1.5ch;
    border-radius: 0.5ch;
    border: 1px solid transparent;
}

.num-page-next:hover{
    border: 1px solid black;
}


.num-page-next img{
    margin-left: 1ch;
}

.pantalla2-mobile-searchbar{
    display: none;
}

.ver-mas{
    display: none;
}

.proximo-lanzamiento-copy{
    margin: 0 !important;
    padding: 0 !important;
}

.proximo-lanzamiento{
    position: fixed;
    top: 0 !important;
    margin-top: 0 !important;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 25;
    border-radius: 0;
}

.proximo-lanzamiento > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 6ch 6ch;
    border-radius: 1ch;
    position: relative;
}

.proximo-lanzamiento > div > img:not(.close-proximo-lanzamiento){
    width: 8ch;
}

.proximo-lanzamiento h2{
    color: #707070;
    margin-top: 2ch;
    margin-bottom: 3ch;
}

.proximo-lanzamiento button{
    width: 22ch;
    background-color: transparent;
    border: none;
}

.proximo-lanzamiento button > img{
    width: 100%;
}

.close-proximo-lanzamiento{
    width: 1.6ch;
    position: absolute;
    top: 2ch;
    right: 2ch;
    cursor: pointer;
}

.animate-popup{
    animation-duration: 6s;
    animation-fill-mode: forwards;
    animation-name: proximoLanzamiento;
}

@keyframes proximoLanzamiento {
    0%{
        opacity: 1;
        right: -100%;
    }

    5%{
        right: 0;
    }
    
    90%{
        opacity: 1;
    }

    100%{
        opacity: 0;
    }
}

.gold{
    width: 84%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: auto;
    margin-top: 2ch;
    margin-bottom: 7ch;
}

.gold > img{
    width: 100%;
}

.gold > input{
    position: absolute;
    padding: 0.5ch;
    top: 65%;
    left: 20%;
    width: 20%;
    text-align: center;
}

.gold-wrong-pw{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(50, 50, 50, 0.4);
    z-index: 20;
}

.gold-wrong-pw > div{
    padding: 3ch;
    border-radius: 2ch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.gold-wrong-pw > div button{
    color: white;
    font-family: inherit;
    border-radius: 100vw;
    background-color: #FF262C;
    padding: 1ch 3ch;
    border: none;
}

/********************************************************************
 * RESPONSIVE
 ********************************************************************/
@media all and (max-width:780px){

    .pantalla2-banner{
        height: calc(110vw + 4vh);
        min-height: fit-content;
    }

    #slider1-pantalla2{
        height: calc(110vw + 4vh);
    }
    #slider1-pantalla2 img{
        height: 120vw !important;
    }

    .pantalla2-banner > *:not(.pantalla2-banner){
        display: none;
    }

    .pantalla2-banner h1{
        display: flex !important;
        font-size: 6vw;
        text-align: center;
        width: 85%;
    }

    .pantalla2-banner{
        display: flex;
    }

    .pantalla2-mobile-searchbar{
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .pantalla2-mobile-searchbar button{
        background: linear-gradient(#FF4C0B, #FF0A46);
        color: white;
        padding: 4vw 9vw;
        font-size: 3.5vw;
        border: none;
        border-radius: 100vw;
        text-transform: uppercase;
        font-weight: bold;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: 4vw;
    }

    .pantalla2-mobile-searchbar > div{
        display: flex;
        justify-content: space-evenly;
        background-color: rgba(255,255,255, 0.6);
        border-radius: 100vw;
        width: 100% !important;
        margin: 2vw 0;
        min-height: 18vw;
    }

    .pantalla2-mobile-searchbar > div img{
        width: 6vw;
    }

    .pantalla2-mobile-searchbar > div > div{
        width: calc(100% - 18vw);
        display: flex;
        flex-direction: column;
    }

    .pantalla2-mobile-searchbar p{
        margin-bottom: 2vw;
        margin-top: 2vw;
        font-size: 4.4vw;
    }

    .pantalla2-mobile-searchbar span, .pantalla2-mobile-searchbar select{
        background-color: transparent;
        margin: 0;
        font-size: 3.5vw;
        margin-bottom: 1ch;
        border: none;
    }

    .pantalla2-mobile-searchbar  button > img{
      width: 1.5ch;
      margin-right: 1ch;
      filter: brightness(0) invert(1);
    }

    .radio-search-div{
        display: none  !important;;
    }

    /* cinta */
    .cinta-categorias {
        max-width: 100%;
    }

    .cinta-categorias > img {
        display: none;
    }

    .cinta-categorias .cinta-container {
        overflow: auto;
        display: flex; /* Agrega display: flex */
        justify-content: flex-start;
    }

    .cinta-categorias .cinta-container > div {
        flex-shrink: 0; /* Evita que el contenido se encoja */
        width: fit-content;
        max-width: unset;
        padding-left: 2vw;
        padding-right: 1vw;
    }

    .cinta-categorias .cinta-container > div p {
        font-size: 3.5vw;
        padding: 0 1ch;
    }

    /* destacadas */
    .destacadas-tiles > div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    /* tiles */
    .tile-destacado{
        width: 80vw !important;
        min-height: 80vw;
        margin-bottom: calc(6ch + 2vw);
        margin-top: 3ch;
    }

    .tile-destacado > div{
        width: 100%;
        height: 100%;
        margin-bottom: 0;
    }

    .tile-destacado p{
        font-size: 3.7vw;
        margin-top: 6ch;
        text-align: center;
    }

    .tile-destacado .tile-title{
        margin-top: 10ch;
        font-size: 3.4vw;
        height: 7ch;
    }

    .tile-destacado .tile-clave{
        margin-top: 0;
    }

    .tile-destacado-type-icon{
        margin-top: 0 !important;
        top: -8vw;
        right: 3vw;
        width: 7vw;
        height: 7vw !important;
    }

    /* images */
    .tile-destacado-slider{
        width: 80vw;
        height: 80vw;
        position: relative;
    }
    .tile-destacado-slider > div{
        height: 100%;
        width: fit-content;
        display: flex;
    }
    .tile-destacado-img {
        width: 80vw;
        height: 80vw;
        background-size: cover;
        z-index: 1;
        border-radius: 0.6ch;
    }

    /* numerador */
    .num-pages{
        display: none;
    }

    .ver-mas{
        display: flex;
        font-weight: bolder;
        text-decoration: underline;
        margin-bottom: 14vw;
        cursor: pointer;
    }


    .proximo-lanzamiento > div{
        max-width: 90vw;
        padding: 2ch 0;
    }

    .proximo-lanzamiento h2{
        font-size: 5vw;
        text-align: center;
    }

    .proximo-lanzamiento img{
        scale: 0.9;
    }

    .animate-popup{
        animation-duration: 6s;
        animation-fill-mode: forwards;
        animation-name: proximoLanzamiento;
    }

    @keyframes proximoLanzamiento {
        0%{
            opacity: 1;
        }
        
        90%{
            opacity: 1;
        }

        100%{
            opacity: 0;
        }
    }

    .gold{
        width: 100%;
    }

    .gold input{
        padding: 0;
    }

}
</style>