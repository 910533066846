<template> <!-- 6 Iniciar Sesión / Crear Cuenta -->
    <div class="InicioSesionCrearCuenta-MAIN">

        <div class="InicioSesion-close"></div>
        
        <div class="InicioSesion-forms">
            <img src="../assets/images/icons/logo.svg"/>
            <div>
                <button v-on:click="switchMode('IniciarSesion')" class="p6-switch-btn" id="p6-switch-btn-IniciarSesion"><span>Iniciar sesión</span></button>
                <button v-on:click="switchMode('CrearCuenta')" class="p6-switch-btn" id="p6-switch-btn-CrearCuenta"><span>Crear cuenta</span></button>
            </div>
            <div class="pantalla6-IniciarSesion">
                <p>Email</p>
                <div><img src="../assets/images/icons/mail.svg"/><input id="p6IniciarSesion-email" type="email" placeholder="nombre@mail.com" required></div>
                <button id="p6-IniciarSesion-btnEnviarCodigo" v-on:click="processIniciarSesion('enviarCodigo')" ref="btnEnviarCodigo">Enviar código de verificación</button>
                <p id="p6-IniciarSesion-pCodigo">Ingresa el Código que se envió a tu correo</p>
                <div id="p6-IniciarSesion-inputCodigo"><img src="../assets/images/icons/lock.svg"/><input type="text" required id="p6IniciarSesion-codigo"></div>
                <button id="p6-IniciarSesion-btnIniciar" v-on:click="processIniciarSesion('Acceder')">Iniciar sesión</button>
            </div>
            <div class="pantalla6-CrearCuenta">
                <div><img src="../assets/images/icons/user.svg"/><input id="p6CrearCuenta-nombre" type="text" placeholder="Nombre*" required></div>
                <div><input id="p6CrearCuenta-apellidos" type="text" placeholder="Apellidos*" required></div>
                <div><img src="../assets/images/icons/mail.svg"/><input id="p6CrearCuenta-email" type="email" placeholder="Email*" required></div>
                <div><img src="../assets/images/icons/mobile-notch.svg"/><input type="number" placeholder="Teléfono celular*" id="p6CrearCuenta-celular" required></div>
                <div><img src="../assets/images/icons/phone-call.svg"/><input type="number" placeholder="Teléfono Fijo" id="p6CrearCuenta-fijo"></div>
                <p class="p6-info"><strong>* Dato obligatorio</strong></p>
                <button v-on:click="processCrearCuenta('Registrarme')" ref="btnRegistrarme">Registrarme</button>
                <p class="p6-info">Al hacer clic en "Registrarme" estarás aceptando el <a @click="goTo('aviso')">Aviso de Privacidad</a> y los <a @click="goTo('terminos')">Términos y Condiciones</a> de Locación.</p>
            </div>
            <div id="access-info"></div>

            <img src="../assets/images/icons/arrow.svg" class="regresar-arrow" @click="goTo('buscar')"/>
        </div>

        <div class="InicioSesion-banner">
        </div>

        <section id="PopUp-validacion">
            <div>
                <p><img src="../assets//images/icons/user.svg"/>VERIFICA TUS DATOS</p>
                <p>A tu correo estará llegando un Código de Verificación para validar tu cuenta</p>
                <div><img src="../assets/images/icons/lock.svg"/><input type="text" placeholder="Ingresa código de verificación"/></div>
                <button v-on:click="processCrearCuenta('Validar')">Validar</button>
                <div class="p6-popup-closebutton" v-on:click="closePopUp()">+</div>
            </div>
        </section>

    </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { element } from 'alexa-reactor-core';
export default {
    name: 'InicioSesionCrearCuenta',
    data(){
        return{
            currentAccess: "IniciarSesion",
            regexEmail : /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            regexPhone : /^\d{8,12}$/
        }
    },
    methods:{

        switchMode(to_){
            this.currentAccess = to_;
            document.querySelector(`.pantalla6-IniciarSesion`).style.display = "none";
            document.querySelector(`.pantalla6-CrearCuenta`).style.display = "none";
            document.querySelector(`.pantalla6-${this.currentAccess}`).style.display = "flex";
            document.getElementById(`p6-switch-btn-IniciarSesion`).style.borderBottom = "2px solid lightgray";
            document.getElementById(`p6-switch-btn-CrearCuenta`).style.borderBottom = "2px solid lightgray";
            document.getElementById(`p6-switch-btn-${this.currentAccess}`).style.borderBottom = "2px solid red";
            if(to_ == "IniciarSesion"){
                document.querySelector(".InicioSesion-banner").style.backgroundImage = `url("${require('../assets/images/bgInicioSesion.png')}")`;
                document.getElementById("p6CrearCuenta-nombre").value = "";
                document.getElementById("p6CrearCuenta-apellidos").value = "";
                document.getElementById("p6CrearCuenta-email").value = "";
                document.getElementById("p6CrearCuenta-celular").value = "";
                document.getElementById("p6CrearCuenta-fijo").value = "";
                document.getElementById("p6-IniciarSesion-btnEnviarCodigo").style.display = "flex";
                document.getElementById("p6-IniciarSesion-pCodigo").style.display = "none";
                document.getElementById("p6-IniciarSesion-inputCodigo").style.display = "none";
                document.getElementById("p6-IniciarSesion-btnIniciar").style.display = "none";
            }
            if(to_ == "CrearCuenta"){
                document.querySelector(".InicioSesion-banner").style.backgroundImage = `url("${require('../assets/images/bgCrearCuenta.png')}")`;
                document.getElementById("p6IniciarSesion-email").value = "";
                document.getElementById("p6IniciarSesion-codigo").value = "";
            }
            document.getElementById("access-info").style.display = "none";
        },
 
        processIniciarSesion(step_){
            if(step_ == "enviarCodigo"){
                this.$refs.btnEnviarCodigo.style.pointerEvents = "none";
                this.$refs.btnEnviarCodigo.style.opacity = 0.5;
                let email = document.getElementById("p6IniciarSesion-email").value;
                if (this.regexEmail.test(email)){
                    // Send verification code... {}
                    fetch('https://locacionbackoffice.ideeo.mx/api/auth/solicitarCodigo', {
                        method: 'POST',
                        body: JSON.stringify({
                            tipo_sesion: "E",
                            usuario: email,
                            medio: "EMAIL"
                        }),
                        headers: {
                            "Content-type": "application/json"
                        }})
                    .then(response => response.json())
                    .then(json => {
                        this.$refs.btnEnviarCodigo.style.pointerEvents = "all";
                        this.$refs.btnEnviarCodigo.style.opacity = 1;
                        console.log(json)
                        if(json.estado){
                            document.getElementById("p6-IniciarSesion-btnEnviarCodigo").style.display = "none";
                            document.getElementById("p6-IniciarSesion-pCodigo").style.display = "flex";
                            document.getElementById("p6-IniciarSesion-inputCodigo").style.display = "flex";
                            document.getElementById("p6-IniciarSesion-btnIniciar").style.display = "block";
                            this.provisional(json.mensajes.split("generado: ")[1]);
                        }else if(json.errores[0] == "Parece que no hay un usuario con esos parametros."){
                            document.getElementById("access-info").textContent = "No existe ninguna cuenta con ese correo electrónico.";
                            document.getElementById("access-info").style.color = "red";
                            document.getElementById("access-info").style.display = "flex";
                        }
                    })
                }
            }
            if(step_ == "Acceder"){
                let email = document.getElementById("p6IniciarSesion-email").value;
                let inputCodigo = document.getElementById("p6IniciarSesion-codigo").value;
                console.log("codigoIngresado:", inputCodigo)
                // validate verification code... {}
                fetch('https://locacionbackoffice.ideeo.mx/api/auth/verificarCodigo', {
                    method: 'POST',
                    body: JSON.stringify({
                        tipo_sesion: "E",
                        usuario: email,
                        codigo: inputCodigo,
                    }),
                    headers: {
                        "Content-type": "application/json"
                    }})
                .then(response => response.json())
                .then(json => {
                    console.log(json)
                    if(json.estado){
                        localStorage.setItem("onSession", true);
                        localStorage.setItem("userData", JSON.stringify(json));
                        this.$parent.setVisualData();
                        localStorage.removeItem('preserveUnsavedData');
                        this.goTo('subir-locacion');
                    }else{
                        document.getElementById("access-info").textContent = json.errores[0];
                        document.getElementById("access-info").style.color = "red";
                        document.getElementById("access-info").style.display = "flex";
                    }
                })
            }
        },

        goTo(section_){
            window.location.hash = "/"+section_;
            if (localStorage.getItem('onSession') === 'true') {
                let userData = JSON.parse(localStorage.getItem('userData'));
                console.log(userData)
                setTimeout(() => {
                    document.querySelectorAll('.navProfile').forEach(element => element.style.display = "flex");
                    document.querySelectorAll('.navAccount').forEach(element => element.style.display = "none");
                    document.querySelectorAll('.nav-username').forEach(username => username.textContent = userData.user.S_Nombre);
                    document.querySelectorAll('.nav-userpic').forEach(userpic => userpic.src =userData.user.S_URLFotoPerfil);
                    console.log("cambiado")
                }, 10);
            }
            setTimeout(() => { window.scrollTo(0, 0); }, 10);
            if(section_ == "aviso" || section_ == "terminos"){
                this.switchMode('IniciarSesion');
            }
        },

        processCrearCuenta(step_){
            let nombre = document.getElementById("p6CrearCuenta-nombre");
            let apellidos = document.getElementById("p6CrearCuenta-apellidos");
            let email = document.getElementById("p6CrearCuenta-email");
            let celular = document.getElementById("p6CrearCuenta-celular");
            let telFijo = document.getElementById("p6CrearCuenta-fijo");

            if (nombre.value.trim() === "") {
                nombre.style.border = "1px solid red";
                return;
            }
            if (apellidos.value.trim() === "") {
                apellidos.style.border = "1px solid red";
                return;
            }
            if (email.value.trim() === "" || !this.regexEmail.test(email.value)) {
                email.style.border = "1px solid red";
                return;
            }
            if (celular.value.trim() === "" || !this.regexPhone.test(celular.value)) {
                celular.style.border = "1px solid red";
                return;
            }
            nombre.style.border = "1px solid gray";
            apellidos.style.border = "1px solid gray";
            email.style.border = "1px solid gray";
            celular.style.border = "1px solid gray";

            if(step_ == "Registrarme"){
                this.$refs.btnRegistrarme.style.pointerEvents = "none";
                this.$refs.btnRegistrarme.style.opacity = 0.5;
                // Enviar datos...
                fetch('https://locacionbackoffice.ideeo.mx/api/usuarios', {
                    method: 'POST',
                    body: JSON.stringify({
                        S_Nombre: nombre.value,
                        S_Apellidos: apellidos.value,
                        tw_roles_id:4,
                        email: email.value,
                        password:"secret10",
                        N_Activo:1,
                        S_URLFotoPerfil:"https://cdn.iconscout.com/icon/free/png-256/user-placeholder-866235.png",
                        N_TelWhatsapp: celular.value,
                        N_TelCasa: telFijo.value,
                        S_TipoLocatario:"propietario",
                        S_URLIdentificacion:"",
                        S_URLlinked:null,
                        S_URLfacebook:null,
                        S_URLinstagram:null,
                        S_URLweb:null,
                        N_AceptaPublicidad:1
                    }),
                    headers: {
                        "Content-type": "application/json"
                    }})
                .then(response => response.json())
                .then(json => {
                    console.log(json)
                    //alert(JSON.stringify(json))
                    if(json.estado){
                        document.getElementById("access-info").textContent = "La cuenta ha sido creada correctamente";
                        document.getElementById("access-info").style.color = "green";
                        document.getElementById("access-info").style.display = "flex";
                        this.switchMode('IniciarSesion');
                    } else if(json.errores[0] == "El campo email ya ha sido tomado."){
                        this.$refs.btnRegistrarme.style.pointerEvents = "all";
                        this.$refs.btnRegistrarme.style.opacity = 1;
                        document.getElementById("access-info").textContent = "Ya existe una cuenta con ese correo electrónico.";
                        document.getElementById("access-info").style.color = "red";
                        document.getElementById("access-info").style.display = "flex";
                    }
                })
                // Enviar código...
                //document.getElementById("PopUp-validacion").style.display = "flex";
            }

            if(step_ == "Validar"){ console.log("validar codigo...") }
        },

        closePopUp(){
            document.getElementById("PopUp-validacion").style.display = "none";
        },

        // eslint-disable-next-line no-unused-vars
        provisional(code_){
            console.log("provisional:");
            //code_ = parseInt(code_);
            //element("#p6IniciarSesion-codigo").value = code_;
            //element("#p6-IniciarSesion-btnIniciar").click();
        }

    },

    mounted(){
        this.switchMode("IniciarSesion");
        
        if (localStorage.getItem('onSession') === 'true') {
            window.location.hash = "/";
            let userData = JSON.parse(localStorage.getItem('userData'));
            //console.log(userData)
            setTimeout(() => {
            document.querySelectorAll('.navProfile').forEach(element => element.style.display = "flex");
            document.querySelectorAll('.navAccount').forEach(element => element.style.display = "none");
            document.querySelectorAll('.nav-username').forEach(username => username.textContent = userData.user.S_Nombre);
            document.querySelectorAll('.nav-userpic').forEach(userpic => userpic.src =userData.user.S_URLFotoPerfil);
            console.log("cambiado")
            }, 10);
        }
        setTimeout(() => { window.scrollTo(0, 0); }, 10);
    }
}
</script>

<style scoped>
.InicioSesionCrearCuenta-MAIN{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-color: #F4F4F4;
}

.InicioSesionCrearCuenta-MAIN > div{
    display: flex;
    background-color: white;
    border-radius: 1ch;
    width: 45%;
    min-height: 80vh;
    padding-bottom: 1ch;
    height: fit-content;
    position: relative;
}

.InicioSesion-forms, .InicioSesion-forms > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.InicioSesion-forms{
    justify-content: flex-start;
    position: relative;
}

.regresar-arrow{
    position: absolute;
    left: 0;
    top: 0;
    margin: 2ch;
    width: 1vw;
    min-width: 1ch;
    cursor: pointer;
}

.InicioSesion-forms > img:not(.regresar-arrow){
    width: 16vw;
    margin-top: calc(6vh);
}

.InicioSesion-forms > div:nth-child(2){
    display: flex;
    flex-direction: row;
    width: 75%;
}

.InicioSesion-forms > div:nth-child(2) button{
    border: none;
    background-color: transparent;
    border-bottom: 2px solid lightgray;
    padding: 1ch 10%;
    font-size: 1.8ch;
    width: fit-content;
    white-space: nowrap;
    margin-top: 2ch;
    margin-bottom: 1ch;
}

.InicioSesion-forms > div:nth-child(2) button:hover{
    cursor: pointer;
    border-bottom: 2px solid gray;
}

.InicioSesion-banner{
    background-image: url("../assets/images/bgInicioSesion.png");
    background-position: center;
    background-size: cover;
}

button:not(.p6-switch-btn){
    background: linear-gradient(#FF4C0B, #FF0A46);
    color: white;
    padding: 2ch 4ch;
    font-size: 12px;
    border: none;
    border-radius: 100vw;
    text-transform: uppercase;
    min-width: 210px;
    font-weight: bold;
    margin-bottom: 2ch;
}

button:hover{
    cursor: pointer;
}

/* IniciarSesion | CrearCuenta */
.pantalla6-IniciarSesion, .pantalla6-CrearCuenta{
    display: flex;
    flex-direction: column;
    width: 44%;
}

.pantalla6-IniciarSesion > *:not(button), .pantalla6-CrearCuenta > *:not(button){
    width: 100%;
}

.pantalla6-IniciarSesion > div, .pantalla6-CrearCuenta > div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1ch;
    width: 100%;
}

.pantalla6-IniciarSesion input, .pantalla6-CrearCuenta input{
    padding: 1ch;
    width: 20vw;
    max-width: 20vw !important;
    border: 1px solid gray;
    border-radius: 5px;
}

.pantalla6-IniciarSesion img, .pantalla6-CrearCuenta img{
    max-width: 3ch;
    max-height: 3ch;
    width: 2ch;
    margin-left: -3ch;
    margin-right: 1ch;
    transform: scale(0.9);
}

.pantalla6-IniciarSesion p, .pantalla6-CrearCuenta p{
    font-weight: bolder;
}

#p6-IniciarSesion-pCodigo, #p6-IniciarSesion-inputCodigo, #p6-IniciarSesion-btnIniciar{
    display: none;
}

.pantalla6-CrearCuenta{
    margin-top: 2ch;
}

/*input[type="email"]:invalid:not(:focus) {
    background-color: rgb(255, 230, 230);
}*/

input[type="email"]:invalid:focus{
    background-color: rgb(255, 230, 230);
}

input[type="email"]:valid:focus{
    background-color: white;
    border: 1px solid cyan;
}

input[type="email"]:valid{
    background-color: white;
    border: 1px solid gray !important;
}

.p6-info{
    color:rgb(46, 46, 46);
    text-align: center;
    font-size: 1ch;
    font-weight: 400 !important;
    margin-bottom: 0;
}

.p6-info a{
    font-weight: bolder;
    color:rgb(46, 46, 46);
    cursor: pointer;
}

.pantalla6-IniciarSesion button, .pantalla6-CrearCuenta button{
    margin-top: 3ch;
}

/* Pop up */
section{
    position: absolute;
    display: none;
}

.InicioSesion-close{
    display: none !important;
}

#PopUp-validacion{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    color: gray;
    display: none;
}

#PopUp-validacion > div{
    background-color: #F4F4F4;
    border-radius: 1ch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3vw 0;
    position: relative;
}

#PopUp-validacion > div > *{
    max-width: 80%;
    text-align: center;
}

#PopUp-validacion > div > div, #PopUp-validacion > div p:not(:nth-child(2)){
    display: flex;
    align-items: center;
}

#PopUp-validacion > div > p:nth-child(1){
    color: black;
    font-weight: bolder;
    font-size: 2ch;
    margin-bottom: 0;
}

#PopUp-validacion img{
    max-width: 2ch;
    max-height: 2ch;
    margin-right: 1ch;
}

#PopUp-validacion input{
    padding: 1ch;
    width: 20vw;
    max-width: 20vw !important;
    border: 1px solid gray;
    border-radius: 5px;
    margin-top: 3ch;
    margin-bottom: 2ch;

}

.p6-popup-closebutton{
    color: gray;
    position: absolute;
    border: 1px solid red;
    top: 1ch;
    right: 1ch;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 0ch;
    height: 0ch;
    font-size: 3ch;
    font-weight: 300;
    transform: rotate(45deg) scale(1.1);
    cursor: pointer;
}

#access-info{
    position: absolute;
    top: 1ch;
    display: none;
    max-width: 80%;
    text-align: center;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){
  
    .InicioSesion-banner{
        display: none !important;
    }

    .InicioSesion-close{
        display: none !important;
        background-color: transparent !important;
        height: 8vw !important;
        margin: 0 !important;
        padding:  0 !important;
    }

    .InicioSesionCrearCuenta-MAIN{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start !important;
        
    }

    #access-info{
        font-size: 3vw;
        margin-top: 1ch;
        max-width: 75%;
    }

    .InicioSesionCrearCuenta-MAIN > div{
        width: 85%;
        height: fit-content;
        margin-top: 8vw;
        margin-bottom: 1ch;
        padding-top: 3.5ch;
    }

    .regresar-arrow{
        width: 2.2vw !important;
    }

    .InicioSesion-forms{
        margin-bottom: 0 !important;
        min-height: unset !important;
    }

    .InicioSesion-forms > img:not(.regresar-arrow){
        width: 35vw !important;
        margin-top: 5vw;
    }

    .InicioSesion-forms > div:nth-child(2) button{
        padding: 2ch 5%;
        padding-top: 1ch;
        font-size: 4.4vw;
    }

    .pantalla6-CrearCuenta, .pantalla6-IniciarSesion{
        width: 85%;
    }

    .pantalla6-IniciarSesion input, .pantalla6-CrearCuenta input{
        width: calc(100% - 12vw);
        max-width: unset !important;
    }

    .p6-info{
        margin-bottom: 4vw;
    }

    #PopUp-validacion > div{
        max-width: 90%;
        padding: 12vw 0;
    }

    #PopUp-validacion > div > p:nth-child(1){
        font-size: 5vw;
    }

    #PopUp-validacion img{
        margin-left: -2vw;
    }
 
    #PopUp-validacion input{
        width: 55vw;
        max-width: unset !important;
    }
    

}
</style>
