<template> <!-- 8 -->
    <div class="PerfilUsuario-MAIN">

        <NavLocacion/>

        <div class="proximo-lanzamiento" ref="proximoLanzamiento" id="p2-proximoLanzamiento">
            <img src="../assets/images/icons/coming-soon.svg"> Próximo lanzamiento para búsquedas
        </div>

        <div class="close-sesion"><button @click="closeSession()">Cerrar sesión</button></div>

        <p class="MiPerfil" id="MiPerfil-txt">Mi perfil</p>

        <div class="p8-aside-cel">
            <p @click="switchSection(1)" id="perfil-perfil-btn">Perfil<span><img src="../assets/images/icons/arrow.svg"/></span></p>
            <hr>
            <p @click="switchSection(2)">Mis Locaciones<span><img src="../assets/images/icons/arrow.svg"/></span></p>
            <hr>
            <p @click="switchSection(3)">Mis Chats<span><img src="../assets/images/icons/arrow.svg"/></span></p>
            <hr>
            <p @click="switchSection(4)">Mis Visitas<span><img src="../assets/images/icons/arrow.svg"/></span></p>
            <hr>
            <p @click="switchSection(5)">Favoritos<span><img src="../assets/images/icons/arrow.svg"/></span></p>
            <hr>
            <p @click="switchSection(6)">Datos Bancarios<span><img src="../assets/images/icons/arrow.svg"/></span></p>
        </div>

        <section>
            <aside>
                <p id="pantalla8-aside-menu1" v-on:click="switchSection(1)">Perfil</p>
                <div id="pantalla8-submenu1"></div>
                <p id="pantalla8-aside-menu2" v-on:click="switchSection(2)">Mis Locaciones</p>
                <div id="pantalla8-submenu2">
                    <p @click="filterLocations('activas')"><button id="pantalla8-menu-activas" class="btn-inactive">Activas</button></p>
                    <p @click="filterLocations('en_espera')"><button id="pantalla8-menu-en_espera" class="btn-inactive">En espera</button></p>
                </div>
                <p id="pantalla8-aside-menu3" v-on:click="switchSection(3)">Mis Chats</p>
                <div id="pantalla8-submenu3"></div>
                <p id="pantalla8-aside-menu4" v-on:click="switchSection(4)">Mis Visitas</p>
                <div id="pantalla8-submenu4"></div>
                <p id="pantalla8-aside-menu5" v-on:click="switchSection(5)">Favoritos</p>
                <div id="pantalla8-submenu5"></div>
                <p id="pantalla8-aside-menu6" v-on:click="switchSection(6)">Datos Bancarios</p>
                <div id="pantalla8-submenu6"></div>
                <img src=""/>
            </aside>

            <div id="pantalla8-components">
                <PerfilUsuario1perfil id="pantalla8-step1"/>
                <PerfilUsuario2misLocaciones id="pantalla8-step2" ref="childComponentRef"/>
                <PerfilUsuarios6datosBancarios id="pantalla8-step6"/>
            </div>
        </section>

        <FooterLocacion/>
    </div>
</template>

<script>
import { element } from 'alexa-reactor-core';
/* eslint-disable vue/no-unused-components */
import FooterLocacion from './FooterLocacion.vue'
import NavLocacion from './NavLocacion.vue'
import PerfilUsuario1perfil from './PerfilUsuario1perfil.vue';
import PerfilUsuario2misLocaciones from './PerfilUsuario2misLocaciones.vue';
import PerfilUsuarios6datosBancarios from './PerfilUsuarios6datosBancarios.vue';
export default {
    components: { NavLocacion, FooterLocacion, PerfilUsuario1perfil, PerfilUsuario2misLocaciones, PerfilUsuarios6datosBancarios },
    name: 'PerfilUsuario',
    methods:{

        switchSection(step_, hideCel_=true){
            if(step_ == 3 || step_ == 4 || step_ == 5){
                let popUp = this.$refs.proximoLanzamiento;
                popUp.style.display = "flex";
                popUp.classList.remove("animate-popup");
                void popUp.offsetWidth;
                popUp.classList.add("animate-popup");
                return;
            }

            document.getElementById("pantalla8-step1").style.display = "none";
            document.getElementById("pantalla8-step2").style.display = "none";
            document.getElementById("pantalla8-step6").style.display = "none";
            document.getElementById("pantalla8-aside-menu1").style.fontWeight = "400";
            document.getElementById("pantalla8-aside-menu2").style.fontWeight = "400";
            document.getElementById("pantalla8-aside-menu3").style.fontWeight = "400";
            document.getElementById("pantalla8-aside-menu4").style.fontWeight = "400";
            document.getElementById("pantalla8-aside-menu5").style.fontWeight = "400";
            document.getElementById("pantalla8-aside-menu6").style.fontWeight = "400";
            document.getElementById("pantalla8-aside-menu1").style.borderBottom = "2px solid transparent";
            document.getElementById("pantalla8-aside-menu2").style.borderBottom = "2px solid transparent";
            document.getElementById("pantalla8-aside-menu3").style.borderBottom = "2px solid transparent";
            document.getElementById("pantalla8-aside-menu4").style.borderBottom = "2px solid transparent";
            document.getElementById("pantalla8-aside-menu5").style.borderBottom = "2px solid transparent";
            document.getElementById("pantalla8-aside-menu6").style.borderBottom = "2px solid transparent";
            document.getElementById("pantalla8-submenu1").style.maxHeight = "0px";
            document.getElementById("pantalla8-submenu2").style.maxHeight = "0px";
            document.getElementById("pantalla8-submenu3").style.maxHeight = "0px";
            document.getElementById("pantalla8-submenu4").style.maxHeight = "0px";
            document.getElementById("pantalla8-submenu5").style.maxHeight = "0px";
            document.getElementById("pantalla8-submenu6").style.maxHeight = "0px";
            document.getElementById(`pantalla8-aside-menu${step_}`).style.fontWeight = "bolder";
            document.getElementById(`pantalla8-aside-menu${step_}`).style.borderBottom = "2px solid #FF282A";
            document.getElementById(`pantalla8-submenu${step_}`).style.maxHeight = "500px";
            if(hideCel_){
                document.getElementById("pantalla8-components").style.display = "flex";
                document.querySelector(".p8-aside-cel").style.display = "none";
            }
            try {
                document.getElementById(`pantalla8-step${step_}`).style.display = "flex";
            } catch (error) {
                console.log(error);
            }

            if(step_ == 2)
                setTimeout(() => {
                    this.filterLocations("activas")
                }, 80);

            if(step_ == 6){
                element("#MiPerfil-txt").innerHTML = "Datos Bancarios";
            }else{
                element("#MiPerfil-txt").innerHTML = "Mi Perfil";
            }
        },

        closeSession(){
            window.location.hash = "/";
            localStorage.clear();
            sessionStorage.removeItem("accessCode");
            sessionStorage.clear();
            setTimeout(() => {
                window.location.reload();
            }, 80);
        },

        filterLocations(filter_){
            this.$refs.childComponentRef.filterLocations(filter_);
            element("#pantalla8-menu-activas").classList.add("btn-inactive");
            element("#pantalla8-menu-activas").classList.remove("btn-active");
            element("#pantalla8-menu-en_espera").classList.add("btn-inactive");
            element("#pantalla8-menu-en_espera").classList.remove("btn-active");
            element(`#pantalla8-menu-${filter_}`).classList.remove("btn-inactive");
            element(`#pantalla8-menu-${filter_}`).classList.add("btn-active");
        }

    },

    mounted(){
        document.getElementById(`pantalla8-aside-menu1`).style.fontWeight = "bolder";
        document.getElementById(`pantalla8-aside-menu1`).style.borderBottom = "2px solid #FF282A";
        document.getElementById(`pantalla8-submenu1`).style.maxHeight = "500px";
        this.switchSection(1, false);
    }
}
</script>

<style>
.PerfilUsuario-MAIN{
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.PerfilUsuario-MAIN section{
    display: flex;
    justify-content: space-evenly;
    border-radius: 2ch;
    background-color: white;
    width: 85%;
    margin-bottom: 5ch;
    padding-bottom: 2ch;
}

.MiPerfil{
    margin-top: 2ch;
    font-size: 2.5ch;
    font-weight: bolder;
}

/* left side bar */
.PerfilUsuario-MAIN section > aside{
    width: calc(25% - 5ch);
}
.PerfilUsuario-MAIN section > div{
    width: calc(70% - 5ch);
}

.PerfilUsuario-MAIN section > aside > p{
    border-bottom: 2px solid transparent;
}

.PerfilUsuario-MAIN section > aside > p:hover{
    cursor: pointer;
    border-bottom: 2px solid lightgray !important;
}

.PerfilUsuario-MAIN section > aside > div p:hover{
    color: black;
    cursor: pointer;
    text-shadow: 0px 0px 1px black;
}

.PerfilUsuario-MAIN section > aside > p{
    font-size: 2ch;
    width: fit-content;
    padding-bottom: 0.5ch;
    margin-top: 2ch;
    margin-bottom: 0ch;
}

.PerfilUsuario-MAIN section > aside > div > p{
    font-size: 1.2ch;
    color: rgb(98, 98, 98);
}


#pantalla8-submenu1, #pantalla8-submenu2, #pantalla8-submenu3{
    max-height: 0px;
    transition: all 0.2s;
    overflow: hidden;
}

/* Component styles */
.PerfilUsuario1perfil-MAIN > p{
    display: flex;
    align-items: center;
    font-size: 1.7ch;
    margin-top: 2.5ch;
}

.PerfilUsuario1perfil-MAIN > p img{
    margin-right: 1ch;
    max-height: 2ch;
    filter: grayscale(1);
}

.PerfilUsuario1perfil-MAIN select{
    width: 100%;
    padding: 1ch;
    border: 1px solid gray;
    border-radius: 5px;
}

.PerfilUsuario1perfil-MAIN input:not(input[type=file]), .PerfilUsuario1perfil-MAIN textarea,
.PerfilUsuario6datosBancarios-MAIN input:not(input[type=file]), .PerfilUsuario6datosBancarios-MAIN textarea{
    padding: 1ch;
    border: 1px solid gray;
    border-radius: 5px;
}

.p8-min-txt{
    font-size: 1.2ch !important;
    text-align: justify;
    flex-direction: column;
}

.SubirLocacion1caracteristicas-MAIN button, .PerfilUsuario6datosBancarios-MAIN button, .PerfilUsuario1perfil-MAIN button{
    background: linear-gradient(#FF4C0B, #FF0A46);
    color: white;
    padding: 2ch 4ch;
    font-size: 12px;
    border: none;
    border-radius: 100vw;
    text-transform: uppercase;
    min-width: 210px;
    font-weight: bold;
    margin-bottom: 2ch;
    width: fit-content;
    align-self: center;
    margin-top: 3ch;
}

button:hover{
    cursor: pointer;
}

.p8-aside-cel{
    display: none;
}

.close-sesion{
    width: 80%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: -2ch;
    margin-top: 1ch;
}

.close-sesion > button{
    border: none;
    background-color: transparent;
    text-decoration: underline;
}

.close-sesion > button:hover{
    color: red;
}


.proximo-lanzamiento{
    position: fixed;
    background-color: #FF282A;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1ch 0 0 1ch;
    z-index: 3;
    margin-top: 8%;
    right: 0;
    padding: 1.5ch;
    display: none;
    box-shadow: 0 1ch  1ch rgba(50, 50, 50, 0.4);
}

.proximo-lanzamiento > img{
    filter: brightness(0) invert(1);
    width: 3ch;
    margin-right: 1ch;
}

.animate-popup{
    animation-duration: 6s;
    animation-fill-mode: forwards;
    animation-name: proximoLanzamiento;
}

@keyframes proximoLanzamiento {
    0%{
        opacity: 1;
        right: -100%;
    }

    5%{
        right: 0;
    }
    
    90%{
        opacity: 1;
    }

    100%{
        opacity: 0;
    }
}

.btn-active{
    background: linear-gradient(#FF4C0B, #FF0A46);
    color: white;
    padding: 1.5ch;
    border: none;
    border-radius: 100vw;
    text-transform: uppercase;
    font-weight: bold;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: 1ch;
    min-width: 18ch;
}

.btn-inactive{
    background-color: white;
    color: gray;
    padding: 1.5ch;
    border: 2px solid #FF4C0B !important;
    border-radius: 100vw;
    text-transform: uppercase;
    font-weight: bold;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: 1ch;
    min-width: 18ch;   
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    .PerfilUsuario-MAIN section aside{
        display: none;
    }

    .PerfilUsuario-MAIN section > div{
        width: 95%;
    }

    .p8-aside-cel{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border-radius: 4vw;
        width: 85vw;
        padding: 2ch 0;
    }

    .p8-aside-cel > p{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .p8-aside-cel > p img{
        height: 1.5ch;
        transform: rotate(180deg);
    }

    .p8-aside-cel > hr{
        width: 90%;
        margin: 0;
        border: 1px solid transparent;
        border-top: 1px solid lightgray;
    }

    #pantalla8-components{
        display: none;
    }

    .close-sesion{
        width: 90%;
    }

    
    .proximo-lanzamiento{
        position: fixed;
        background-color: #FF282A;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        z-index: 3;
        margin-top: 0;
        width: 100%;
        right: unset;
        left: unset;
        padding: 1.5ch 0;
        display: none;
        box-shadow: 0 1ch  1ch rgba(50, 50, 50, 0.4);
    }

    .proximo-lanzamiento > img{
        display: none;
    }

    .animate-popup{
        animation-duration: 6s;
        animation-fill-mode: forwards;
        animation-name: proximoLanzamiento;
    }

    @keyframes proximoLanzamiento {
        0%{
            opacity: 1;
        }
        
        90%{
            opacity: 1;
        }

        100%{
            opacity: 0;
        }
    }

    
    .btn-active{
        padding: 1.2ch;
        min-width: 14ch;
        margin: 0;
        margin-bottom: 2ch;
    }

    .btn-inactive{
        padding: 1.2ch;
        min-width: 14ch;
        margin: 0; 
        margin-bottom: 2ch;  
    }

}
</style>