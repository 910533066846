<template>
    <main class="BuscoEstoView-MAIN">
        <NavLocacion/>

        <section>
            <div>
                <div class="BuscoEstoView-imgs">
                    <img src="../assets/images/icons/arrow-small.svg" class="BuscoEstoView-btnL" @click="scrollCarrousel(-1)">
                    <div ref="BuscoEstoViewImgContainer">
                        <img :src="targetBuscoEstoData.url_foto1" v-if="targetBuscoEstoData.url_foto1 !== '' && targetBuscoEstoData.url_foto1 != null">
                        <img :src="targetBuscoEstoData.url_foto2" v-if="targetBuscoEstoData.url_foto2 !== '' && targetBuscoEstoData.url_foto2 != null">
                        <img :src="targetBuscoEstoData.url_foto3" v-if="targetBuscoEstoData.url_foto3 !== '' && targetBuscoEstoData.url_foto3 != null">
                    </div>
                    <img src="../assets/images/icons/arrow-small.svg" class="BuscoEstoView-btnR" @click="scrollCarrousel(+1)">
                </div>
                <div class="BuscoEstoView-titles">
                    <h3><img :src="targetBuscoEstoData.S_URLFotoPerfil">{{ targetBuscoEstoData.titulo }}</h3>
                    <p>*Imágenes de referencia, pueden ser reales o ficticias.</p>
                </div>
                <p class="ver-galeria" @click="gallery.active = true">Ver Galería</p>
                <h4>{{ targetBuscoEstoData.titulo }}</h4>
                <p>{{ targetBuscoEstoData.descripcion }}</p>
            </div>
            
            <div>
                <div class="pre-BuscoEsto" v-if="!dataSent">
                    <p><img src="../assets/images/icons/house-building.svg"> Si quieres postular tu locación, coloca la clave que tiene tu anuncio:</p>
                    <div>
                        <img :src="typeof userData?.user != 'undefined' ? userData?.user?.S_URLFotoPerfil : require('../assets/images/icons/userpic.svg')">
                        <input type="text" v-model="keyText" placeholder="Ejemplo: JFDRDF789">
                        <img src="../assets/images/enviar-btn.png" @click="sendKey()">
                    </div>
                    <p>Tu clave será enviada directamente a este solicitante y si le interesa, se podría contactar contigo en el chat del anuncio de esa locación.</p>
                </div>
                <div class="post-BuscoEsto" v-else>
                    <img :src="userData?.user?.S_URLFotoPerfil">
                    <h3>!Suerte!</h3>
                    <p><img src="../assets/images/icons/house-building.svg"><b>Esperamos que elijan tu locación.</b></p>
                    <p>Tu clave se ha enviado directamente a este solicitante. Si le interesa, podrá contactarte en el chat de tu anuncio.</p>
                </div>
            </div>
            <!--{{ targetBuscoEstoData }}-->
        </section>

        <section class="BuscoEstoView-gallery" v-if="gallery.active">
            <div class="close-BuscoEstoView-gallery" @click="gallery.active = false">+</div>
            <img :src="gallery.images[gallery.current]">
            <div>
                <img :src="gallery.images[0]" v-if="gallery.images[0]" @click="gallery.current = 0">
                <img :src="gallery.images[1]" v-if="gallery.images[1]" @click="gallery.current = 1">
                <img :src="gallery.images[2]" v-if="gallery.images[2]" @click="gallery.current = 2">
            </div>
        </section>
        <FooterLocacion/>
    </main>
</template>

<script>
import FooterLocacion from './FooterLocacion.vue'
import NavLocacion from './NavLocacion.vue'
export default {
    components: { NavLocacion, FooterLocacion },
    name: 'BuscoEstoView',
    data(){
        return{
            listBuscoEsto: [],
            targetBuscoEstoData: [],
            gallery: {
                active: false,
                images: [],
                current: 0,
            },
            targetId: 0,
            userData: JSON.parse(localStorage.getItem("userData")),
            dataSent: false,
            keyText: '',
            falseToken: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5OWM5OTE0Ny1jOTFkLTQyYTUtYjBmZi0yOGRmZmJlMGRkMDgiLCJqdGkiOiJjMjNhZWM4ODlkN2ZiYTIxY2EyOTI0NjFmN2ZmZDUzN2Y3MjdlYmU4M2I2N2IwNTdlNzNkZTAwNTc0YzE5YzVhYzdkYmIwYmY0YjU4ZGZjZSIsImlhdCI6MTcyMzI0NzUwMS4wMjI5MzMsIm5iZiI6MTcyMzI0NzUwMS4wMjI5MzYsImV4cCI6MTc1NDc4MzUwMS4wMTc3NjYsInN1YiI6IjcyIiwic2NvcGVzIjpbIkNSRUFURSIsIlJFQUQiLCJVUERBVEUiLCJERUxFVEUiXX0.a356A74j7nQrUqMRY0CO7WyYgRBV1nsJdwpkk3TvI-z3ZhYFxi1lUpSEb_0AxmY3789_0Vl1ASk9lgZjjbjOAxX56fxuNAfVCz0o-TJZk8iayauypGbXHW61uOOVkGZOp8hWWUqpKJV5nwZaAoXb3Z5Yi85PIEohrz_X4gOuNxHqDU0wn7XP468b4Ul74MY5SXODtrqD5HZhdkec-3gv6iloWy_evaN-FQDvPS8SUeXAXUOMHMF6Drht9K8cCi05xGURjD03q0CepJlAS5O8IJYp3P84i5B9M_kAQJEUDDjiQciw7HYZBvcu1Rtb1T0svbTyZy8CLr60GtVoyOrrgCXOD-5CIFLkITi4mtxAaJGPpDPjJTcNYLzmgJBHlNMO1g2MrJuoNaNeE_nmUXM1Cvn0xZHOVr1W8lpLdVeFmkiKwo1bd8WPZI_a_dsZwcves_dvdQjL4hiPr2LoLp17ysuYmZCp2bvbriSc8C5b1g3ylEMoFr7NWUvF26ubR-dplvSFyo3TtBG-ohnweIhccMmvkFXlvqNRSlpq7O1cMteJhfxcD4mlktENeJxVHqvfRflAivOj6AVJVRz_erkxUDrU9vzIXv8jgJMbYfQ_qP29Vinh2HbwbjB_Q8tk2JYaUNQ6gvWiB_3Tlb27GYOcJI6cRLMyR4ZI4Mr0MHVDonQ",
        }
    },
    methods:{
        getTargetId(){
            const urlParams = new URLSearchParams(window.location.search);
            const targetId = urlParams.get('id').replaceAll("/", "");
            this.targetId = targetId;
            console.log("targetId", targetId);
            for(let i of this.listBuscoEsto){
                if(parseInt(i.id) == parseInt(this.targetId)){
                    this.targetBuscoEstoData = i;
                    break;
                }
            }
            if(this.targetBuscoEstoData.url_foto1 !== '' && this.targetBuscoEstoData.url_foto1 != null) this.gallery.images.push(this.targetBuscoEstoData.url_foto1);
            if(this.targetBuscoEstoData.url_foto2 !== '' && this.targetBuscoEstoData.url_foto2 != null) this.gallery.images.push(this.targetBuscoEstoData.url_foto2);
            if(this.targetBuscoEstoData.url_foto3 !== '' && this.targetBuscoEstoData.url_foto3 != null) this.gallery.images.push(this.targetBuscoEstoData.url_foto3);
        },

        getList(){
            //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://dev-locacionbackoffice.ideeo.mx/api/lista-busquedas-urgentes', {method: 'GET',
                headers: {
                    "Content-type": "application/json",
                    "Authorization": this.falseToken //`Bearer ${accessToken}`
                }})
                .then(response => response.json())
                .then(json => {
                    console.log("lista de busco esto", json);
                    this.listBuscoEsto = json;
                    this.getTargetId();
                })
        },

        scrollCarrousel(direction_){
            const elem = this.$refs.BuscoEstoViewImgContainer;
            elem.scrollLeft += elem.offsetWidth * direction_;
        },

        sendKey(){
            if(typeof this.userData?.user == 'undefined'){
                console.log("No haz iniciado sesión")
                return;
            }

            const key = this.keyText.trim();
            if(key == ""){
                return;
            }

            console.log("sending key text");
            //fetch. If ok:
            //this.dataSent = true;
        }
    },

    mounted(){
        this.getList();
    }
}
</script>

<style scoped>
.BuscoEstoView-MAIN{
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.BuscoEstoView-MAIN > section:not(.BuscoEstoView-gallery){
    width: 90vw;
    margin-bottom: 2ch;
}

.BuscoEstoView-MAIN > section:not(.BuscoEstoView-gallery) > div{
    border-radius: 1ch;
    margin-bottom: 2ch;
    padding: 2ch 3ch;
    background-color: white;
    position: relative;
}

h4, .BuscoEstoView-MAIN > section:not(.BuscoEstoView-gallery) > div:nth-child(1) > p{
    color: #707070;
    text-align: left;
}

.BuscoEstoView-MAIN > section:not(.BuscoEstoView-gallery) > div:nth-child(1) > p{
    margin-top: 0;
    font-size: 1.3ch;
}

h4{
    margin-top: 3ch;
}

.BuscoEstoView-titles{
    position: absolute;
    top: 1ch;
    color: white;
    text-shadow: 0 0 1ch rgba(50,50,50,0.4);
}

.BuscoEstoView-titles > h3{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5ch;
}

.BuscoEstoView-titles > h3 img{
    width: 2ch;
    height: 2ch;
    object-fit: cover;
    object-position: center;
    border-radius: 100vw;
    margin-right: 1ch;
}

.BuscoEstoView-titles > p{
    margin-top: 0;
    margin-left: 4ch;
    font-size: 1.3ch;
    
    text-shadow: 0 0 1ch rgba(0,0,0,0.4);
}

.ver-galeria{
    position: absolute;
    left: 0;
    right: 0;
    top: 27vw;
    width: fit-content;
    margin: auto;
    color: white !important;
    text-shadow: 0 0 1ch rgba(0,0,0,0.5) !important;
    text-decoration: underline;
    cursor: pointer;
}

/* imgs */
.BuscoEstoView-imgs{
    display: flex;
    align-items: center;
    width: calc(100% + 6ch) !important;
    height: fit-content;
    margin-top: -2ch;
    margin-left: -3ch;
    position: relative;
}

.BuscoEstoView-imgs > div{
    overflow-x: auto;
    scrollbar-width: none;
    display: flex;
}

.BuscoEstoView-imgs > div > img{
    width: 90vw !important;
    min-width: 90vw !important;
    height: 30vw;
    object-fit: cover;
    object-position: top;
}

.BuscoEstoView-btnL, .BuscoEstoView-btnR{
    position: absolute;
    background-color: white;
    width: 1.5ch;
    height: 1.5ch;
    padding: 0.5ch;
    border-radius: 100vw;
}

.BuscoEstoView-btnL:hover, .BuscoEstoView-btnR:hover{
    cursor: pointer;
    scale: 1.05;
}

.BuscoEstoView-btnL{
    left: 4ch;
    rotate: 90deg;
}

.BuscoEstoView-btnR{
    right: 4ch;
    rotate: -90deg;
}

/* input part */
.pre-BuscoEsto p{
    display: flex;
    align-items: center;
}

.pre-BuscoEsto p > img{
    width: 2ch;
    margin-right: 1ch;
}

.pre-BuscoEsto > div{
    display: flex;
    align-items: center;
}

.pre-BuscoEsto > div > img:nth-child(1){
    width: 2.5ch;
    height: 2.5ch;
    object-fit: cover;
    object-position: center;
    margin-right: 1ch;
    border-radius: 100vw;
}

.pre-BuscoEsto input{
    width: 50%;
    padding: 1ch;
    border-radius: 5px;
    border: 1px solid gray;
    margin: 0 1ch;
}

.pre-BuscoEsto > div > img:nth-child(3){
    width: 14vw;
    cursor: pointer;
    margin-bottom: -0.2vw;
}

.pre-BuscoEsto > p:nth-child(3){
    font-size: 1ch;
    color: #4D4D4D;
}

.post-BuscoEsto{
    color: #4D4D4D;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.post-BuscoEsto > img{
    width: 6ch;
    height: 6ch;
    object-fit: cover;
    object-position: center;
    border-radius: 100vw;
    margin-top: 1ch;
}

.post-BuscoEsto h3{
    margin-top: 1ch;
    margin-bottom: 0.6ch;
    font-size: 2.5ch;
}

.post-BuscoEsto p{
    margin-top: 0;
    margin-bottom: 0.5ch;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.2ch;
}

.post-BuscoEsto p img{
    width: 2ch;
    margin-right: 1ch;
}

/* gallery */
.BuscoEstoView-gallery{
    width: 100%    ;
    height: 100vh    ;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 8;
}

.close-BuscoEstoView-gallery{
    position: absolute;
    top: 0.7ch;
    right: 0.7ch;
    color: white;
    font-size: 4ch;
    border-radius: 100vw;
    rotate: 45deg;
    width: 1ch;
    height: 1ch;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.close-BuscoEstoView-gallery:hover{
    cursor: pointer;
    scale: 1.05;
}

.BuscoEstoView-gallery > img{
    max-width: 75vw;
    max-height: 75vh;
    margin-top: -4ch;
}

.BuscoEstoView-gallery > div:nth-child(3){
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 1ch;
}

.BuscoEstoView-gallery > div:nth-child(3) > img{
    max-height: 10vh;
    margin: 0 0.5ch;
    border-radius: 1ch;
}


.BuscoEstoView-gallery > div:nth-child(3) > img:hover{
    cursor: pointer;
    scale: 1.05;
}
</style>