<template> <!-- 3 Pantalla Sube tu Locación. Usuario sin cuenta creada -->
    <div id="SubirLocacionNoSession-MAIN">
        <NavLocacion :currentSection="'Sube tu locacion'"/>

        <section class="pantalla3-banner">
            <p>Sube tu locación, sin comisión por proyecto y te pagamos por visita.</p>
            <!--<p>¡Comienza a ganar con nosotros!</p>--><span></span>
            <button v-on:click="goTo('crear-cuenta')">Subir locación</button>
            <p>¡Haz de tu locación un nuevo negocio!</p>
        </section>

        <section class="pantalla3-video">
            <video controls autoplay>
                <source src="../assets/videos/locacion-bienvenido.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </section>

        <section class="pantalla3-info">
            <div>
                <div>
                    <img src="../assets/images/icons/house-marker.svg"/>
                    ¿Cuáles son las ventajas de rentar mi espacio en Locación?
                </div>
                <ol>
                    <li>¡Es totalmente gratis!</li>
                    <li>Chatea directo con clientes.</li>
                    <li>No cobramos comisiones por proyecto.</li>
                    <li>¡Te pagamos por cada visita registrada!</li>
                    <li>Nuestra plataforma es segura, por lo que tus datos y los de tu locación estarán siempre protegidos.</li>
                </ol>
            </div>
            <button v-on:click="goTo('crear-cuenta')">Subir locación</button>
        </section>

        <section class="pantalla3-descubre-eventos">
            <p><strong>Descubre todos los eventos que puedes ofrecer en tu Locación.</strong></p>
            <p>Tu imaginación es el límite.</p>
            <img src="../assets/images/icons/arrow.svg" class="slider-arrow-L" @click="moveSlider(-1)"/>
            <img src="../assets/images/icons/arrow.svg" class="slider-arrow-R" @click="moveSlider(1)"/>
            <div class="pantalla3-slider" id="pantalla3-slider">
                <div>
                    <div><img src="../assets/images/carrusel-3/1.png"/><p>Eventos sociales</p></div>
                    <div><img src="../assets/images/carrusel-3/2.png"/><p>Eventos de Marca</p></div>
                    <div><img src="../assets/images/carrusel-3/3.png"/><p>Shoots</p></div>
                    <div><img src="../assets/images/carrusel-3/4.png"/><p>Red Carpet</p></div>
                    <div><img src="../assets/images/carrusel-3/5.png"/><p>Entrevistas</p></div>
                    <div><img src="../assets/images/carrusel-3/6.png"/><p>Clases</p></div>
                    <div><img src="../assets/images/carrusel-3/7.png"/><p>Lanzamiento de producto</p></div>
                    <div><img src="../assets/images/carrusel-3/8.png"/><p>Capacitaciones</p></div>
                    <div><img src="../assets/images/carrusel-3/9.png"/><p>Cenas exclusivas</p></div>
                    <div><img src="../assets/images/carrusel-3/10.png"/><p>Eventos de Prensa</p></div>
                    <div><img src="../assets/images/carrusel-3/11.png"/><p>Ventas exclusivas</p></div>
                    <div><img src="../assets/images/carrusel-3/12.png"/><p>Boda</p></div>
                    <div><img src="../assets/images/carrusel-3/13.png"/><p>Ventas cerradas</p></div>
                    <div><img src="../assets/images/carrusel-3/14.png"/><p>Showroom</p></div>
                    <div><img src="../assets/images/carrusel-3/15.png"/><p>Lanzamientos</p></div>
                    <div class="slider-last-item"><img src="../assets/images/carrusel-3/16.png"/><p>Presentaciones influencers</p></div>
                </div>
            </div>

        </section>

        <section class="pantalla3-final-info">
            <div class="p3-final-info-pc">
                <img src="../assets/images/icons/marker.svg"/>
                <div>
                    <p>¿Quieres saber más?</p>
                    <p>Conoce nuestras preguntas frecuentes:</p>
                </div>
                <img src="../assets/images/icons/building.svg"/>
            </div>
            <div class="p3-final-info-cel">
                <div>
                    <p class="tu-imaginacion-limite"><strong>Descubre todos los eventos que puedes ofrecer en tu Locación.</strong></p>
                    <p class="tu-imaginacion-limite">Tu imaginación es el límite.</p>
                    <div class="pantalla3-slider-cel">
                        <div>
                            <div><img src="../assets/images/carrusel-3/1.png"/><p>Eventos sociales</p></div>
                            <div><img src="../assets/images/carrusel-3/2.png"/><p>Eventos de Marca</p></div>
                            <div><img src="../assets/images/carrusel-3/3.png"/><p>Shoots</p></div> 
                            <div><img src="../assets/images/carrusel-3/4.png"/><p>Red Carpet</p></div>
                            <div><img src="../assets/images/carrusel-3/5.png"/><p>Entrevistas</p></div>
                            <div><img src="../assets/images/carrusel-3/6.png"/><p>Clases</p></div>
                            <div><img src="../assets/images/carrusel-3/7.png"/><p>Lanzamiento de producto</p></div>
                            <div><img src="../assets/images/carrusel-3/8.png"/><p>Capacitaciones</p></div>
                            <div><img src="../assets/images/carrusel-3/9.png"/><p>Cenas exclusivas</p></div>   
                            <div><img src="../assets/images/carrusel-3/10.png"/><p>Eventos de Prensa</p></div>
                            <div><img src="../assets/images/carrusel-3/11.png"/><p>Ventas exclusivas</p></div>
                            <div><img src="../assets/images/carrusel-3/12.png"/><p>Boda</p></div>
                            <div><img src="../assets/images/carrusel-3/13.png"/><p>Ventas cerradas</p></div>
                            <div><img src="../assets/images/carrusel-3/14.png"/><p>Showroom</p></div>
                            <div><img src="../assets/images/carrusel-3/15.png"/><p>Lanzamientos</p></div>
                            <div><img src="../assets/images/carrusel-3/16.png"/><p>Presentaciones influencers</p></div>
                        </div>
                    </div>
                </div>
                <div>
                    <img src="../assets/images/icons/marker.svg"/>
                    <img src="../assets/images/icons/building.svg"/>
                </div>
                <div>
                    <p>¿Quieres saber más?</p>
                    <p>Averigua todo sobre Locación</p>
                </div>
            </div>
            <button v-on:click="goTo('preguntas')">Aquí</button>
        </section>

        <FooterLocacion class="p3-footer"/>
    </div>
</template>

<script>
import NavLocacion from './NavLocacion.vue';
import FooterLocacion from './FooterLocacion.vue';
import { htjs } from "alexa-reactor-core";
export default {
    components: { FooterLocacion, NavLocacion },
    name: 'SubirLocacionNoSession',
    methods:{

        goTo(section_){
            const newURL = window.location.origin + window.location.pathname;
            history.replaceState({}, document.title, newURL);
            window.location.hash = "/"+section_;
            if (localStorage.getItem('onSession') === 'true') {
                let userData = JSON.parse(localStorage.getItem('userData'));
                //console.log(userData)
                setTimeout(() => {
                document.querySelectorAll('.navProfile').forEach(element => element.style.display = "flex");
                document.querySelectorAll('.navAccount').forEach(element => element.style.display = "none");
                document.querySelectorAll('.nav-username').forEach(username => username.textContent = userData.user.S_Nombre);
                document.querySelectorAll('.nav-userpic').forEach(userpic => userpic.src =userData.user.S_URLFotoPerfil);
                console.log("cambiado")
                }, 10);
            }
            setTimeout(() => { window.scrollTo(0, 0); }, 10);
        },

        moveSlider(direction_){
            const sliderContainer = document.getElementById("pantalla3-slider");
            const newScrollLeft = sliderContainer.scrollLeft + direction_ * sliderContainer.clientWidth;
            sliderContainer.scrollLeft = newScrollLeft;
        }

    },
    mounted(){
        htjs();
    }
}
</script>

<style scoped>
#SubirLocacionNoSession-MAIN{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F4F4F4;
    overflow-x: hidden;
}

.pantalla3-info, .pantalla3-video, .pantalla3-final-info, .pantalla3-descubre-eventos{
    border-radius: 1ch;
    width: 90%;
    background-color: white;
    margin: 2ch 0;
    position: relative;
}

.pantalla3-banner{
    width: 100%;
    margin-top: 0;
    font-size: 2vw;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/images/bg2.png");
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 0ch 0ch 0.5ch rgba(0, 0, 0, 0.25);
    height: 45vh;
    min-height: fit-content !important;
    min-height: 30vw !important;
    margin-bottom: 1ch;
}

.pantalla3-banner > p:nth-child(1){
    margin-bottom: 5ch;
    margin-top: 2ch;
    width: 50%;
    text-align: center;
    line-height: 2.5ch;
}

.pantalla3-banner > p:nth-child(2), .pantalla3-banner > p:nth-child(4){
    font-size: 1.2vw;
    font-weight: 500;
}

.pantalla3-banner > p:nth-child(4){
    font-style: italic;
}

.pantalla3-banner > button{
    margin: 2ch;
    margin-bottom: 1ch;
}

button{
    background: linear-gradient(#FF4C0B, #FF0A46);
    color: white;
    padding: 2ch 4ch;
    font-size: 15px;
    border: none;
    border-radius: 100vw;
    text-transform: uppercase;
    width: 230px;
}

button:hover{
    cursor: pointer;
}

.pantalla3-info{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.pantalla3-info > div{
    display: flex;
    justify-content: center;
}

.pantalla3-info > div > *{
    width: 43%;
    margin-top: 1.8ch;
}

.pantalla3-info ol{
    margin-top: 10vw;
    margin-bottom: 0;
}

.pantalla3-info > div > div{
    display: flex;
    flex-direction: column;
    font-size: calc(2vw + 1ch);
    font-weight: bolder;
}

.pantalla3-info > div > div img{
    width: 2ch;
    margin-bottom: 1.5ch;
}

.pantalla3-info li{
    font-size: 1.7ch;
    margin: 0.5ch 0;
    font-weight: 400;
}

.pantalla3-info li::marker{
    font-weight: bolder;
}

.pantalla3-info button{
    margin-top: 3ch;
    margin-bottom: 4ch;
}

.pantalla3-video{
    background-color: black;
    overflow: hidden;
}

.pantalla3-video > video{
    width: 100%;
}

.pantalla3-descubre-eventos{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding-top: 3ch;
}

.pantalla3-descubre-eventos p{
    font-size: 1.8ch;
    margin: 0.5ch;
    color: #4d4d4d;
}

.pantalla3-slider{
    width: 100vw;
    margin-top: 5ch;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
}

.pantalla3-slider > div {
    display: flex;
    align-items: center;
    width: calc(100vw * 5);
}

.pantalla3-slider > div > div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.pantalla3-slider > div> div > div{
    display: inherit;
    flex-direction: column;
    align-items: center;
}

.pantalla3-slider img{
    width: 22vw;
}

.pantalla3-slider p{
    font-weight: bolder;
    margin-top: 1ch;
}

.slider-arrow-L, .slider-arrow-R{
    position: absolute;
    background-color: white;
    width: 3ch !important;
    height: 3ch;
    border-radius: 100vw;
    padding: 1ch;
    top: 8ch;
    bottom: 0;
    margin: auto;
    box-shadow: 0ch 0ch 1ch rgba(0, 0, 0, 0.25);
    z-index: 2;
}

.slider-arrow-L:hover, .slider-arrow-R:hover{
    filter: brightness(0.95);
    cursor: pointer;
    transition: all 0.2s;
}

.slider-arrow-L{
    left: -4ch;
}

.slider-arrow-R{
    right: -3ch;
    transform: rotate(180deg);
}

.pantalla3-slider-cel{
    display: none;
}

.slider-last-item{
    justify-self: center !important;
}

.pantalla3-final-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    margin-top: 6ch;
    margin-bottom: 8ch;
}

.pantalla3-final-info > div{
    display: flex;
    align-items: center;
    margin-bottom: 3ch;
}

.pantalla3-final-info > div > div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pantalla3-final-info > div > div p{
    margin: 0.3ch 1ch;
}

.pantalla3-final-info > div > div p:nth-child(1){
    font-size: 2vw;
    font-weight: bold;
}

.pantalla3-final-info > div > div p:nth-child(2){
    font-size: 1.4vw;
}

.pantalla3-final-info img{
    height: 4.5vw;
}

.p3-final-info-cel{
    display: none !important;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){
    #SubirLocacionNoSession-MAIN{
        overflow-x: hidden;
    }

    .pantalla3-descubre-eventos{
        display: none;
    }
  
    .pantalla3-banner{
        height: calc(110vw + 4vh) !important;
        min-height: fit-content;
        max-height: unset;
        order: 1;
        background-image: url("../assets/images/bg2_cel.png");
    }

    .pantalla3-banner > *:not(button){
        width: 85% !important;
        text-align: center;
    }
    
    .pantalla3-banner > p:nth-child(1){
        font-size: 5.5vw;
    }

    .pantalla3-banner > p:nth-child(2), .pantalla3-banner > p:nth-child(4){
        font-size: 4vw;
    }

    .pantalla3-banner > p:nth-child(4){
        margin-top: 0;
    }

    .pantalla3-video{
        background-color: black;
        overflow: hidden;
        border-radius: 0;
        height: 100vw;
        width: 100vw;
        order: 2;
    }

    .pantalla3-video > video{
        width: 100%;
        height: 100%;
    }

    .tu-imaginacion-limite{
        font-size: 3.5vw !important;
        text-align: center;
    }

    .pantalla3-slider{
        display: none;
    }

    .pantalla3-slider-cel{
        display: flex;
        width: 100vw;
        height: 100vh !important;
        margin-top: 0ch;
        transform: translateY(6vw);
        overflow-y: hidden;
        overflow-x: auto;
    }

    .pantalla3-slider-cel > div{
        height: fit-content;
        display: flex;
        align-items: center;
        width: calc(100vw * 5);
        min-width: fit-content;
    }

    .pantalla3-slider-cel > div > div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content !important;
        transform: scale(0.9);
    }

    .pantalla3-slider-cel img{
        width: 70vw;
        height: 70vw;
    }

    .pantalla3-slider-cel p{
        font-size: 4vw !important;
        margin-top: 5vw !important;
        margin-bottom: 5vw !important;
    }

    .pantalla3-info{
         order: 3; 
    }

    .pantalla3-info > div{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .pantalla3-info > div > *{
        width: 80% !important;
        min-width: unset;
        max-width: unset;
        padding: 0;
    }

    .pantalla3-info > div > div{
        font-size: 5.5vw;
        margin-bottom: -3vw;
    }

    .pantalla3-info li{
        font-size: 4.5vw;
        margin-left: 4vw;
    }

    .pantalla3-info button{
        font-size: 3.6vw;
    }

    .pantalla3-final-info{
         order: 4; 
    }

    .p3-final-info-pc{
        display: none !important;
    }

    .p3-final-info-cel{
        display: flex !important;
        flex-direction: column;
    }

    .p3-final-info-cel > div:nth-child(1){
        width: 80vw;
        height: 100vw;
        border-radius: 4vw;
        display: flex;
    }

    .p3-final-info-cel > div:nth-child(1) > img{
        width: 100%;
        height: 100%;
    }

    .p3-final-info-cel > div:nth-child(2){
        margin-top: 10vw;
        display: flex;
        flex-direction: row;
        width: 50%;
        justify-content: space-between;
    }

    .p3-final-info-cel > div:nth-child(2) img{
        width: 14vw;
        height: unset;
    }

    .p3-final-info-cel > div:nth-child(3){
        margin-top: 5vw;
        width: 100%;
    }

    .p3-final-info-cel > div:nth-child(3) p:nth-child(1){
        font-size: 6vw;
    }

    .p3-final-info-cel > div:nth-child(3) p:nth-child(2){
        font-size: 4vw;
    }

    .pantalla3-final-info button{
        font-size: 3.6vw;
    }

    .p3-footer{
        order: 6;
    }

}
</style>