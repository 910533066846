<template>
  <footer>

    <div>
      <img src="../assets/images/icons/logo-white.png" class="footer-l footer-logo"/>
      <img src="../assets/images/icons/arrow-up.svg" class="footer-r footer-up-arrow" v-on:click="goTop()"/>
      <div>
          <p class="punto-encuentro-cel">El punto de encuentro que estabas buscando</p>
      </div>
    </div>

    <div>
      <div>
          <p class="punto-encuentro">El punto de encuentro que estabas buscando</p>
      </div>
      <div>
        <p @click="goTo('buscar')"><img src="../assets/images/icons/marker.svg"/>Busca una locación</p>
        <p @click="goTo('subir-locacion')"><img src="../assets/images/icons/building.svg"/>Sube tu locación</p>
        <p @click="goTo('preguntas')"><img src="../assets/images/icons/interrogation.svg"/>Preguntas frecuentes</p>
        <div class="footer-networks">
          <a href="https://www.facebook.com/locacion.com.mx"><img src="../assets/images/icons/facebook.svg"/></a>
          <a href="https://www.instagram.com/locacionmx/" target="_blank"><img src="../assets/images/icons/instagram.svg"/></a>
          <a><img src="../assets/images/icons/youtube.svg"/></a>
        </div>
      </div>
      <div class="footer-form">
        <p style="grid-area:llamanos;">Llámanos al 56 3030 0470 o escríbenos</p>
        <div style="grid-area:nombre;">Nombre*<br><input type="text" required/></div>
        <div style="grid-area:apellido;">Apellido*<br><input type="text" required/></div>
        <div style="grid-area:email;">Email*<br><input type="text" required/></div>
        <div style="grid-area:telefono;">Teléfono*<br><input type="text" required/></div>
        <div style="grid-area:comentario;">Escríbenos tu comentario<br><input type="text" required/></div>
        <button style="grid-area:boton;">Enviar</button>
      </div>
    </div>

    <div>
      <p class="footer-l" @click="goTo('terminos')">Términos y condiciones</p>
      <p class="footer-r" @click="goTo('aviso')">Aviso de privacidad</p>
    </div>

  </footer>
</template>

<script>
export default {
  name: 'FooterLocacion',
  methods:{

    goTop(){
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    goTo(section_){
      window.location.hash = "/"+section_;
      if (localStorage.getItem('onSession') === 'true') {
        let userData = JSON.parse(localStorage.getItem('userData'));
        //console.log(userData)
        setTimeout(() => {
          document.querySelectorAll('.navProfile').forEach(element => element.style.display = "flex");
          document.querySelectorAll('.navAccount').forEach(element => element.style.display = "none");
          document.querySelectorAll('.nav-username').forEach(username => username.textContent = userData.user.S_Nombre);
          document.querySelectorAll('.nav-userpic').forEach(userpic => userpic.src =userData.user.S_URLFotoPerfil);
          console.log("cambiado")
        }, 10);
      }
      setTimeout(() => { window.scrollTo(0, 0); }, 10);
    }
    
  }
}
</script>

<style scoped>
footer{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.3ch;
  background-color: white;
}

footer > div{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

footer > div:nth-child(1){
  background-color: black;
  color: white;
  height: 8ch;
}

.footer-logo{
  height: 3.5ch;
}

.footer-up-arrow{
  height: 6ch;
  order: 3;
}

.footer-up-arrow:hover{
  cursor: pointer;
}

footer > div:nth-child(2){
  width: 90%;
}

footer > div:nth-child(2) > div{  
  width: calc(100% / 3);
  padding-top: 2ch;
  padding-bottom: 8ch;
}

footer > div:nth-child(2) > div:nth-child(2){
  padding: 0;
  margin-top: 1ch;
  align-self: flex-start;
  cursor: pointer;
}

footer > div:nth-child(2) > div:nth-child(2) > *{
  margin-left: 4vw;
}

.footer-networks{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 18ch;
  margin-top: 6ch;
}

.footer-networks img{
  max-height: 5ch;
  max-width: 5ch;
}

footer p{
  display: flex;
  align-items: center;
}

footer p > img{
  filter: brightness(0);
  opacity: 0.7;
  max-height: 2ch;
  max-width: 2ch;
  margin:  0.2ch 1ch 0.2ch 0;
}

.footer-form{
  display: grid;
  grid-template-areas:"llamanos llamanos"
                      "nombre apellido"
                      "email telefono"
                      "comentario comentario"
                      "boton boton";
  margin-top: 5ch;
  position: relative;
  padding-bottom: 0 !important;
}

.footer-form::after{
    content: "Contacto";
    position: absolute;
    top: -3ch;
    font-weight: bolder;
  }

.footer-form > p{
  margin-top: -2ch;
  margin-bottom: 3ch;
  color: #4D4D4D;
}

.footer-form > div > input{
  border: 1px solid transparent;
  border-bottom: 1px solid black;
  width: 80%;
  margin-top: 0.5ch;
  margin-bottom: 3ch;
}

.footer-form > div:nth-child(5) > input{
  width: 90%;
}

.footer-form button{
  background: linear-gradient(#FF4C0B, #FF0A46);
    color: white;
    padding: 2ch 4ch;
    font-size: 15px;
    border: none;
    border-radius: 100vw;
    text-transform: uppercase;
    width: 230px;
    justify-self: center;
    margin-top: 1ch;
    margin-bottom: 3ch;
}

footer > div:nth-child(3){
  background-color: #4D4D4D;
  color: white;
  height: 6ch;
  font-weight: bold;
}

.footer-l{
  margin-left: 7.5vw;
  cursor: pointer;
}

.footer-r{
  margin-right: 7.5vw;
  cursor: pointer;
}

.punto-encuentro{
  padding-left: 1ch;
  border-left: 3px solid #FF282A;
  padding-top: 0.7ch;
  padding-bottom: 0.7ch;
  margin-left: -2vw;
  margin-top: -6ch;
}

.punto-encuentro-cel{
  display: none;
  order: 2;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

  .footer-up-arrow{
    display: none;
  }

  .footer-l, .footer-r{
    margin: 0;
    z-index: 2;
  }

  footer > div:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
    padding: 2ch 0;
  }

  .punto-encuentro-cel{
    display: flex;
    color: white;
    font-size: 3.3vw;
    margin-top: 3ch;
    border-left: 2px solid orangered;
    padding-left: 1ch;
    padding-right: -1ch;
  }

  footer > div:nth-child(2){
    flex-direction: column;
  }

  footer > div:nth-child(2) > div{
    width: 98%;
  }

  footer > div:nth-child(2) > div:nth-child(1){
    display: none;
  }

  footer > div:nth-child(2) > div:nth-child(2){
   width: fit-content;
   justify-self: unset;
   align-self: unset;
   margin-top: 5vw;
   margin-bottom: 20vw;
  }

  .footer-form{
    margin-top: 2vw;
    position: relative;
    grid-template-areas:"llamanos"
                        "nombre"
                        "apellido"
                        "email"
                        "telefono"
                        "comentario"
                        "boton";
  }

  .footer-form::after{
    content: "Contacto";
    position: absolute;
    top: -10vw;
    font-weight: bolder;
  }

  .footer-form > div > input, footer-form > div:nth-child(5) > input{
    width: 90%;
  }

  footer > div:nth-child(3){
    flex-direction: column;
    padding: 4ch 0;
    padding-bottom: 33vw;
  }



}
</style>