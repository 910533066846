<template> <!-- https://xd.adobe.com/view/ddcd1463-e874-48aa-9b6a-3f07ac4fcd19-889e/ Located on: dev.ideeo.mx/locaciones/ -->
           <!-- Mobile: https://xd.adobe.com/view/82ddd3a9-1357-4e92-b63a-c366925f69fc-d78e/screen/ac8c0573-1651-4888-885b-4ef846c5ef3a -->
  <div>
    <NoCodigo v-if="!activeCode"/>
    <div v-else>
      <div v-if="currentView === 'BuscadorNoSession'"><BuscadorNoSession/></div>
      <div v-if="currentView === 'InicioSesionCrearCuenta'"><InicioSesionCrearCuenta/></div>
      <div v-if="currentView === 'SubirLocacionNoSession'"><SubirLocacionNoSession/></div>
      <div v-if="currentView === 'SubirLocacionWithSession'"><SubirLocacionWithSession/></div>
      <div v-if="currentView === 'PerfilUsuario'"><PerfilUsuario/></div>
      <div v-if="currentView === 'Detalle'"><DetalleLocacion/></div>
      <div v-if="currentView === 'NotFound'"><NotFound/></div>
      <div v-if="currentView === 'PreguntasFrecuentes'"><PreguntasFrecuentes/></div>
      <div v-if="currentView === 'TerminosCondiciones'"><TerminosCondiciones/></div>
      <div v-if="currentView === 'AvisoPrivacidad'"><AvisoPrivacidad/></div>
      <div v-if="currentView === 'BuscoEstoView'"><BuscoEstoView/></div>
    </div>
  </div>

</template>

<script>
//https://dev-locacion.ideeo.mx/
/* eslint-disable vue/no-unused-components */
// https://locacionusuarios.ideeo.mx/#/crear-locacion/eventos
import { ref, computed } from 'vue';
import PerfilUsuario from './PerfilUsuario.vue';
import SubirLocacionWithSession from './SubirLocacionWithSession.vue';
import DetalleLocacion from './DetalleLocacion.vue'
import BuscadorNoSession from './BuscadorNoSession.vue'
import InicioSesionCrearCuenta from './InicioSesionCrearCuenta.vue';
import SubirLocacionNoSession from './SubirLocacionNoSession.vue';
import PreguntasFrecuentes from './PreguntasFrecuentes.vue';
import NotFound from './NotFound.vue';
import TerminosCondiciones from './TerminosCondiciones.vue';
import AvisoPrivacidad from './AvisoPrivacidad.vue';
import NoCodigo from './NoCodigo/NoCodigo.vue';
import BuscoEstoView from './BuscoEstoView.vue';
export default {
    components: {
    DetalleLocacion,
    BuscadorNoSession,
    InicioSesionCrearCuenta,
    SubirLocacionNoSession,
    SubirLocacionWithSession,
    PerfilUsuario,
    PreguntasFrecuentes,
    NotFound,
    TerminosCondiciones,
    AvisoPrivacidad,
    NoCodigo,
    BuscoEstoView,
},
  name: 'LocacionApp',
  data(){
    return{
      activeCode: false
    }
  },
  setup() {
    const currentPath = ref(window.location.hash);

    window.addEventListener('hashchange', () => {
      currentPath.value = window.location.hash;
    });

    const currentView = computed(() => {

      const route = currentPath.value.slice(2); // Elimina el '#' y el '/' del inicio de la ruta
      if (route === '' || route === 'buscar') {
        return 'BuscadorNoSession';
      } else if (route === 'crear-cuenta') {
        return 'InicioSesionCrearCuenta';
      } else if (route === 'subir-locacion') {
        if (localStorage.getItem('onSession') === 'true') {
          return 'SubirLocacionWithSession';
        }else{
          return 'SubirLocacionNoSession';
        }
      } else if (route === 'perfil') {
        return 'PerfilUsuario';
      } else if (route === 'detalle') {
        return 'Detalle';
      } else if (route === 'preguntas') {
        return 'PreguntasFrecuentes';
      } else if (route === 'terminos') {
        return 'TerminosCondiciones';
      } else if (route === 'aviso') {
        return 'AvisoPrivacidad';
      } else if(route === 'buscoesto'){
        return 'BuscoEstoView';
      }
        else {
        // Ruta no válida, redirecciona a la página de no encontrada
        //window.location.href = '/#/not-found'; // Cambia '/not-found' por la ruta que deseas utilizar para la página de no encontrada
        return 'NotFound';
      }
    });

    return { currentView };
  },

  methods:{
    setVisualData(){
      // Configura toda la información visual, como el nombre y la imagen de perfil, etc
      if (localStorage.getItem('onSession') === 'true') {
        let userData = JSON.parse(localStorage.getItem('userData'));
        console.log(userData)

        if(userData){
          document.querySelectorAll('.navProfile').forEach(element => element.style.display = "flex");
          document.querySelectorAll('.navAccount').forEach(element => element.style.display = "none");
          document.querySelectorAll('.nav-username').forEach(username => username.textContent = userData.user.S_Nombre);
          document.querySelectorAll('.nav-userpic').forEach(userpic => userpic.src =userData.user.S_URLFotoPerfil);
        }

      }
    },

    setAccessCode(){
      this.activeCode = true;
    }
  },

  mounted(){
    this.setVisualData();
    let accessCode = localStorage.getItem("accessCode");
    if(accessCode == true || accessCode == "true"){
      this.activeCode = true;
    }else{
      this.activeCode = false;
    }
    
    /*Window.AlexaKitsune = true;
    console.log(
      "%c 10Ne-V",
      "color:#4c00ff; font-size:16px; font-weight: bold;",
    );*/
  }
}
</script>

<style>
#InicioSesionCrearCuenta-component-MAIN,
#SubirLocacionNoSession-component-MAIN,
#SubirLocacionWithSession-component-MAIN,
#PerfilUsuario-component-MAIN{
  display: none;
}

</style>