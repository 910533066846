<template>
    <div id="NoCodigo-BuscadorNoSession-MAIN">
        <nav>
            <div>
                <img src="../../assets/images/icons/logo.svg" class="nav-logo"/>
            </div>
        </nav>

        <div class="proximo-lanzamiento" ref="proximoLanzamiento" id="NoCodigo-p2-proximoLanzamiento">
            <img src="../../assets/images/icons/coming-soon.svg"> Próximo lanzamiento para búsquedas
        </div>
        
        <section class="pantalla2-banner">
            <div class="error-code" v-if="errors">{{ msgError }}</div>
            <div class="searchbar">
                <input type="text" ref="CodigoAccess" class="CodigoAccess" placeholder="Ingresa tu código" @keydown.enter="verifyCode"/>
            </div>

            <button class="IngresarCodigo" ref="IngresarCodigo" @click="verifyCode()">Ingresar</button>

            <slider id="NoCodigo-slider1-pantalla2" interval="3000">
                <img :src="require(`../../assets/images/carrusel-2/1${isMobile('cel','')}.png`)"/>
                <img :src="require(`../../assets/images/carrusel-2/2${isMobile('cel','')}.png`)"/>
                <img :src="require(`../../assets/images/carrusel-2/3${isMobile('cel','')}.png`)"/>
                <img :src="require(`../../assets/images/carrusel-2/4${isMobile('cel','')}.png`)"/>
                <img :src="require(`../../assets/images/carrusel-2/5${isMobile('cel','')}.png`)"/>
                <img :src="require(`../../assets/images/carrusel-2/6${isMobile('cel','')}.png`)"/>
            </slider>

        </section>
  
        <section class="cinta-categorias cinta-icons">
            <img src="../../assets/images/icons/arrow.svg" class="arrow-left" @click="moveIcons(-1)"/>
            <div class="cinta-container" ref="cintaContainer">
                <div>
                    <div><img src="../../assets/images/icons/bodegas.svg"><p>Bodegas</p></div>
                    <div><img src="../../assets/images/icons/salones.svg"><p>Salones</p></div>
                    <div><img src="../../assets/images/icons/locales.svg"><p>Locales</p></div>
                    <div><img src="../../assets/images/icons/estadios.svg"><p>Estadios</p></div>
                    <div><img src="../../assets/images/icons/helipuertos.svg"><p>Helipuertos</p></div>
                    <div><img src="../../assets/images/icons/palcos.svg"><p>Palcos</p></div>
                    <div><img src="../../assets/images/icons/casas.svg"/><p>Casas</p></div>
                    <div><img src="../../assets/images/icons/terrazas.svg"/><p>Terrazas</p></div>
                    <div><img src="../../assets/images/icons/casaantigua.svg"/><p>Casa antigua</p></div>
                    <div><img src="../../assets/images/icons/albercas.svg"/><p>Albercas</p></div>
                    <div><img src="../../assets/images/icons/jardines.svg"/><p>Jardines</p></div>
                    <div><img src="../../assets/images/icons/sotanos.svg"/><p>Sótanos</p></div>
                    <div><img src="../../assets/images/icons/haciendas.svg"/><p>Haciendas</p></div>
                    <div><img src="../../assets/images/icons/house-tree.svg"/><p>Cabañas</p></div>
                    <div><img src="../../assets/images/icons/vecindades.svg"/><p>Vecindades</p></div>
                    <div><img src="../../assets/images/icons/iglesia.svg"/><p>Iglesia</p></div>
                    <div><img src="../../assets/images/icons/cafeteria.svg"/><p>Cafeteria</p></div>
                    <div><img src="../../assets/images/icons/foro.svg"/><p>Foro</p></div>
                    <div><img src="../../assets/images/icons/oficinas.svg"/><p>Oficinas</p></div>
                    <div><img src="../../assets/images/icons/deportivo.svg"/><p>Deportivo</p></div>
                    <div><img src="../../assets/images/icons/galeria.svg"/><p>Galería</p></div>
                    <div><img src="../../assets/images/icons/edificio.svg"/><p>Edificio</p></div>
                    <div><img src="../../assets/images/icons/departamento.svg"/><p>Departamento</p></div>
                    <div><img src="../../assets/images/icons/hospitales.svg"/><p>Hospitales</p></div>
                </div>
            </div>
            <img src="../../assets/images/icons/arrow.svg" class="arrow-right" @click="moveIcons(1)"/>
        </section>

        <FooterLocacion/>  
    </div>  
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { element, htjs, rand } from "alexa-reactor-core";
export default {
    name: 'NoCodigo',
    data(){
        return{
            errors: false,
            msgError: "",
            codeRequired: false,
        }
    },
    methods:{

        isMobile(yes_, no_){
            if(window.matchMedia('(max-width: 780px)').matches)
                return yes_;
            else
                return no_;
        },

        moveIcons(direction_){
            let slide = this.$refs.cintaContainer;
            slide.scrollLeft += slide.clientWidth * direction_;
        },

        verifyCode(){
            const codigo = this.$refs.CodigoAccess;
            if(!codigo.value.trim()){
                console.log("No code");
                return;
            }

            const btn = this.$refs.IngresarCodigo;
            btn.style.pointerEvents = "none";
            btn.style.opacity = 0.5;


            fetch('https://locacionbackoffice.ideeo.mx/api/validarCodigo', {
                method: 'POST',
                body: JSON.stringify({
                    codigo: codigo.value.trim()
                }),
                headers: {
                    "Content-type": "application/json"
                }
                })
                .then(response => response.json())
                .then(json => {
                    btn.style.pointerEvents = "all";
                    btn.style.opacity = 1;

                    console.log(json)
                    if(json.activo == 1){
                        console.log("Codigo valido");
                        this.errors = false;
                        sessionStorage.setItem("accessCode", true);
                        sessionStorage.setItem("accessCodeNum", codigo.value.trim());
                        this.$parent.setAccessCode();
                    }else{
                        console.log("Codigo no valido");
                        this.errors = true;
                        this.msgError = "Código no valido";
                        sessionStorage.removeItem("accessCode");
                        sessionStorage.clear();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.errors = true;
                    this.msgError = "Código no valido";
                    sessionStorage.removeItem("accessCode");
                    sessionStorage.clear();
                });
        }
    },

    mounted(){
        htjs();
        if(!this.codeRequired){
            console.log("Accessing...")
            this.errors = false;
            sessionStorage.setItem("accessCode", true);
            sessionStorage.setItem("accessCodeNum", 0);
            this.$parent.setAccessCode();
            setTimeout(() => {
                console.log("Accessing...")
                this.errors = false;
                sessionStorage.setItem("accessCode", true);
                sessionStorage.setItem("accessCodeNum", 0);
                this.$parent.setAccessCode();
            }, 100);
        }
    },
}
</script>
 
<style scoped>
#NoCodigo-BuscadorNoSession-MAIN{
    width: 100%;
    overflow-x: hidden;
}

.checkbox-round {
    width: 10px !important;
    height: 10px !important;
    background-color: white;
    border-radius: 100vw !important;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    transform: scale(0.6);
}

.checkbox-round:checked {
    background-color: #FF282A;
}

.pantalla2-banner{
    width: 100%;
    height: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    margin-bottom: 5ch;
}

.pantalla2-banner> *:not(#NoCodigo-slider1-pantalla2){
    z-index: 20;
}

#NoCodigo-slider1-pantalla2{
    position: absolute;
    top: 7ch;
    left: 0;
    width: 100%;
    height: 25vw;
    z-index: 0;
}

#NoCodigo-slider1-pantalla2 img{
    height: 35vw !important;
    width: 100vw !important;
}

.pantalla2-banner > h1, .pantalla2-banner button{
    color: white;
}

.pantalla2-banner > h1{
    text-shadow: 0ch 0ch 0.5ch rgba(0, 0, 0, 0.25);
    font-size: 2vw;
    margin-bottom: 2ch;
}

.searchbar{
    background-color: rgba(255,255,255, 0.6);
    width: fit-content;
    min-height: 8vh;
    border-radius: 100vw;
    display: flex;
    justify-content: space-between;
}

@media all and (max-width:870px){
    .searchbar{
        width: 95%;
        margin-left: 0;
    }

    .searchbar > label:nth-child(1) input{
        width: 18ch;
    }

    .searchbar > label:nth-child(2) select{
        width: fit-content;
    }
}

.searchbar > label:not(:nth-child(3)){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left:6%;
}

.searchbar > label:not(:nth-child(3)) p{
    display: flex;
    align-items: center;
    margin: 1ch;
}

.searchbar > label:not(:nth-child(3)) p img{
    max-width: 2ch;
    max-height: 2ch;
    margin-right: 1ch;
    margin-left: -3ch;
}

.searchbar input, .searchbar select{
    border: 2px solid transparent;
    background-color: transparent;
    padding: 0.5ch;
    margin-bottom: -1px;
    width: 30ch;
}

.searchbar input::placeholder{
    color:black;
}

.searchbar > label:nth-child(3){
    display: flex;
    align-items: flex-end;
    padding-bottom: 1ch;
    opacity: 0;
}

.searchbar > div{
    display: flex;
}

.searchbar > div > img{
    width: 4ch;
    opacity: 0.8;
    margin-right: 2ch;
}

.searchbar button{
    border-radius: 0 100vw 100vw 0;
    border: none;
    width: 18ch;
    display: flex;  
    align-items: center;
    justify-content: center;
    background: linear-gradient(#E0230C, #F2B105);
}

.searchbar button img{
    width: 2.8ch;
    margin-right: 1ch;
}

.IngresarCodigo{
    border-radius: 100vw;
    border: none;
    display: flex;  
    align-items: center;
    justify-content: center;
    padding: 1.5ch 4ch;
    margin-top: 1.5ch;
    margin-bottom: -1.5ch;
    background: linear-gradient(#E0230C, #f24c05);
}

.CodigoAccess{
    display: flex;
    background-color: red;
    border-radius: 100vw;
    text-align: center;
    opacity: 1 !important;
    font-size: 1.5ch;
    padding: 0ch !important;
    max-width: 90vw;
    padding: 0;
}

.CodigoAccess::placeholder{
    color: rgb(99, 99, 99) !important;
}

.radio-search-div{
    color: white;
    font-weight: bold;
    width: 70ch;
    margin-top: 1ch;
    margin-left: 2vw;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.radio-search-div label{
    margin-right: 4ch;
}

/* cinta */
.cinta-categorias{
    margin-bottom: 8ch;
    overflow: auto;
}

.cinta-categorias, .locaciones-destacadas{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cinta-categorias > .cinta-container{
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    width: 93%;
}

.cinta-categorias > .cinta-container > div{
    max-width: unset;
    width: fit-content;
}

.cinta-categorias > .cinta-container > div > div{
    text-align: center;
    width: 10ch !important;
}

.cinta-container > div, .cinta-container{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.cinta-container > div{
    width: 85%;
}

.cinta-icons > div{
    width: 90%;
}

.cinta-icons > div > div > div{
    margin: 0 1ch;
}

.cinta-icons > div > div img{
    max-width: 3ch;
    max-height: 3ch !important;
}

.cinta-container > div > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: calc(100% / 8);
}

.cinta-container > div > div img{
    height: 4.3ch;
}

.cinta-categorias .arrow-left, .cinta-categorias .arrow-right{
    position: absolute;
    margin-top: -1ch;
}

.cinta-categorias .arrow-left{
    left: 2ch;
    cursor: pointer;
}

.cinta-categorias .arrow-right{
    right: 2ch;
    cursor: pointer;
}

.cinta-categorias > div img{
    max-width: 4ch;
    max-height: 3.5ch;
}

.arrow-right{
    transform: rotate(180deg);
}

/* destacadas */
.locaciones-destacadas{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.destacadas-tiles > div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

/* tiles */
.tile-destacado{
    display: flex;
    width: 20vw !important;
    min-height: 20vw;
    margin-bottom: 3ch;
}

.tile-destacado > div{
    width: 20vw;
    height: 20vw;
    border-radius: 1ch;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5ch;
}

.tile-destacado > div > *:not(.tile-destacado-img){
    position: absolute;
}

.tile-destacado-type-icon{
    /*position: absolute;*/
    z-index: 2;
    top: 1ch;
    right: 1ch;
    width: 1.8vw;
    height: 1.8vw !important;
    background-color:    #F4F4F4;
    padding: 0.7ch;
    border-radius: 100vw;
}

.tile-destacado .arrow-left, .tile-destacado .arrow-right{
    /*position: absolute;*/
    z-index: 2;
    width: 1.5ch;
    height: 1.5ch;
    padding: 0.5ch;
    border-radius: 100vw;
    background-color: #F4F4F4;
    display: none;
}

.tile-destacado:hover .arrow-left, .tile-destacado:hover .arrow-right{
    display: flex;
}

.tile-destacado .arrow-left{
    left: 1ch;
    display: none !important;
}

.tile-destacado .arrow-right{
    right: 1ch;
    display: none !important;
}

.tile-destacado .arrow-left:hover, .tile-destacado .arrow-right.arrow-right:hover{
    transform: scale(1.2);
    transition: all 0.1s;
    cursor: pointer;
}

.tile-destacado p{
    margin-top: 0;
    font-size: 1ch;
    white-space: nowrap;
    width: 100%;
}

.tile-destacado .tile-title{
    white-space:break-spaces;
    overflow: hidden;
    font-size: 1.3ch;
    margin-bottom: 1ch;
}

.arrow-right.arrow-right:hover{
    transform: scale(1.2) rotate(180deg) !important;
}

/* images */
.tile-destacado-slider{
    width: 20vw;
    height: 20vw;
    position: relative;
    overflow: hidden;
    scroll-behavior: smooth;
}
.tile-destacado-slider > div{
    height: 100%;
    width: fit-content;
    display: flex;
}
.tile-destacado-img {
    width: 20vw;
    height: 20vw;
    background-size: cover;
    z-index: 1;
    border-radius: 0.6ch;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.tile-direccion{
    padding: 1ch;
    color: white;
    text-shadow: 0ch 0ch 1ch black;
    text-align: right;
}

/* numerador */
.num-pages{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5ch;
    margin-bottom: 5ch;
}

.number-page{
    border: 1px solid transparent;
    padding: 1ch 1.5ch;
    border-radius: 0.5ch;
    margin: 0 0.4ch;
}

.number-page:hover{
    border: 1px solid black;
}

.num-page-active{
    background-color: #4D4D4D;
    color: white;
}

.num-page-next{
    display: flex;
    align-items: center;
    margin-right: 8vw;
    padding: 1ch 1.5ch;
    border-radius: 0.5ch;
    border: 1px solid transparent;
}

.num-page-next:hover{
    border: 1px solid black;
}


.num-page-next img{
    margin-left: 1ch;
}

.ver-mas{
    display: none;
}

.proximo-lanzamiento-copy{
    margin: 0 !important;
    padding: 0 !important;
}

.proximo-lanzamiento{
    position: fixed;
    background-color: #FF282A;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1ch 0 0 1ch;
    z-index: 3;
    margin-top: 2%;
    right: 0;
    padding: 1.5ch;
    display: none;
    box-shadow: 0 1ch  1ch rgba(50, 50, 50, 0.4);
}

.proximo-lanzamiento > img{
    filter: brightness(0) invert(1);
    width: 3ch;
    margin-right: 1ch;
}

.animate-popup{
    animation-duration: 6s;
    animation-fill-mode: forwards;
    animation-name: proximoLanzamiento;
}

@keyframes proximoLanzamiento {
    0%{
        opacity: 1;
        right: -100%;
    }

    5%{
        right: 0;
    }
    
    90%{
        opacity: 1;
    }

    100%{
        opacity: 0;
    }
}

nav{
    height: 7ch;
    display: flex;
    align-items: center;
}

nav > div{
    margin-left: 1ch;
}

.nav-logo{
    height: 3.5ch;
}

.error-code{
    color: red;
    margin-bottom: 1ch;
    background-color: #e7e7e7;
    padding: 0.5ch 1ch;
    border-radius: 100vw;
}

/********************************************************************
 * RESPONSIVE
 ********************************************************************/
@media all and (max-width:780px){

    nav{
        height: 7ch;
        display: flex;
        align-items: center;
    }

    nav > div{
        margin-left: 2ch;
    }

    .nav-logo{
      height: 2.8ch;
    }

    .pantalla2-banner{
        height: calc(110vw + 4vh);
        min-height: fit-content;
    }

    #NoCodigo-slider1-pantalla2{
        height: calc(110vw + 4vh);
    }
    #NoCodigo-slider1-pantalla2 img{
        height: 120vw !important;
    }

    .pantalla2-banner > *:not(.pantalla2-banner){
    }

    .pantalla2-banner h1{
        display: flex !important;
        font-size: 6vw;
        text-align: center;
        width: 85%;
    }

    .pantalla2-banner{
        display: flex;
    }

    .pantalla2-mobile-searchbar{
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .pantalla2-mobile-searchbar button{
        background: linear-gradient(#FF4C0B, #FF0A46);
        color: white;
        padding: 4vw 9vw;
        font-size: 3.5vw;
        border: none;
        border-radius: 100vw;
        text-transform: uppercase;
        font-weight: bold;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: 4vw;
    }

    .pantalla2-mobile-searchbar > div{
        display: flex;
        justify-content: space-evenly;
        background-color: rgba(255,255,255, 0.6);
        border-radius: 100vw;
        width: 100% !important;
        margin: 2vw 0;
        min-height: 18vw;
    }

    .pantalla2-mobile-searchbar > div img{
        width: 6vw;
    }

    .pantalla2-mobile-searchbar > div > div{
        width: calc(100% - 18vw);
        display: flex;
        flex-direction: column;
    }

    .pantalla2-mobile-searchbar p{
        margin-bottom: 2vw;
        margin-top: 2vw;
        font-size: 4.4vw;
    }

    .pantalla2-mobile-searchbar span, .pantalla2-mobile-searchbar select{
        background-color: transparent;
        margin: 0;
        font-size: 3.5vw;
        margin-bottom: 1ch;
        border: none;
    }

    .pantalla2-mobile-searchbar  button > img{
      width: 1.5ch;
      margin-right: 1ch;
      filter: brightness(0) invert(1);
    }

    .radio-search-div{
        display: none  !important;;
    }

    /* cinta */
    .cinta-categorias {
        max-width: 100%;
    }

    .cinta-categorias > img {
        display: none;
    }

    .cinta-categorias .cinta-container {
        overflow: auto;
        display: flex; /* Agrega display: flex */
        justify-content: flex-start;
    }

    .cinta-categorias .cinta-container > div {
        flex-shrink: 0; /* Evita que el contenido se encoja */
        width: fit-content;
        max-width: unset;
        padding-left: 2vw;
        padding-right: 1vw;
    }

    .cinta-categorias .cinta-container > div p {
        font-size: 3.5vw;
        padding: 0 1ch;
    }

    /* destacadas */
    .destacadas-tiles > div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    /* tiles */
    .tile-destacado{
        width: 80vw !important;
        min-height: 80vw;
        margin-bottom: calc(6ch + 2vw);
        margin-top: 3ch;
    }

    .tile-destacado > div{
        width: 100%;
        height: 100%;
        margin-bottom: 0;
    }

    .tile-destacado p{
        font-size: 3.7vw;
        margin-top: 6ch;
        text-align: center;
    }

    .tile-destacado .tile-title{
        margin-top: 10ch;
        font-size: 3.4vw;
        height: 7ch;
    }

    .tile-destacado .tile-clave{
        margin-top: 0;
    }

    .tile-destacado-type-icon{
        margin-top: 0 !important;
        top: -8vw;
        right: 3vw;
        width: 7vw;
        height: 7vw !important;
    }

    /* images */
    .tile-destacado-slider{
        width: 80vw;
        height: 80vw;
        position: relative;
    }
    .tile-destacado-slider > div{
        height: 100%;
        width: fit-content;
        display: flex;
    }
    .tile-destacado-img {
        width: 80vw;
        height: 80vw;
        background-size: cover;
        z-index: 1;
        border-radius: 0.6ch;
    }

    /* numerador */
    .num-pages{
        display: none;
    }

    .ver-mas{
        display: flex;
        font-weight: bolder;
        text-decoration: underline;
        margin-bottom: 14vw;
        cursor: pointer;
    }

    .proximo-lanzamiento{
        position: fixed;
        background-color: #FF282A;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        z-index: 3;
        margin-top: 0;
        width: 100%;
        right: unset;
        left: unset;
        padding: 1.5ch 0;
        display: none;
        box-shadow: 0 1ch  1ch rgba(50, 50, 50, 0.4);
    }

    .proximo-lanzamiento > img{
        display: none;
    }

    .animate-popup{
        animation-duration: 6s;
        animation-fill-mode: forwards;
        animation-name: proximoLanzamiento;
    }

    @keyframes proximoLanzamiento {
        0%{
            opacity: 1;
        }
        
        90%{
            opacity: 1;
        }

        100%{
            opacity: 0;
        }
    }

    
    .searchbar{
        scale: 0.9;
    }

}
</style>